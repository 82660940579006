
@mixin landingBtn {
    display: inline-block;
    padding: 12px 16px;
    background: $color-point;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
    white-space: nowrap;
    @include respond-to(pc) {
        padding: 16px 20px;
        font-size: 20px;
    }
}
@mixin jBtn {
    display: block;
    padding: 13px 0;
    background: #fff;
    color: $color-point;
    text-align: center;
    @include respond-to(pc) {
        padding: 18px 0;
        font-size: 16px;
    }
}
@mixin txtTit {
    font: {
        size: 24px;
        weight: 700;
    }
    line-height: 34px;
    br {
        display: none;
    }
    @include respond-to(pc) {
        font-size: 36px;
        text-align: center;
        line-height: 56px;
        br {
            display: inline;
        }
    }
    @include respond-to(pcMain) {
        font-size: 40px;
    }
}
$landing-def: #26262A;
.landing-w,
.lading-before {
    font-family: $font-landing;
    -webkit-font-smoothing: antialiased;
    strong {
        font-weight: 700;
    }
}
.landing-h {
    position: sticky !important;
    height: 60px;
    margin-bottom: 0;
    padding: {
        top: 13px;
    }
    border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
    .nav-w {
        display: none;
        position: absolute;
        left: 0;
        width: 100vw;
        height: 100vh;
        margin-top: 55px;
        background: rgba(0, 0, 0, 0.15);
        .inner-w {
            overflow: auto;
            background: #fff;
        }
        ul {
            li {
                a {
                    display: block;
                    padding: 10px;
                    font-size: 14px;
                }
            }
        }
        nav {
            position: relative;
            padding: 10px 10px 20px;
            //background: #fff;
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 5px;
                left: 20px;
                width: calc(100% - 40px);
                height: 1px;
                background: #E6E6E6;
            }
        }
        .m-inner {
            //overflow: auto;
            padding: 10px 10px 20px;
            //background: #fff;
        }
        &.selected {
            display: block;
        }
    }
    &.lg-before {
        .nav-w {
            .inner-w {
                nav {
                    display: none;
                }
            }
        }
    }
    .mem-w {
        margin-top: 5px;
    }
    .btn-menu {
        top: 10px;
    }
    .inner {
        top: auto;
        margin-top: 55px;
        padding: 0;
        background: rgba(0, 0, 0, 0.15);
        .inbox {
            padding: 0 20px 10px;
            background: #fff;
            > nav, .utill {
                padding: 15px 0;
                ul {
                    li {
                        padding: 0;
                        a {
                            display: block;
                            padding: 10px 0;
                            color: $landing-def;
                            font-size: 14px;
                        }
                    }
                }
            }
            .utill {
                margin-top: 0;
                border-top: 1px solid #E6E6E6;
            }
        }

    }
    @include respond-to(pc) {
        height: 78px;
        padding-top: 22px;
        .btn-menu {
            //display: none;
            top: 18px;
        }
        .nav-w {
            display: block;
            position: static;
            width: 100%;
            height: auto;
            margin: 0;
            //margin: 0 0 0 auto;
            background: none;
            .inner-w {
                display: flex;
                justify-content: space-between;
                background: none;
            }
            nav {
                padding: 0;
                ul {
                    display: flex;
                    li {
                        margin-left: 40px;
                    }
                }
                &:after {
                    display: none;
                }
            }
            .m-inner {
                overflow: hidden;
                margin-left: auto;
                padding: 0;
                background: none;
                ul {
                    display: flex;
                    li {
                        margin-left: 8px;
                        a {
                            padding: 8px 12px;
                            border-radius: 8px;
                            font-size: 14px;
                            &:hover {
                                background: #F4F4F5;
                            }
                            &.actv {
                                background: $color-point;
                                color: #fff;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
        &.lg-before {
            display: flex;
            .btn-menu {
                display: none;
            }
        }
        .mem-w {
            top: 25px;
            margin-top: 0;
        }
        .inner {
            margin: 3px 0 0;
            background: none;
            .inbox {
                padding: 0;
                > nav, .utill {
                    margin: 0;
                    padding: 0;
                }
                nav {
                    ul {
                        li {
                            margin-left: 40px;
                        }
                    }
                }
                .utill {
                    top: 71px;
                    padding: 16px 0;
                    border: 1px solid $color-point;
                    ul {
                        li {
                            a {
                                display: inline-block;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
.landing-cnts {
    padding-bottom: 40px;
    color: $landing-def;
    a {
        color: $landing-def;
    }
    @include respond-to(pc) {
        padding-bottom: 60px;
    }
    .top-v {
        padding: 35px 30px;
        background-color: #FCFBF8;
        .inner {
            strong {
                font-size: 34px;
                transition: font-size 0.5s;
            }
            p {
                margin-top: 16px;
                color: #8C8C8C;
                line-height: 24px;
                font-weight: bold;
            }
            a {
                @include landingBtn;
                margin-top: 24px;
            }
            .img {
                display: block;
                margin-top: 40px;
                img {
                    width: 100%;
                }
            }
            /* &:after {
                content: '';
                display: block;
                width: 100%;
                height: 300px;
                margin-top: 40px;
                background: url('../images/landing_topv.png') no-repeat 0 0;
                background-size: contain;
                transition: height 0.5s;
            } */
        }
        @include respond-to(pc2) {
            padding: 85px 30px 70px;
            .inner {
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 1060px;
                margin: 0 auto;
                strong {
                    font-size: 56px;
                }
                p {
                    font-size: 24px;
                    line-height: 36px;
                }
                .img {
                    width: 43%;
                    max-width: 480px;
                    margin-top: 0;
                    margin-left: 30px;
                }
                /* &:after {
                    width: 370px;
                    height: 370px;
                    margin-top: 0;
                    margin-left: 30px;
                } */
            }
        }
        @include respond-to(pcMain) {
            .inner {
                &:after {
                    width: 480px;
                    height: 480px;
                }
            }
        }
    }
    .corptop-v {
        padding: 36px 32px 0;
        strong {
            font-size: 34px;
        }
        p {
            margin-top: 20px;
            color: #8C8C8C;
            font-weight: 700;
            line-height: 24px;
        }
        > a {
            @include landingBtn;
            margin-top: 24px;
        }
        &:after {
            content: '';
            display: block;
            width: 300px;
            height: 300px;
            margin: 39px auto;
            background: url('../images/landingcorp01_m.png') no-repeat 0 0;
            background-size: contain;
        }
        @include respond-to(pc) {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 600px;
            padding-top: 100px;
            background: url('../images/landingcorp01_pc.png') no-repeat 50% 0;
            background-size: 1440px auto;
            box-sizing: border-box;
            text-align: center;
            strong {
                font-size: 56px;
                line-height: 72px;
            }
            p {
                margin-top: 28px;
                font-size: 24px;
                line-height: 36px;
            }
            a {
                margin-top: 40px;
            }
            &:after {
                display: none;
            }
        }
    }
    .pjt-data {
        padding: 48px 30px 0;
        > p {
            @include txtTit;
        }
        .n-job {
            display: flex;
            align-items: center;
            margin-top: 24px;
            color: #8C8C8C;
            font: {
                size: 14px;
                weight: 700;
            }
            span {
                margin-right: 8px;
                padding: 12px;
                background: #F2F4F6;
                border-radius: 24px;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                display: flex;
                flex-direction: column;
                width: 50%;
                margin-top: 44px;
                span {
                    color: #8C8C8C;
                    font-size: 14px;
                }
                em {
                    margin-top: 4px;
                    font-size: 40px;
                    font-weight: 600;
                    -webkit-font-smoothing: antialiased;
                    color: #0C87DF;
                    white-space: nowrap;
                }
                &.ctype2 {
                    em {
                        color: #699704;
                    }
                }
                &.ctype3 {
                    em {
                        color: #F65243;
                    }
                }
                &.ctype4 {
                    em {
                        color: #474ABE;
                    }
                }
            }
        }
        &:after {
            content: '';
            display: block;
            width: 280px;
            height: 308px;
            margin: 50px auto 0;
            background: url('../images/landingmem0101_m.png') no-repeat 0 0;
            background-size: contain;
            transition: width 0.5s, max-width 0.5s, height 0.5s;
        }
        &.corp {
            &:after {
                display: none;
            }
        }
        @include respond-to(xMo) {
            &:after {
                width: 100%;
                max-width: 1000px;
                height: 150px;
                margin: 50px auto 0;
                background-image: url('../images/landingmem0101_pc.png');
            }
            ul {
                li {
                    width: 25%;
                }
            }
        }
        @include respond-to(pc) {
            padding-top: 80px;
            .n-job {
                justify-content: center;
                font-size: 20px;
                span {
                    margin-right: 12px;
                    padding: 12px 16px;
                }
            }
            ul {
                max-width: 1020px;
                margin: 0 auto;
                li {
                    margin-top: 70px;
                    span {
                        font-size: 18px;
                    }
                    em {
                        margin-top: 8px;
                        font-size: 64px;
                    }
                }
            }
            &:after {
                height: 212px;
                margin-top: 100px;
            }
        }
    }
    .mem-rev {
        max-width: 1080px;
        margin: 0 auto;
        padding: 20px 20px 0;
        ul {
            li {
                overflow: hidden;
                margin-top: 40px;
                border: 1px solid #000;
                border-radius: 16px;
                background: #FCFBF8;
                > div {
                    padding: 28px 28px 40px;
                    strong {
                        font-size: 26px;
                        line-height: 34px;
                    }
                    p {
                        margin-top: 24px;
                        color: #8C8C8C;
                        font-weight: 700;
                        line-height: 26px;
                    }
                }
                &:after {
                    content: '';
                    display: block;
                    height: 262px;
                    background: #FFEBEB url('../images/landingmem0201.png') no-repeat 50% 50%;
                    background-size: auto 172px;
                }
                &.rev2 {
                    &:after {
                        background: {
                            color: #E8E0FE;
                            image: url('../images/landingmem0202.png');
                        }
                    }
                }
                &.rev3 {
                    &:after {
                        background: {
                            color: #FFF5C8;
                            image: url('../images/landingmem0203.png');
                        }
                    }
                }
                &.rev4 {
                    &:after {
                        background: {
                            color: #DAEEDC;
                            image: url('../images/landingmem0204.png');
                        }
                    }
                }
            }
        }
        @include respond-to(pc) {
            ul {
                li {
                    display: flex;
                    margin-top: 80px;
                    > div {
                        width: 50%;
                        padding: 70px 50px 0 70px;
                        box-sizing: border-box;
                        strong {
                            font-size: 38px;
                            line-height: 56px;
                        }
                        p {
                            max-width: 360px;
                            margin-top: 32px;
                            font-size: 20px;
                            line-height: 32px;
                        }
                    }
                    &:after {
                        width: 50%;
                        height: 580px;
                        background-size: auto 240px;
                    }
                    &:nth-child(2n+1) {
                        flex-direction: row-reverse;
                    }
                }
            }
        }
        @include respond-to(pcMain) {
            ul {
                li {
                    > div {
                        padding: 70px 60px 0 70px;
                        strong {
                            font-size: 48px;
                            line-height: 64px;
                        }
                    }
                    &:after {
                        background-size: auto 280px;
                    }
                    &.rev3 {
                        > div {
                            strong {
                                font-size: 40px;
                                line-height: 56px;
                            }
                        }
                    }
                }
            }
        }
    }
    .recu-info {
        padding: 0 30px;
        ul {
            li {
                display: flex;
                flex-direction: column;
                margin-top: 70px;
                &:before {
                    content: '';
                    width: 100%;
                    height: 285px;
                    background: url('../images/landingcorp0201.png') no-repeat 50% 50%;
                    background-size: cover;
                    border: 1px solid #000;
                    border-radius: 16px;
                    box-sizing: border-box;
                }
                > div {
                    padding: 32px 10px 0;
                    strong {
                        font-size: 26px;
                        br {
                            display: none;
                        }
                    }
                    p {
                        margin-top: 24px;
                        color: #8C8C8C;
                        font-weight: 700;
                        line-height: 26px;
                        br {
                            display: none;
                        }
                        &.desc {
                            margin-top: 30px;
                            font-size: 14px;
                            &:before {
                                content: '*';
                            }
                        }
                    }
                }
                &.rec2 {
                    &:before {
                        background-image: url('../images/landingcorp0203.png');
                    }
                }
                &.rec3 {
                    &:before {
                        background-image: url('../images/landingcorp0204.png');
                    }
                }
                &.rec4 {
                    &:before {
                        background-image: url('../images/landingcorp0202.png');
                    }
                }
            }
        }
        @include respond-to(pc) {
            max-width: 1100px;
            margin: 0 auto;
            ul {
                li {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 180px;
                    &:before {
                        width: 45%;
                        max-width: 484px;
                        height: 460px;
                    }
                    > div {
                        width: 55%;
                        padding-left: 130px;
                        box-sizing: border-box;
                        strong {
                            font-size: 48px;
                            line-height: 64px;
                            br {
                                display: inline;
                            }
                        }
                        p {
                            width: auto;
                            max-width: 380px;
                            margin-top: 32px;
                            font-size: 20px;
                            line-height: 32px;
                            br {
                                display: inline;
                            }
                            &.desc {
                                font-size: 16px;
                            }
                        }
                    }
                    &:nth-child(2n) {
                        flex-direction: row-reverse;
                        > div {
                            padding-left: 0;
                            padding-right: 130px;
                        }
                    }
                }
            }
        }
    }
    .process-txt {
        margin-top: 95px;
        padding: 0 20px;
        .inbox {
            max-width: 1080px;
            margin: 0 auto;
            padding: 32px;
            background: #FCFBF8;
            border: 1px solid #000;
            border-radius: 16px;
            box-sizing: border-box;
        }
        h3 {
            font-size: 26px;
            line-height: 34px;
        }
        ol {
            padding-top: 8px;
            li {
                display: flex;
                flex-direction: column;
                margin-top: 40px;
                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 12px;
                    background-color: #323131;
                    color: #fff;
                    font: {
                        weight: 600;
                        size: 20px;
                    }
                }
                strong {
                    margin-top: 16px;
                    font-size: 20px;
                }
                p {
                    margin-top: 8px;
                    color: #8C8C8C;
                    font: {
                        weight: 600;
                        size: 16px;
                    }
                }
            }
        }
        @include respond-to(pc) {
            margin-top: 120px;
            .inbox {
                display: flex;
                padding: 88px 72px;
                h3 {
                    width: 50%;
                    font-size: 48px;
                    line-height: 60px;
                }
                ol {
                    width: 50%;
                    li {
                        margin-top: 48px;
                        span {
                            width: 48px;
                            height: 48px;
                            font-size: 24px;
                        }
                        strong {
                            font-size: 24px;
                        }
                        p {
                            font-size: 20px;
                            line-height: 32px;
                        }
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    .mem-rev + .process-txt {
        margin-top: 40px;
        @include respond-to(pc) {
            margin-top: 80px;
        }
    }
    .outsourcing-w {
        padding: 100px 20px 0;
        > p {
            @include txtTit;
        }
        > a {
            @include landingBtn;
            margin-top: 24px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 50px;
            li {
                width: calc(50% - 5px);
                display: flex;
                margin-top: 10px;
                flex-direction: column;
                min-height: 100%;
                padding: 20px;
                border: 1px solid #E2E3E6;
                border-radius: 16px;
                box-sizing: border-box;
                a {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                span {
                    overflow: hidden;
                    width: 40px;
                    height: 40px;
                    border-radius: 16px;
                    margin-bottom: 40px;
                }
                font: {
                    size: 16px;
                    weight: 700;
                }
            }
        }
        @include respond-to(pc) {
            padding-top: 210px;
            text-align: center;
            > a {
                margin-top: 64px;
            }
            ul {
                max-width: 1000px;
                margin: 0 auto;
                text-align: left;
                li {
                    width: calc(33.3% - 6px);
                    margin-top: 10px;
                    font-size: 20px;
                    span {
                        margin-bottom: 80px;
                    }
                }
            }
        }
    }
    .partner-w {
        padding: 80px 35px 0;
        > p, > h3 {
            @include txtTit;
        }
        > h3 {
            font-size: 26px;
        }
        ul.bnr-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 28px;
            li {
                img {
                    width: 134px;
                }
            }
        }
        @include respond-to(pc) {
            padding-top: 160px;
            > h3 {
                font-size: 48px;
            }
            ul.bnr-list {
                justify-content: center;
                max-width: 1232px;
                margin: 56px auto 0;
                li {
                    padding: 0 10px;
                    img {
                        width: 180px;
                    }
                }
            }
        }
    }
    ul.pt-rev {
        padding: 10px 20px 0;
        li {
            margin-top: 20px;
            padding: 24px;
            border: 1px solid #E2E3E6;
            border-radius: 20px;
            em {
                display: flex;
                align-items: center;
                font: {
                    size: 14px;
                    weight: 700;
                }
                span {
                    overflow: hidden;
                    min-width: 48px;
                    width: 48px;
                    height: 48px;
                    margin-right: 16px;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            p {
                margin-top: 28px;
                font-size: 14px;
                line-height: 20px;
                -webkit-font-smoothing: antialiased;
            }
            .prof-w {
                display: flex;
                align-items: center;
                > span {
                    overflow: hidden;
                    width: 48px;
                    height: 48px;
                    margin-right: 16px;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                div {
                    em {
                        font-size: 14px;
                    }
                    span {
                        display: block;
                        margin-top: 8px;
                        font-size: 12px;
                    }
                }
            }
            .bglogo {
                display: none;
            }
        }
        &.corp {
            p {
                line-height: 24px;
            }
        }
        @include respond-to(pc) {
            display: flex;
            justify-content: space-between;
            max-width: 1232px;
            margin: 0 auto;
            li {
                display: flex;
                flex-direction: column;
                width: calc(33.3% - 10px);
                padding: 40px;
                box-sizing: border-box;
                em {
                    font-size: 18px;
                }
                p {
                    font-size: 18px;
                    line-height: 32px;
                }
                .prof-w {
                    > span {
                        width: 60px;
                        height: 60px;
                    }
                    div {
                        em {
                            font-size: 18px;
                        }
                        span {
                            font-size: 16px;
                        }
                    }
                }
                .bglogo {
                    display: block;
                    margin-top: auto;
                    img {
                        width: 152px;
                    }
                }
            }
            &.corp {
                li {
                    min-height: 520px;
                    padding: 34px 34px 24px;
                    p {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .m-price {
        padding: 75px 20px 0;
        .info {
            padding: 0 12px;
            h3 {
                font-size: 26px;
            }
            p {
                margin-top: 14px;
                color: #8C8C8C;
                font-weight: 700;
                line-height: 26px;
                br {
                    display: none;
                }
            }
            > a, > em {
                @include landingBtn;
                margin-top: 24px;
                background-color: #323131;
            }
        }
        .p-box {
            margin-top: 47px;
            .box-w {
                padding: 16px;
                border: 1px solid #000;
                background: #FCFBF8;
                border-radius: 16px;
                h4 {
                    font-size: 16px;
                }
                > p {
                    margin-top: 10px;
                    color: #8C8C8C;
                    font-size: 12px;
                }
                strong {
                    display: block;
                    margin-top: 18px;
                    font-size: 40px;
                    line-height: 64px;
                }
                .noti {
                    display: block;
                    margin-top: 10px;
                    padding: 16px;
                    background: #FFEBEB;
                    border-radius: 8px;
                    font: {
                        size: 12px;
                        weight: 700;
                    }
                    text-align: center;
                }
            }
            .tit {
                padding: 18px 20px 0;
                font: {
                    size: 14px;
                    weight: 700;
                }
            }
            ul {
                padding: 0 20px;
                font-size: 12px;
                li {
                    position: relative;
                    margin-top: 10px;
                    padding-left: 20px;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 8px;
                        width: 3px;
                        height: 3px;
                        background: $color-def;
                        border-radius: 50%;
                    }
                }
            }
        }
        .p-data {
            overflow: auto;
            width: 100vw;
            margin-left: -20px;
            margin-top: 40px;
            padding: 0 20px;
            font-size: 12px;
            box-sizing: border-box;
            table {
                min-width: 360px;
                tr {
                    margin-top: 4px;
                    text-align: center;
                    th, td {
                        vertical-align: middle;
                        line-height: 20px;
                        border: 2px solid #fff;
                        border-radius: 8px;
                        > span {
                            display: block;
                            font-size: 10px;
                            font-weight: normal;
                        }
                    }
                    th {
                        //width: calc(50% - 2px);
                        padding: 16px 0;
                        background: #eee;
                        font-weight: 700;
                    }
                    td {
                        width: calc(50% - 2px);
                        padding: 16px 0;
                        background: #F8F8F8;
                        border-radius: 4px;
                        font-weight: 700;
                        .tok {
                            display: block;
                            width: 16px;
                            height: 16px;
                            margin: 0 auto;
                            background: url('../images/icon_chk_p.svg') no-repeat 0 0;
                            background-size: cover;
                            font-size: 1px;
                            text: {
                                align: left;
                                indent: -9999px;
                            }
                        }
                    }
                }
                thead {
                    tr {
                        th {
                            &:first-child {
                                background: none;
                            }
                        }
                    }
                }
                tbody {
                }
            }
        }
        @include respond-to(pc) {
            display: flex;
            justify-content: space-between;
            max-width: 1060px;
            margin: 0 auto;
            padding-top: 190px;
            .info {
                padding: 39px 20px 0 0;
                h3 {
                    font-size: 48px;
                }
                p {
                    margin-top: 26px;
                    font-size: 20px;
                    line-height: 32px;
                    br {
                        display: inline;
                    }
                }
                a {
                    margin-top: 40px;
                }
            }
            .p-box {
                width: 50%;
                max-width: 400px;
                margin-top: 0;
                margin-left: 30px;
                .box-w {
                    padding-top: 25px;
                    h4 {
                        padding-left: 24px;
                        font-size: 18px;
                    }
                    > p {
                        margin: 10px 0 0 24px;
                        font-size: 16px;
                    }
                    strong {
                        margin: 54px 0 0 24px;
                        font-size: 64px;
                    }
                    .noti {
                        margin-top: 29px;
                        font-size: 16px;
                    }
                }
                .tit {
                    padding: 24px 40px 0;
                    font-size: 18px;
                }
                ul {
                    padding: 10px 40px 0;
                    font-size: 16px;
                }
            }
            .p-data {
                width: 55%;
                max-width: 580px;
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 20px;
                table {
                    tr {
                        th {
                            width: calc(45% - 2px);
                            padding: 21px 0 20px;
                        }
                        td {
                            width: calc(55% - 2px);
                            padding: 21px 0 20px;
                        }
                        th, td {
                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            &.corp {
                .info {
                    padding: 0;
                }
            }
        }
    }
    .join-info {
        padding: 60px 20px 0;
        .inner {
            padding: 28px 28px 40px;
            background: #FFF28A;
            border-radius: 16px;
            .txt {
                strong {
                    font-size: 24px;
                }
                p {
                    margin-top: 24px;
                    font-weight: 700;
                    line-height: 26px;
                }
                > a {
                    @include landingBtn;
                    margin-top: 24px;
                }
            }
            &:after {
                content: '';
                display: block;
                width: 184px;
                height: 220px;
                margin: 39px auto 0;
                background: url('../images/landingmem03.png') no-repeat 0 0;
                background-size: contain;
            }
        }
        @include respond-to(pc) {
            .inner {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
                width: 100%;
                max-width: 1080px;
                margin: 0 auto;
                padding: 90px 100px;
                box-sizing: border-box;
                .txt {
                    strong {
                        font-size: 40px;
                    }
                    p {
                        margin-top: 36px;
                        font-size: 20px;
                    }
                    > a {
                        margin-top: 40px;
                    }
                }
                &:after {
                    width: 258px;
                    height: 306px;
                    margin: 0 30px 0 0;
                }
            }
        }
    }
}
.cust-cnts {
    padding: 80px 20px 0;
    .txt-box {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 32px 28px 40px;
        background: #263238;
        border-radius: 16px;
        h3 {
            margin-top: 14px;
            color: #fff;
            font-size: 24px;
        }
        p {
            margin-top: 20px;
            margin-bottom: 50px;
            color: #A9A9B7;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }
        > a {
            @include landingBtn;
            margin-top: auto;
            margin-right: auto;
        }
        &:before {
            content: '';
            display: block;
            width: 28px;
            height: 44px;
            background: url('../images/custimg01.png') no-repeat 0 0;
            background-size: cover;
        }
        &.faq {
            &:before {
                width: 48px;
                height: 40px;
                background-image: url('../images/custimg02.png');
            }
            > a {
                background: #F8C858;
                color: $color-def;
            }
        }
    }
    @include respond-to(pc) {
        display: flex;
        justify-content: space-between;
        max-width: 1080px;
        margin: 230px auto 0;
        padding: 0;
        background: #263238;
        border-radius: 16px;
        .txt-box {
            width: 50%;
            margin-top: 0;
            padding: 62px 80px 90px;
            background: none;
            border-radius: 0;
            &:before {
                width: 40px;
                height: 63px;
            }
            h3 {
                margin-top: 20px;
                font-size: 40px;
            }
            p {
                margin-top: 28px;
                font-size: 20px;
                line-height: 32px;
            }
            &.faq {
                &:before {
                    width: 62px;
                    height: 53px;
                }
            }
        }
    }
}
.lading-before {
    .top-v {
        padding: 120px 20px 50px;
        background: $color-point;
        color: #fff;
        .inner {
            strong {
                font-size: 28px;
            }
            p {
                max-width: 340px;
                margin-top: 10px;
                font-size: 16px;
                line-height: 26px;
                br {
                    display: none
                }
            }
            a {
                @include jBtn;
                margin-top: 40px;
            }
        }
        @include respond-to(pc) {
            padding: {
                top: 185px;
                bottom: 285px;
            }
            .inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 1035px;
                margin: 0 auto;
                strong {
                    font-size: 32px;
                }
                p {
                    font-size: 18px;
                    line-height: 32px;
                }
                a {
                    width: 360px;
                }
                &:after {
                    content: '';
                    width: 534px;
                    height: 523px;
                    background: url('../images/landingimg01.png') no-repeat 50% 50%;
                    background-size: contain;
                }
            }
        }
    }
    .sect-cnts {
        position: relative;
        padding: 0 20px;
        ul {
            li {
                margin-top: 30px;
                padding: 30px;
                background: #FFFFFF;
                box-shadow: 1px 2px 34px -2px rgba(131, 131, 131, 0.15);
                border-radius: 20px;
                > div {
                    strong {
                        font-size: 26px;
                        span {
                            display: block;
                            margin-bottom: 10px;
                            color: rgba($color: #0D1240, $alpha: 0.4);
                            font: {
                                weight: normal;
                                size: 16px;
                            }
                        }
                    }
                    p {
                        margin-top: 10px;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
        @include respond-to(pc) {
            margin-top: -180px;
            background: url('../images/landingbg.png') no-repeat 50% 50%;
            ul {
                max-width: 1040px;
                margin: 0 auto;
                li {
                    display: flex;
                    align-items: center;
                    margin-top: 60px;
                    padding: 60px 65px;
                    &:before {
                        content: '';
                        width: 60%;
                        max-width: 460px;
                        height: 438px;
                        background: url('../images/landingimg0201.png') no-repeat 50% 0;
                        background-size: contain;
                    }
                    > div {
                        width: 40%;
                        padding-left: 48px;
                        strong {
                            font-size: 32px;
                            line-height: 48px;
                            span {
                                margin-bottom: 0;
                            }
                        }
                        p {
                            line-height: 32px;
                        }
                    }
                    &:first-child {
                        margin-top: 0;
                        &:before {
                            height: 395px;
                        }
                    }
                    &:nth-child(2) {
                        &:before {
                            background-image: url('../images/landingimg0202.png');
                        }
                    }
                    &:nth-child(3) {
                        &:before {
                            background-image: url('../images/landingimg0203.png');
                        }
                    }
                }
            }
        }
    }
    .bgbox {
        margin-top: 80px;
        padding: 0 20px;
        background: $color-def;
        box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.15);
        h3 {
            color: #fff;
            font: {
                size: 26px;
                weight: normal;
            }
            em {
                display: block;
                font-weight: bold;
            }
        }
        > div {
            padding-top: 60px;
            &.solution-w {
                h3 {
                    text-align: center;
                }
                ul {
                    li {
                        display: flex;
                        align-items: center;
                        margin-top: 30px;
                        padding: 30px 20px;
                        background: #fff;
                        border-radius: 15px;
                        box-sizing: border-box;
                        &:before {
                            content: '';
                            min-width: 100px;
                            height: 100px;
                            margin-right: 20px;
                            background: url('../images/landingimg0301.png') no-repeat 50% 50%;
                            background-size: contain;
                        }
                        > div {
                            font-size: 16px;
                            p {
                                margin-top: 5px;
                                color: rgba($color: $color-def, $alpha: 0.6);
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                        &.sol2 {
                            &:before {
                                background-image: url('../images/landingimg0302.png');
                            }
                        }
                        &.sol3 {
                            &:before {
                                background-image: url('../images/landingimg0303.png');
                            }
                        }
                        &.sol4 {
                            &:before {
                                background-image: url('../images/landingimg0304.png');
                            }
                        }
                    }
                }
            }
            &.jobtype-data {
                display: flex;
                padding-bottom: 60px;
                .graph {
                    min-width: 100px;
                    margin-right: 30px;
                    img {
                        width: 100%;
                    }
                }
                .cnts {
                    ul {
                        padding-top: 10px;
                        color: #fff;
                        font-size: 16px;
                        li {
                            display: flex;
                            margin-top: 10px;
                            em {
                                margin: 0 5px;
                                font-weight: bold;
                            }
                            &:before {
                                content: '';
                                width: 8px;
                                height: 8px;
                                margin: 5px 8px 0 0;
                                background: $color-point;
                                border-radius: 50%;
                            }
                            &.designer {
                                &:before {
                                    background: #96FFC6;
                                }
                            }
                            &.planner {
                                &:before {
                                    background: #E0FE52;
                                }
                            }
                            &.etc {
                                &:before {
                                    background: #FFB494;
                                }
                            }
                        }
                    }
                }
            }
        }
        @include respond-to(pc) {
            margin-top: -260px;
            padding-top: 320px;
            h3 {
                font-size: 32px;
                em {
                    display: inline;
                }
            }
            > div {
                &.solution-w {
                    max-width: 1242px;
                    margin: 0 auto;
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        li {
                            width: calc(50% - 13px);
                        }
                    }
                }
                &.jobtype-data {
                    max-width: 1017px;
                    padding: {
                        top: 148px;
                        bottom: 125px;
                    }
                    margin: 0 auto;
                    background: url('../images/graphimg.png') no-repeat 100% 100%;
                    .graph {
                        width: 290px;
                        height: 290px;
                        margin-right: 60px;
                    }
                    .cnts {
                        margin-top: 10px;
                        ul {
                            font-size: 20px;
                            li {
                                margin-top: 16px;
                                &:before {
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
                }
            }
            @include respond-to(pcMain) {
                > div {
                    &.solution-w {
                        ul {
                            li {
                                flex-direction: column;
                                width: calc(25% - 22px);
                                padding: 30px;
                                &:before {
                                    width: 172px;
                                    height: 174px;
                                }
                                > div {
                                    margin-top: 19px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .pricing-w {
        margin-top: 20px;
        padding: 45px 0;
        box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.15);
        h3 {
            font-size: 28px;
            text-align: center;
        }
        .viewport {
            overflow: hidden;
            overflow-x: auto;
            padding: 20px 20px 0;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none; /* Chrome, Safari, Opera*/
            }
            table {
                width: 796px;
                margin: 0 auto;
                border-collapse: collapse;
                th, td {
                    border: 4px solid #fff;
                }
                thead {
                    th {
                        padding: 20px 0;
                        border-radius: 20px 20px 0 0;
                        border: {
                            top: none;
                            bottom: none;
                        }
                        &.basic {
                            background: $color-point;
                            color: #fff;
                        }
                        &.memship {
                            background: #E0FE52;
                            color: $color-def;
                            font-size: 18px;
                        }
                    }
                }
                tbody {
                    font-size: 14px;
                    th, td {
                        height: 61px;
                        border-radius: 6px;
                    }
                    th {
                        padding-left: 20px;
                        background: rgba($color: $color-def, $alpha: 0.02);
                        text-align: left;
                    }
                    td {
                        width: 283px;
                        background: rgba(85, 57, 255, 0.03);
                        color: $color-point;
                        text-align: center;
                        .tok {
                            display: block;
                            width: 40px;
                            height: 31px;
                            margin: 0 auto;
                            background: url('../images/icon_chk.png') no-repeat 0 50%;
                            background-size: contain;
                            font-size: 1px;
                            text: {
                                indent: -9999px;
                                align: left;
                            }
                        }
                        &.memship {
                            background: rgba($color: #E0FE52, $alpha: 0.05);
                            color: rgba($color: $color-def, $alpha: 0.6);
                        }
                    }
                }
            }
        }
        > p {
            max-width: 796px;
            margin: 20px auto 0;
            padding-right: 20px;
            color: rgba($color: $color-def, $alpha: 0.4);
            font-size: 12px;
            text-align: right;
            box-sizing: border-box;
        }
    }
    .join-info {
        padding: 50px 20px;
        background: $color-point;
        color: #fff;
        .inner {
            max-width: 1040px;
            margin: 0 auto;
            .cnts {
                max-width: 440px;
                p {
                    margin-top: 10px;
                    font: {
                        size: 32px;
                        weight: bold;
                    }
                    line-height: 48px;
                    br {
                        display: none
                    }
                }
                a {
                    @include jBtn;
                    margin-top: 40px;
                }
            }
        }
        @include respond-to(pc) {
            .inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .cnts {
                    padding-right: 30px;
                }
                &:after {
                    content: '';
                    width: 432px;
                    height: 425px;
                    background: url('../images/how_rec04.png') no-repeat 50% 50%;
                    background-size: contain;
                }
            }
        }
    }
}
//v3
.landing-cnts-v3 {
    padding-bottom: 40px;
    .top-v {
        max-width: 1073px;
        margin: 0 auto;
        padding: 35px 30px;
        .inner {
            .cnts {
                display: flex;
                flex-direction: column;
                color: $color-def;
                > span {
                    font-size: 18px;
                    font-weight: 600;
                }
                a {
                    @include landingBtn;
                    margin-top: 24px;
                    margin-right: auto;
                    padding-left: 20px;
                }
            }
            strong {
                margin-top: 10px;
                font-size: 34px;
                transition: font-size 0.5s;
            }
            p {
                margin-top: 16px;
                color: #8C8C8C;
                line-height: 24px;
                font-weight: bold;
            }
            .img {
                display: block;
                margin-top: 40px;
                img {
                    width: 80%;
                }
            }
        }
        @include respond-to(pc2) {
            padding: 85px 30px 70px;
            .inner {
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 1060px;
                margin: 0 auto;
                .cnts {
                    > span {
                        font-size: 24px;
                    }
                }
                strong {
                    margin-top: 20px;
                    font-size: 60px;
                }
                p {
                    font-size: 24px;
                    line-height: 36px;
                }
                .img {
                    width: 43%;
                    max-width: 500px;
                    margin-top: 0;
                    margin-left: 30px;
                }
            }
        }
    }
    .partners {
        @include scrollN;
        overflow: auto;
        margin-top: 80px;
        > p {
            max-width: 1040px;
            margin: 0 auto;
            padding: 0 16px;
            color: #8E9496;
            font: {
                size: 20px;
                weight: 700;
            }
            b {
                color: $color-def;
            }
        }
        ul {
            display: flex;
            //justify-content: center;
            li {
                min-width: 120px;
                max-width: 120px;
                margin-top: 30px;
                padding: 0 18px;
                img {
                    width: 100%;
                }
            }
        }
        &.corp {
            ul {
                flex-wrap: wrap;
            }
        }
        @include respond-to(pc2) {
            margin-top: 160px;
            > p {
                margin-bottom: 30px;
                font-size: 40px;
            }
            ul {
                justify-content: center;
                li {
                    padding: 0 32px;
                }
            }
            &.corp {
            }
        }
    }
    .res-update {
        margin-top: 60px;
        padding: 0 16px 60px;
        text-align: center;
        h3 {
            color: $color-point;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        > p {
            margin-top: 18px;
            font: {
                size: 18px;
                weight: 700;
            }
            line-height: 26px;
            em {
                color: $color-point;
            }
        }
        .up-list {
            max-width: 920px;
            padding-top: 20px;
            margin: 0 auto;
            font: {
                size: 16px;
                weight: 700;
            }
            text-align: left;
            ul {
                li {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin: 12px 0;
                    padding: 20px;
                    background: #fff;
                    border: 1px solid #EAECF0;
                    border-radius: 16px;
                    box-shadow: 0 0 0 4px #EFF8FF;
                    .logo {
                        overflow: hidden;
                        min-width: 36px;
                        width: 48px;
                        height: 48px;
                        border-radius: 3px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .info {
                        flex: 1;
                        padding: 0 16px;
                        > span {
                            color: #475467;
                        }
                    }
                    .result {
                        display: flex;
                        align-items: center;
                        min-width: calc(100% - 20px);
                        margin-top: 10px;
                        margin-left: auto;
                        padding: 10px;
                        border-radius: 16px;
                        background: #EFF8FF;
                        color: #175CD3;
                        font-size: 14px;
                        &:before {
                            content: '';
                            display: block;
                            width: 8px;
                            height: 8px;
                            margin-right: 6px;
                            border-radius: 50%;
                            background: #175CD3;
                        }
                    }
                    &.propose {
                        box-shadow: 0 0 0 4px #F2EDFF;
                        .result {
                            background: #F2EDFF;
                            color: #7C45F7;
                            &:before {
                                background: #7C45F7;
                            }
                        }
                    }
                    &.employ {
                        box-shadow: 0 0 0 4px #ECFDF3;
                        .result {
                            background: #ECFDF3;
                            color: #027948;
                            &:before {
                                background: #027948;
                            }
                        }
                    }
                }
            }
        }
        @include respond-to(pc2) {
            margin-top: 120px;
            padding-bottom: 120px;
            background: url('../images/update_bg.png') no-repeat 50% 0;
            > p {
                margin-top: 24px;
                font-size: 36px;
                line-height: 52px;
            }
            .up-list {
                padding-top: 72px;
                ul {
                    li {
                        padding: 20px 32px;
                        .logo {
                            min-width: 72px;
                            width: 72px;
                            height: 72px;
                        }
                        .info {
                            padding: 0 32px;
                        }
                        .result {
                            min-width: auto;
                            padding: 20px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .fav-pjt {
        max-width: 1012px;
        margin: 0 auto;
        padding: 0 20px 100px;
        text-align: center;
        h3 {
            color: $color-point;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        > p {
            margin-top: 18px;
            font: {
                size: 18px;
                weight: 700;
            }
            line-height: 26px;
            em {
                color: $color-point;
            }
            &.desc {
                margin-top: 0;
                color: #8E9496;
                font-size: 16px;
            }
        }
        .ms-list {
            column-width: 324px;
            gap: 20px;
            padding-top: 60px;
            text-align: left;
            .inbox {
                margin-bottom: 20px;
                padding: 32px;
                border: 1px solid #D6D6DA;	
                border-radius: 20px;
                break-inside: avoid;
                box-sizing: border-box;
                > a {
                    display: block;
                }
                .recomd {
                    display: block;
                    position: relative;
                    margin-bottom: 20px;
                    padding: 16px 13px 16px 26px;
                    background: #EFF8FF;
                    border-radius: 12px;
                    color: #175CD3;
                    font-size: 14px;
                    span {
                        text-decoration: underline;
                    }
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 16px;
                        left: 13px;
                        width: 6px;
                        height: 6px;
                        margin-top: 5px;
                        margin-right: 7px;
                        background: #175CD3;
                        border-radius: 50%;
                    }
                }
                .cnts {
                    margin-top: 24px;
                    line-height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    ul {
                        margin-top: 30px;
                        li {
                            padding-left: 12px;
                            text-indent: -12px;
                            &:before {
                                content: '•';
                                margin-right: 5px;
                            }
                        }
                    }
                    p {
                        margin-top: 30px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
                h4 {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    // &:before {
                    //     content: '';
                    //     min-width: 32px;
                    //     width: 32px;
                    //     height: 32px;
                    //     margin-right: 12px;
                    //     background: url('../images/icon_profile.svg') no-repeat 0 0;
                    // }
                }
                .pjt {
                    display: flex;
                    flex-direction: column;
                    // margin-top: 30px;
                    em {
                        color: #B4B4B9;
                        font: {
                            size: 11px;
                            weight: bold;
                        }
                    }
                    strong {
                        margin-top: 8px;
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
                .hash-pj {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 28px;
                    height: 74px;
                    overflow: hidden;
                    span {
                        margin-top: 6px;
                        margin-right: 6px;
                        padding: 8px 12px;
                        background: #FFEFEB;
                        border-radius: 6px;
                        color: #CC0905;
                        font-weight: 600;
                        &.ml {
                            background: #EBF7FF;
                            color: #0452C8;
                        }
                        &.node {
                            background: #EBFFF1;
                            color: #119C2B;
                        }
                        &.human {
                            background: #E4FCFC;
                            color: #0D9676;
                        }
                        &.ux {
                            background: #FFFCC2;
                            color: #835101;
                        }
                        &.aie {
                            background: #FCF0FF;
                            color: #7E10E5;
                        }
                    }
                }
                &.c1 {
                    .cnts {
                        color: #F74;
                    }
                }
                &.c2 {
                    .cnts {
                        color: #09f;
                    }
                }
                &.c3 {
                    .cnts {
                        color: #504BFF;
                    }
                }
                &.c4 {
                    .cnts {
                        color: #2A9;
                    }
                }
            }
        }
        .btn-more {
            margin-top: 30px;
            button {
                padding: 15px 20px 14px;
                background: #FFF;
                border: 1px solid #D0D5DD;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
                color: #344054;
                font: {
                    size: 16px;
                    weight: 700;
                }
            }
        }
        @include respond-to(pc2) {
            > p {
                margin-top: 24px;
                font-size: 36px;
                line-height: 52px;
                &.desc {
                    margin-top: 12px;
                    font-size: 24px;
                }
            }
            .btn-more {
                margin-top: 60px;
            }
        }
    }
    .pjt-input {
        padding: 50px 16px;
        background: #FCFCFD;
        h3 {
            font-size: 22px;
            text-align: center;
        }
        .btn {
            display: flex;
            justify-content: center;
            a, button {
                width: auto;
                height: 55px;
                margin: 0 -2px;
                padding: 0 12px;
                background: #fff;
                font: {
                    size: 16px;
                    weight: bold;
                }
                border: 1px solid #D0D5DD;
                border-radius: 8px;
            }
        }
        .mem-rev {
            max-width: 1112px;
            margin: 0 auto;
            ul {
                li {
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                    border-radius: 20px;
                    div {
                        flex: 1;
                        padding: 32px 32px 40px;
                        background: linear-gradient(180deg, #00B900 0%, rgba(0, 185, 0, 0.8) 50%, rgba(0, 185, 0, 0.5) 100%);
                        color: #fff;
                        font-weight: 700;
                        em {
                            font-size: 20px;
                            &:before {
                                content: '';
                                display: block;
                                width: 20px;
                                height: 15px;
                                margin-bottom: 20px;
                                background: url('../images/icon_comma.svg') no-repeat 0 0;
                            }
                        }
                        p {
                            margin-top: 20px;
                            font-size: 24px;
                        }
                    }
                    .img {
                        display: block;
                        width: 100%;
                        height: 160px;
                        margin-top: auto;
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    &.c2 {
                        div {background: linear-gradient(180deg, #FFD200 0%, rgba(255, 210, 0, 0.9) 48.96%, rgba(255, 210, 0, 0.5) 100%, rgba(255, 210, 0, 0.5) 100%, rgba(255, 210, 0, 0.8) 100%);
                        }
                    }
                    &.c3 {
                        div {
                            background: linear-gradient(180deg, #2AC1BC 0%, rgba(42, 193, 188, 0.8) 49.48%, rgba(42, 193, 188, 0.5) 100%);
                        }
                    }
                }
            }
        }
        &.corp {
            max-width: 1112px;
            margin: 0 auto;
            background: #fff;
            > p {
                margin-bottom: 30px;
                font: {
                    size: 22px;
                    weight: bold;
                }
                &:before {
                    content: '💬';
                    display: block;
                }
            }
        }
        @include respond-to(pc2) {
            padding: 100px 16px;
            h3 {
                font-size: 22px;
            }
            .btn {
                a, button {
                    padding: 0 24px;
                    font-size: 20px;
                }
            }
            .mem-rev {
                margin-top: 65px;
                ul {
                    display: flex;
                    li {
                        width: 33.3%;
                        margin: 0 8px;
                    }
                }
            }
            &.corp {
                padding-top: 160px;
                > p {
                    margin-bottom: 60px;
                    padding: 0 40px;
                    font-size: 44px;
                }
            }
        }
    }
    .service {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 1080px;
        margin: 30px 16px 0;
        padding: 30px;
        background: linear-gradient(0deg, #262838, #262838), linear-gradient(0deg, #263238, #263238), linear-gradient(0deg, #FFF28A, #FFF28A), #F6F6F8;
        border-radius: 16px;
        box-sizing: border-box;
        font-weight: 700;
        > span {
            color: #EAECF0;
            font-size: 20px;
        }
        > strong {
            margin-top: 10px;
            color: #EAECF0;
            font-size: 32px;
            line-height: 48px;
        }
        p {
            margin-top: 24px;
            color: #A9A9B7;
            font-size: 18px;
            line-height: 32px;
        }
        > a, > button {
            margin-top: 32px;
            margin-right: auto;
            padding: 16px 20px;
            background: #5539FF;
            color: #fff;
            font-size: 20px;
            border-radius: 8px;
        }
        @include respond-to(pc2) {
            min-height: 520px;
            margin: 60px auto;
            padding: 70px 20px 70px 460px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 107px;
                left: 102px;
                width: 258px;
                height: 306px;
                background: url('../images/landingmem03.png') no-repeat 0 0;
                background-size: cover;
            }
            > strong {
                font-size: 36px;
            }
            p {
                font-size: 20px;
            }
        }
    }
    //company
    .corptop-v {
        display: flex;
        flex-direction: column;
        padding: 36px 32px 0;
        > span {
            margin-bottom: 10px;
            font: {
                size: 18px;
                weight: 700;
            }
        }
        strong {
            font-size: 34px;
        }
        p {
            margin-top: 20px;
            color: #8C8C8C;
            font-weight: 700;
            line-height: 24px;
        }
        > a {
            @include landingBtn;
            margin-top: 24px;
            margin-right: auto;
        }
        @include respond-to(pc) {
            align-items: center;
            //height: 600px;
            margin-top: 80px;
            background: url('../images/landingcorp03_pc.png') no-repeat 50% 0;
            background-size: 1440px auto;
            box-sizing: border-box;
            text-align: center;
            > span {
                margin-bottom: 20px;
                font-size: 24px;
            }
            strong {
                font-size: 56px;
                line-height: 72px;
            }
            p {
                margin-top: 28px;
                font-size: 24px;
                line-height: 36px;
            }
            a {
                margin: 40px auto 0;
            }
            &:after {
                display: none;
            }
        }
    }
    .prob-solv {
        max-width: 1060px;
        margin: 0 auto;
        padding: 0 16px;
        h3 {
            font-size: 22px;
            &:before {
                content: '🤔';
                display: block;
            }
        }
        ul {
            li {
                margin-top: 15px;
                color: #8E9496;
                font-weight: 700;
                &:before {
                    content: '👉';
                    margin-right: 7px;
                }
            }
        }
        @include respond-to(pc2) {
            display: flex;
            margin-top: 80px;
            h3 {
                font-size: 44px;
            }
            ul {
                margin-top: 50px;
                margin-left: auto;
                padding-left: 40px;
                font-size: 24px;
            }
        }
    }
    .service-tw {
        margin-top: 80px;
        padding: 40px 16px 90px;
        background: linear-gradient(0deg, #FAFBFC, #FAFBFC), linear-gradient(0deg, #FAFAFA, #FAFAFA), #FFF;
        > h3 {
            color: $color-point;
            text-align: center;
        }
        > div {
            max-width: 1080px;
            margin: 0 auto;
            > p {
                color: $color-def;
                font: {
                    size: 24px;
                    weight: 700;
                }
                text-align: center;
            }
            .service-w {
                margin-top: 40px;
                .inbox {
                    overflow: hidden;
                    margin: 24px 0 0;
                    padding: 24px;
                    background: url('../images/boxbg01.png') no-repeat 100% 100%;
                    background-size: cover;
                    box-shadow: 0px 20px 16px rgba(0, 0, 0, 0.04), 0px 8px 8px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02);
                    border-radius: 20px;
                    box-sizing: border-box;
                    strong {
                        font-size: 20px;
                    }
                    p {
                        margin-top: 24px;
                        color: #8E9496;
                        font: {
                            weight: 700;
                        }
                    }
                    ul {
                        margin-top: 24px;
                        li {
                            position: relative;
                            padding-left: 35px;
                            color: #8E9496;
                            font: {
                                weight: 700;
                            }
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 5px;
                                left: 0;
                                width: 22px;
                                height: 24px;
                                background: url('../images/icon_check_r.svg') no-repeat 0 0;
                            }
                        }
                    }
                    &.d2 {
                        background-image: url('../images/boxbg02.png');
                        strong {
                            color: #fff;
                        }
                    }
                    &.d3 {
                        background-color: #5539FF;
                        background-image: none;
                        strong {
                            color: #fff;
                        }
                        p {
                            color: #fff;
                        }
                    }
                    &.ds1 {
                        background-image: url('../images/box2bg01.png');
                        strong, p {
                            color: #fff;
                        }
                    }
                    &.ds2 {
                        background-image: url('../images/box2bg02.png');
                    }
                    &.ds3 {
                        background-image: url('../images/box2bg03.png');
                    }
                    &.ds4 {
                        background-image: url('../images/box2bg04.png');
                        strong, ul > li {
                            color: #fff;
                        }
                        ul {
                            li {
                                &:before {
                                    background-image: url('../images/icon_check_rw.svg');
                                }
                            }
                        }
                    }
                }
            }
            &.service3 {
                margin-top: 70px;
            }
        }
        @include respond-to(pc2) {
            margin-top: 160px;
            padding-top: 80px;
            > h3 {
                font-size: 24px;
            }
            > div {
                > p {
                    margin-top: 24px;
                    font-size: 48px;
                    line-height: 64px;
                }
                .service-w  {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 80px;
                    .inbox {
                        width: calc(50% - 12px);
                        min-height: 400px;
                        padding: 48px;
                        strong {
                            font-size: 40px;
                        }
                        p {
                            max-width: 400px;
                            font-size: 24px;
                            line-height: 36px;
                        }
                        ul {
                            li {
                                font-size: 24px;
                                line-height: 36px;
                            }
                        }
                        &.d3 {
                            min-width: 100%;
                            background-image: url('../images/boxbg03.png');
                        }
                    }
                }
                &.service3 {
                    margin-top: 140px;
                }
            }
        }
    }
    .price-w2 {
        max-width: 1080px;
        margin: 0 auto;
        padding: 70px 16px 0;
        > h3 {
            font-size: 24px;
        }
        > p {
            color: #8C8C8C;
            margin-top: 12px;
            font-weight: 700;
        }
        .price-box {
            margin-top: 30px;
            .inbox {
                background: #fff;
                margin-top: 10px;
                border: 1px solid #EAECF0;
                box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
                border-radius: 16px;
                .hgroup {
                    min-height: 132px;
                    position: relative;
                    padding: 32px 32px 24px;
                    box-sizing: border-box;
                    h4 {
                        font-size: 32px;
                    }
                    p {
                        color: #475467;
                        font-weight: bold;
                    }
                    &.lev2{
                        p {
                            position: absolute;
                            top: 32px;
                            right: 32px;
                            em {
                                color: $color-def;
                                font-size: 60px;
                            }
                        }
                    }
                }
                .ser-li {
                    padding: 32px 32px 40px;
                    border-top: 1px solid #EAECF0;
                    h5 {
                        font-size: 18px;
                    }
                    ul {
                        li {
                            position: relative;
                            margin-top: 20px;
                            padding-left: 35px;
                            color: #475467;
                            font-weight: 700;
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 24px;
                                height: 24px;
                                background: url('../images/icon_scheck_y.svg') no-repeat 0 0;
                            }
                            &.no {
                                &:before {
                                    background-image: url('../images/icon_scheck_n.svg');
                                }
                            }
                        }
                    }
                }
            }
        }
        @include respond-to(pc2) {
            padding-top: 140px;
            > h3 {
                font-size: 48px;
            }
            > p {
                margin-top: 24px;
                font-size: 24px;
            }
            .price-box {
                display: flex;
                justify-content: space-between;
                margin-top: 60px;
                .inbox {
                    width: calc(50% - 12px);
                    margin-top: 0;
                    .hgroup {
                        p {
                            font-size: 20px;
                        }
                    }
                    .ser-li {
                        ul {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}