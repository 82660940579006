//common
.btn-del {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: rgba(85, 57, 255, 0.05);
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 1px);
        width: 2px;
        height: 13px;
        background: rgba(13, 18, 64, 0.6);
        border-radius: 2px;
    }
    &:before {
        transform: rotate(-45deg);
    }
    &:after {
        transform: rotate(45deg);
    }
    span {
        @include hiddenText;
    }
}
.btn-add {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    margin-top: 24px;
    border-radius: 2px;
    background-color: rgba(85, 57, 255, 0.05);
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        background: $color-point;
    }
    &:before {
        top: calc(50% - 7px);
        left: calc(50% - 1px);
        width: 2px;
        height: 14px;
    }
    &:after {
        top: calc(50% - 1px);
        left: calc(50% - 7px);
        width: 14px;
        height: 2px;
    }
    span {
        @include hiddenText;
    }
}
.btn-prev {
    a {
        display: block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        background: url('../images/icon_arrow_back.svg') no-repeat 0 0;
        span {
            @include hiddenText;
        }
    }
}
.btn-sticky {
    position: sticky;
    z-index: 20;
    bottom: 0;
    left: 0;
    margin-top: -1px;
    padding: 24px 0;
    background: #fff;
    a, button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        background: $color-point;
        border-radius: 2px;
        color: #fff;
    }
    @include respond-to(pc) {
        position: static;
        margin-top: 64px;
        padding: 0;
    }
}
.btn-out {
    margin-top: 24px;
    a {
        color: rgba(13, 18, 64, 0.4);
        font-size: 12px;
        text-decoration: underline;
    }
    @include respond-to(pc) {
        margin-top: 64px;
    }
}
.btn-more {
    margin-top: 26px;
    text-align: center;
    a {
        color: $color-point;
        text-decoration: underline;
    }
}
.pw-lo {
    margin-top: 24px;
    text-align: center;
    a {
        color: rgba(13, 18, 64, 0.4);
        font-size: 12px;
        text-decoration: underline;
    }
    @include respond-to(pc) {
        margin-top: 64px;
    }
}
.li-more {
    margin-top: 16px;
    text-align: center;
    button {
        color: $color-point;
        text-decoration: underline;
    }
}
p.err {
    margin-top: 10px;
    color: #f00;
    font-size: 12px;
}
.ness {
    &:before {
        content: '*';
    }
    span {
        @include hiddenText;
    }
}
button.btn_pw_v,
button.btn_pw_h {
    position: absolute;
    right: 25px;
    top: 42px;
    width: 20px;
    height: 20px;
    background: url('../images/icon_pw.svg') no-repeat 0 0;
    span {
        @include hiddenText;
    }
}
button.btn_pw_h {
    background-position: 100% 0;
}
.pjt-more {
    margin-top: 20px;
    text-align: right;
    a {
        padding-right: 22px;
        font-size: 14px;
        color: $color-point;
        background: url('../images/icon_arrow02.svg') no-repeat 100% -1px;
        background-size: 20px auto;
        white-space: nowrap;
    }
}
//title
.smtit {
    position: relative;
    padding-left: 15px;
    color: $color-point;
    font-size: 14px;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        width: 7px;
        height: 4px;
        margin-right: 8px;
        border-radius: 10px;
        background: $color-point;
        transform: rotate(-45deg);
    }
}
//contents
.top-box {
    overflow: hidden;
    padding: calc(72px + 16px) 24px 24px;
    background: $color-point;
    color: #fff;
    text-align: center;
    a {
        color: #fff;
    }
    > h2 {
        overflow: hidden;
        color: #fff;
        font: {
            size: 22px;
            weight: normal;
        }
        white-space: nowrap;
        text-overflow: ellipsis;
        em {
            font-weight: bold;
            &:after {
                content: '·';
                margin: 0 5px;
            }
        }
    }
    .cho-date {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        > label {
            margin-right: 18px;
        }
        .d-w {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 14px;
            border-radius: 2px;
            background-color: rgba(13, 18, 64, 0.1);
            input {
                width: 135px;
                height: auto;
                padding: 0;
                color: rgba(255, 255, 255, 0.8);
                font-size: 16px;
            }
            button.cal {
                width: 22px;
                height: 22px;
                background: url('../images/icon_calendar.svg') no-repeat 0 0;
                span {
                    @include hiddenText;
                }
            }
        }
    }
    > p {
        margin-top: 16px;
        line-height: 22px;
        a {
            display: inline-flex;
            align-items: center;
            &:after {
                content: '';
                display: block;
                width: 7px;
                height: 7px;
                margin-left: 10px;
                border: {
                    width: 0 2px 2px 0;
                    style: solid;
                    color: #fff;
                    radius: 2px;
                }
                transform: rotate(-45deg);
            }
            span {
                display: none;
            }
        }
        > span {
            text-decoration: underline;
        }
    }
    .btn-guide {
        margin-top: 19px;
        a {
            padding-left: 18px;
            background: url('../images/icon_guide.svg') no-repeat 0 0;
            text-decoration: underline;
            font-size: 12px;
        }
    }
    .sch-wrap {
        max-width: 600px;
        margin: 24px auto 0;
        p {
            font-size: 22px;
            text-align: center;
            line-height: 34px;
            em {
                font-weight: bold;
            }
        }
        fieldset {
            margin-top: 24px;
            padding-bottom: 8px;
            .sel {
                display: flex;
                justify-content: space-between;
                select {
                    width: calc(50% - 3px);
                    background-color: #fff;
                    font-size: 16px;
                    &:disabled {
                        opacity: 1;
                        color: rgba(13, 18, 64, 0.4);
                    }
                    &:last-child {
                        width: calc(50% - 4px);
                    }
                }
            }
            .int {
                display: flex;
                position: relative;
                margin-top: 8px;
                padding: 8px 8px 8px 0;
                background: #fff;
                border-radius: 2px;
                input {
                    height: 40px;
                    background: none;
                    &::placeholder {
                        opacity: 1;
                        color: rgba(13, 18, 64, 0.4);
                    }
                }
                button {
                    position: relative;
                    min-width: 40px;
                    height: 40px;
                    span {
                        @include hiddenText;
                    }
                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                    }
                    &.sch {
                        background-color: rgba(85, 57, 255, 0.05);
                        border-radius: 2px;
                        &:before {
                            top: 11px;
                            left: 11px;
                            width: 15px;
                            height: 15px;
                            box-sizing: border-box;
                            border: 2px solid $color-point;
                            border-radius: 50%;
                        }
                        &:after {
                            top: 23px;
                            left: 25px;
                            width: 2px;
                            height: 7px;
                            background: $color-point;
                            transform: rotate(-45deg);
                        }
                    }
                    &.delete {
                        width: 40px;
                        height: 40px;
                        margin-right: 8px;
                        &:before,
                        &:after {
                            top: calc(50% - 9px);
                            left: 50%;
                            width: 2px;
                            height: 19px;
                            background-color: rgba(13, 18, 64, 0.6);
                            border-radius: 2px;
                        }
                        &:before {
                            transform: rotate(-45deg);
                        }
                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        .btn-reset {
            width: 100%;
            margin-top: 8px;
            color: #fff;
            font-weight: 16px;
            text: {
                align: center;
                decoration: underline;
            }
        }
    }
    &.in-prev {
        display: flex;
        justify-content: center;
        h2 {
            line-height: 34px;
            white-space: normal;
        }
    }
    @include respond-to(pc) {
        position: relative;
        padding: {
            top: calc(72px + 46px);
            bottom: 40px;
        }
        > p {
            a {
                span {
                    display: inline;
                    &:before {
                        content: '·';
                        margin: 0 5px;
                    }
                }
            }
        }
        .btn-guide {
            position: absolute;
            top: 88px;
            right: 40px;
            margin-top: 0;
        }
        .sch-wrap {
            margin-top: 0;
            fieldset {
                padding-bottom: 0;
            }
        }
        &.in-prev {
            position: relative;
            .btn-prev {
                position: absolute;
                top: 112px;
                left: 40px;
            }
        }
    }
}
.paging {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    a, strong {
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        > span {
            @include hiddenText;
        }
    }
    > a {
        margin: 0 4px;
        background: url('../images/icon_paging.svg') no-repeat 50% 50%;
        &.btn-next {
            transform: rotate(180deg);
        }
        &.disb {
            opacity: 0.4;
        }
    }
    > span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: center;
        a, strong {
            margin: 0 4px;
            border-radius: 4px;
        }
        a {
            background-color: rgba(85, 57, 255, 0.03);
            color: rgba(85, 57, 255, 0.4);
        }
        strong {
            background-color: rgba(85, 57, 255, 0.05);
            color: $color-point;
        }
    }
}
.cate-w {
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    > span, > a {
        @include cateBox;
        margin: 8px 8px 0 0;
    }
    > a {
        color: $color-point;
        &.sns {
            display: inline-flex;
            &:before {
                content: '';
                min-width: 18px;
                height: 18px;
                margin-right: 9px;
                background: url('../images/icon_sns_p.svg') no-repeat 0 0;
            }
            &.dribbble {
                &:before {
                    background-position: -32px 0;
                }
            }
            &.github {
                &:before {
                    background-position: -64px 0;
                }
            }
            &.blog {
                &:before {
                    background-position: -96px 0;
                }
            }
            &.behance {
                &:before {
                    background-position: -128px 0;
                }
            }
            &.insta {
                &:before {
                    background-position: -160px 0;
                }
            }
        }
        &.dir-go {
            &:after {
                content: '';
                width: 12px;
                height: 12px;
                margin-left: 8px;
                background: url('../images/icon_dirgo.svg') no-repeat 100% 0;
            }
        }
    }
}
.fm-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: 56px;
    padding: 4px 8px 6px;
    background-color: rgba(85, 57, 255, 0.03);
    box-sizing: border-box;
    > span {
        display: flex;
        align-items: center;
        position: relative;
        height: 42px;
        margin: 4px 8px 4px 0;
        padding: 8px 36px 8px 16px;
        background-color: rgba(85, 57, 255, 0.05);
        border: none;
        color: rgba(85, 57, 255, 0.8);
        box-sizing: border-box;
        button {
            position: absolute;
            top: 6px;
            right: 5px;
            width: 30px;
            height: 30px;
            box-sizing: border-box;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 7px;
                left: 50%;
                width: 2px;
                height: 13px;
                background: $color-point;
                border-radius: 2px;
            }
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
            span {
                @include hiddenText;
            }
        }
    }
    .in-sel {
        position: relative;
        flex: 1;
        input[type="text"] {
            height: 42px;
            margin-top: 0;
            padding: 4px 0 0 4px;
            background: none;
        }
        .opt-li {
            overflow: auto;
            display: none;
            position: absolute;
            top: 42px;
            left: 0;
            background: #fff;
            width: 200px;
            max-height: 210px;
            padding: 8px;
            box-shadow: 4px 8px 16px 0 rgba(13, 18, 64, 0.15);
            box-sizing: border-box;
            ul {
                li {
                    display: block;
                    height: 32px;
                    padding: 0 8px;
                    color: rgba(13, 18, 64, 0.6);
                    font-size: 14px;
                    line-height: 32px;
                    border-radius: 2px;
                    cursor: pointer;
                    &:hover,
                    &.selected {
                        background-color: rgba(85, 57, 255, 0.03);
                    }
                }
            }
        }
        &.selected {
            z-index: 5;
            .opt-li {
                display: block;
            }
        }
    }
}
.int-form {
    position: relative;
    margin-top: 8px;
    .file-up {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 40px;
        padding: 0 12px;
        background: $color-point;
        color: #fff;
        font-size: 14px;
        line-height: 40px;
        border-radius: 2px;
    }
}
.num-w {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 0 24px 0 0;
    background-color: rgba(85,57,255,0.03);
    color: $color-def;
    input {
        margin-top: 0 !important;
        background: none;
        font-size: 14px;
        text-align: right;
        &::placeholder {
            text-align: left;
        }
    }
    span {
        white-space: nowrap;
    }
}
//country list
.country-list {
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    top: 85px;
    left: 0;
    width: 100%;
    max-height: 200px;
    padding: 0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid #ccc;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    .country {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        > span {
            padding-left: 7px;
        }
        .dial-code {
            color: #999;
        }
        &.highlight,
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
}
.intl-tel-input {
    display: flex;
    position: relative;
    margin-top: 8px;
    .hide {
        display: none; 
    }
    .v-hide {
        visibility: hidden;
    }
    .flag-container {
        height: 56px;
        .selected-flag {
            display: flex;
            align-items: center;
            position: relative;
            width: 100px;
            height: 100%;
            margin-right: 8px;
            padding-left: 24px;
            background-color: rgba(85, 57, 255, 0.05);
            box-sizing: border-box;
            .arrow {
                position: absolute;
                right: 24px;
                width: 0; 
                height: 0; 
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $color-point;
                &.up {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .tel {
        flex: 1;
        > div {
            position: relative;
            margin-top: 8px;
            input {
                padding-right: 110px;
            }
            button {
                position: absolute;
                top: 8px;
                right: 8px;
                min-width: 97px;
                height: 40px;
                background: $color-point;
                color: #fff;
                line-height: 40px;
                border-radius: 2px;
                &.inc {
                    background-color: rgba(85, 57, 255, 0.05);
                    color: $color-point;
                }
            }
            &:first-child {
                margin-top: 0;
            }
        }
    }
    .inc-time {
        position: absolute;
        top: -23px;
        right: 0;
        color: rgba(13, 18, 64, 0.6);
        font-size: 14px;
    }
    > button {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 105px;
        height: 40px;
        background: $color-point;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        &:disabled {
            background-color: rgba(13, 18, 64, 0.05);
            color: rgba(13, 18, 64, 0.4);
        }
    }
    &.disabled {
        .flag-container {
            .selected-flag {
                background-color: rgba(13, 18, 64, 0.05);
                .arrow {
                    border-top-color: rgba(13, 18, 64, 0.4);
                }
            }
        }
    }
}
.iti-flag {
    width: 20px;
    height: 15px;
    background: {
        image: url('../images/sel_flags.png');
        repeat: no-repeat;
        size: 5630px 15px;
        color: #dbdbdb;
        position: 20px 0;
    }
    box-shadow: 0px 0px 1px 0px #888;
    &.ac {background-position: 0 0;}
    &.ad {background-position: -22px 0;}
    &.ae {background-position: -44px 0;}
    &.af {background-position: -66px 0;}
    &.ag {background-position: -88px 0;}
    &.ai {background-position: -110px 0;}
    &.al {background-position: -132px 0;}
    &.am {background-position: -154px 0;}
    &.ao {background-position: -176px 0;}
    &.aq {background-position: -198px 0;}
    &.ar {background-position: -220px 0;}
    &.as {background-position: -242px 0;}
    &.at {background-position: -264px 0;}
    &.au {background-position: -286px 0;}
    &.aw {background-position: -308px 0;}
    &.ax {background-position: -330px 0;}
    &.az {background-position: -352px 0;}
    &.ba {background-position: -374px 0;}
    &.bb {background-position: -396px 0;}
    &.bd {background-position: -418px 0;}
    &.be {background-position: -440px 0;}
    &.bf {background-position: -460px 0;}
    &.bg {background-position: -482px 0;}
    &.bh {background-position: -504px 0;}
    &.bi {background-position: -526px 0;}
    &.bj {background-position: -548px 0;}
    &.bl {background-position: -570px 0;}
    &.bm {background-position: -592px 0;}
    &.bn {background-position: -614px 0;}
    &.bo {background-position: -636px 0;}
    &.bq {background-position: -658px 0;}
    &.br {background-position: -680px 0;}
    &.bs {background-position: -702px 0;}
    &.bt {background-position: -724px 0;}
    &.bv {background-position: -746px 0;}
    &.bw {background-position: -768px 0;}
    &.by {background-position: -790px 0;}
    &.bz {background-position: -812px 0;}
    &.ca {background-position: -834px 0;}
    &.cc {background-position: -856px 0;}
    &.cd {background-position: -878px 0;}
    &.cf {background-position: -900px 0;}
    &.cg {background-position: -922px 0;}
    &.ch {background-position: -944px 0;}
    &.ci {background-position: -961px 0;}
    &.ck {background-position: -983px 0;}
    &.cl {background-position: -1005px 0;}
    &.cm {background-position: -1027px 0;}
    &.cn {background-position: -1049px 0;}
    &.co {background-position: -1071px 0;}
    &.cp {background-position: -1093px 0;}
    &.cr {background-position: -1115px 0;}
    &.cu {background-position: -1137px 0;}
    &.cv {background-position: -1159px 0;}
    &.cw {background-position: -1181px 0;}
    &.cx {background-position: -1203px 0;}
    &.cy {background-position: -1225px 0;}
    &.cz {background-position: -1247px 0;}
    &.de {background-position: -1269px 0;}
    &.dg {background-position: -1291px 0;}
    &.dj {background-position: -1313px 0;}
    &.dk {background-position: -1335px 0;}
    &.dm {background-position: -1357px 0;}
    &.do {background-position: -1379px 0;}
    &.dz {background-position: -1401px 0;}
    &.ea {background-position: -1423px 0;}
    &.ec {background-position: -1445px 0;}
    &.ee {background-position: -1467px 0;}
    &.eg {background-position: -1489px 0;}
    &.eh {background-position: -1511px 0;}
    &.er {background-position: -1533px 0;}
    &.es {background-position: -1555px 0;}
    &.et {background-position: -1577px 0;}
    &.eu {background-position: -1599px 0;}
    &.fi {background-position: -1621px 0;}
    &.fj {background-position: -1643px 0;}
    &.fk {background-position: -1665px 0;}
    &.fm {background-position: -1687px 0;}
    &.fo {background-position: -1709px 0;}
    &.fr {background-position: -1731px 0;}
    &.ga {background-position: -1753px 0;}
    &.gb {background-position: -1775px 0;}
    &.gd {background-position: -1797px 0;}
    &.ge {background-position: -1819px 0;}
    &.gf {background-position: -1841px 0;}
    &.gg {background-position: -1863px 0;}
    &.gh {background-position: -1885px 0;}
    &.gi {background-position: -1907px 0;}
    &.gl {background-position: -1929px 0;}
    &.gm {background-position: -1951px 0;}
    &.gn {background-position: -1973px 0;}
    &.gp {background-position: -1995px 0;}
    &.gq {background-position: -2017px 0;}
    &.gr {background-position: -2039px 0;}
    &.gs {background-position: -2061px 0;}
    &.gt {background-position: -2083px 0;}
    &.gu {background-position: -2105px 0;}
    &.gw {background-position: -2127px 0;}
    &.gy {background-position: -2149px 0;}
    &.hk {background-position: -2171px 0;}
    &.hm {background-position: -2193px 0;}
    &.hn {background-position: -2215px 0;}
    &.hr {background-position: -2237px 0;}
    &.ht {background-position: -2259px 0;}
    &.hu {background-position: -2281px 0;}
    &.ic {background-position: -2303px 0;}
    &.id {background-position: -2325px 0;}
    &.ie {background-position: -2347px 0;}
    &.il {background-position: -2369px 0;}
    &.im {background-position: -2391px 0;}
    &.in {background-position: -2413px 0;}
    &.io {background-position: -2435px 0;}
    &.iq {background-position: -2457px 0;}
    &.ir {background-position: -2479px 0;}
    &.is {background-position: -2501px 0;}
    &.it {background-position: -2523px 0;}
    &.je {background-position: -2545px 0;}
    &.jm {background-position: -2567px 0;}
    &.jo {background-position: -2589px 0;}
    &.jp {background-position: -2611px 0;}
    &.ke {background-position: -2633px 0;}
    &.kg {background-position: -2655px 0;}
    &.kh {background-position: -2677px 0;}
    &.ki {background-position: -2699px 0;}
    &.km {background-position: -2721px 0;}
    &.kn {background-position: -2743px 0;}
    &.kp {background-position: -2765px 0;}
    &.kr {background-position: -2787px 0;}
    &.kw {background-position: -2809px 0;}
    &.ky {background-position: -2831px 0;}
    &.kz {background-position: -2853px 0;}
    &.la {background-position: -2875px 0;}
    &.lb {background-position: -2897px 0;}
    &.lc {background-position: -2919px 0;}
    &.li {background-position: -2941px 0;}
    &.lk {background-position: -2963px 0;}
    &.lr {background-position: -2985px 0;}
    &.ls {background-position: -3007px 0;}
    &.lt {background-position: -3029px 0;}
    &.lu {background-position: -3051px 0;}
    &.lv {background-position: -3073px 0;}
    &.ly {background-position: -3095px 0;}
    &.ma {background-position: -3117px 0;}
    &.mc {background-position: -3139px 0;}
    &.md {background-position: -3160px 0;}
    &.me {background-position: -3182px 0;}
    &.mf {background-position: -3204px 0;}
    &.mg {background-position: -3226px 0;}
    &.mh {background-position: -3248px 0;}
    &.mk {background-position: -3270px 0;}
    &.ml {background-position: -3292px 0;}
    &.mm {background-position: -3314px 0;}
    &.mn {background-position: -3336px 0;}
    &.mo {background-position: -3358px 0;}
    &.mp {background-position: -3380px 0;}
    &.mq {background-position: -3402px 0;}
    &.mr {background-position: -3424px 0;}
    &.ms {background-position: -3446px 0;}
    &.mt {background-position: -3468px 0;}
    &.mu {background-position: -3490px 0;}
    &.mv {background-position: -3512px 0;}
    &.mw {background-position: -3534px 0;}
    &.mx {background-position: -3556px 0;}
    &.my {background-position: -3578px 0;}
    &.mz {background-position: -3600px 0;}
    &.na {background-position: -3622px 0;}
    &.nc {background-position: -3644px 0;}
    &.ne {background-position: -3666px 0;}
    &.nf {background-position: -3686px 0;}
    &.ng {background-position: -3708px 0;}
    &.ni {background-position: -3730px 0;}
    &.nl {background-position: -3752px 0;}
    &.no {background-position: -3774px 0;}
    &.np {
        background: {
            position: -3796px 0;
            color: transparent;
        }
    }
    &.nr {background-position: -3811px 0;}
    &.nu {background-position: -3833px 0;}
    &.nz {background-position: -3855px 0;}
    &.om {background-position: -3877px 0;}
    &.pa {background-position: -3899px 0;}
    &.pe {background-position: -3921px 0;}
    &.pf {background-position: -3943px 0;}
    &.pg {background-position: -3965px 0;}
    &.ph {background-position: -3987px 0;}
    &.pk {background-position: -4009px 0;}
    &.pl {background-position: -4031px 0;}
    &.pm {background-position: -4053px 0;}
    &.pn {background-position: -4075px 0;}
    &.pr {background-position: -4097px 0;}
    &.ps {background-position: -4119px 0;}
    &.pt {background-position: -4141px 0;}
    &.pw {background-position: -4163px 0;}
    &.py {background-position: -4185px 0;}
    &.qa {background-position: -4207px 0;}
    &.re {background-position: -4229px 0;}
    &.ro {background-position: -4251px 0;}
    &.rs {background-position: -4273px 0;}
    &.ru {background-position: -4295px 0;}
    &.rw {background-position: -4317px 0;}
    &.sa {background-position: -4339px 0;}
    &.sb {background-position: -4361px 0;}
    &.sc {background-position: -4383px 0;}
    &.sd {background-position: -4405px 0;}
    &.se {background-position: -4427px 0;}
    &.sg {background-position: -4449px 0;}
    &.sh {background-position: -4471px 0;}
    &.si {background-position: -4493px 0;}
    &.sj {background-position: -4515px 0;}
    &.sk {background-position: -4537px 0;}
    &.sl {background-position: -4559px 0;}
    &.sm {background-position: -4581px 0;}
    &.sn {background-position: -4603px 0;}
    &.so {background-position: -4625px 0;}
    &.sr {background-position: -4647px 0;}
    &.ss {background-position: -4669px 0;}
    &.st {background-position: -4691px 0;}
    &.sv {background-position: -4713px 0;}
    &.sx {background-position: -4735px 0;}
    &.sy {background-position: -4757px 0;}
    &.sz {background-position: -4779px 0;}
    &.ta {background-position: -4801px 0;}
    &.tc {background-position: -4823px 0;}
    &.td {background-position: -4845px 0;}
    &.tf {background-position: -4867px 0;}
    &.tg {background-position: -4889px 0;}
    &.th {background-position: -4911px 0;}
    &.tj {background-position: -4933px 0;}
    &.tk {background-position: -4955px 0;}
    &.tl {background-position: -4977px 0;}
    &.tm {background-position: -4999px 0;}
    &.tn {background-position: -5021px 0;}
    &.to {background-position: -5043px 0;}
    &.tr {background-position: -5065px 0;}
    &.tt {background-position: -5087px 0;}
    &.tv {background-position: -5109px 0;}
    &.tw {background-position: -5131px 0;}
    &.tz {background-position: -5153px 0;}
    &.ua {background-position: -5175px 0;}
    &.ug {background-position: -5197px 0;}
    &.um {background-position: -5219px 0;}
    &.us {background-position: -5241px 0;}
    &.uy {background-position: -5263px 0;}
    &.uz {background-position: -5285px 0;}
    &.va {background-position: -5307px 0;}
    &.vc {background-position: -5324px 0;}
    &.ve {background-position: -5346px 0;}
    &.vg {background-position: -5368px 0;}
    &.vi {background-position: -5390px 0;}
    &.vn {background-position: -5412px 0;}
    &.vu {background-position: -5434px 0;}
    &.wf {background-position: -5456px 0;}
    &.ws {background-position: -5478px 0;}
    &.xk {background-position: -5500px 0;}
    &.ye {background-position: -5522px 0;}
    &.yt {background-position: -5544px 0;}
    &.za {background-position: -5566px 0;}
    &.zm {background-position: -5588px 0;}
    &.zm {background-position: -5610px 0;}
}
body > .iti-container {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 40px 20px;
    background: rgba($color: #000, $alpha: 0.7);
    box-sizing: border-box;
    .country-list  {
        position: static;
        height: 100%;
        max-height: 100%;
        box-sizing: border-box;
    }
}