@import "utils.scss";

/* reset */
html {
	height: 100%;
}
body {
	height: 100%;
	background: #fff;
	color: $color-def;
	font-size: 16px;
	font-family: $font-def;
	word-wrap: break-word;
	-webkit-text-size-adjust: none;
	//-webkit-font-smoothing: antialiased;
	@include respond-to(pc) {
		font-size: 14px;
	}
}
strong {
	font-weight: bold;
}
body, div, 
dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, form, fieldset, input, select, textarea, p, blockquote, th, td, legend, button, strong, a {
	margin: 0;
	padding: 0;
	word-wrap: break-word;
}
h1, h2, h3, h4, h5, h6 {
	color: $color-def;
}
article, section, nav, header, footer, main {
	display: block;
}
table {
	width: 100%;
	border-spacing: 0;
	th {
		font-weight: normal;
	}
}
form, fieldset, img {
	border:0 none;
}
ul, ol, li{
	list-style: none outside;
}
a {
	text-decoration: none;
	color: $color-def;
}
i, em, address {
	font-style: normal;
}
li, p, dt, dd, div, span {
}
button {
	cursor: pointer;
	-webkit-border-radius: 0;
	border-radius: 0;
	border: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-size: 14px;
    font-family: $font-def;
    background: none;
}
input[type=search]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
legend, caption {
	@include hiddenText;
	position: absolute;
}
hr {
	display: none;
}
input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="search"],
input[type="tel"] {
	width: 100%;
	height: 60px;
	padding: 0 15px;
	background-color: rgba(85, 57, 255, 0.03);
    color: $color-def;
    font: {
		family: $font-def;
		size: 16px;
	}
    outline: 0 none;
	border-radius: 2px;
	border: none;
	box-sizing: border-box;
	-webkit-appearance: none;
    -moz-appearance: none;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	&:disabled {
		color: #999;
		background-color: rgba(13, 18, 64, 0.05);
		opacity: 1;
		-webkit-text-fill-color: #999;
	}
	&.err {
		border: 1px solid #ff4e16;
		background-color: rgba(255, 78, 22, 0.05) !important;
	}
	&.err2 {
		background-color: rgba(255, 78, 22, 0.05) !important;
	}
	@include respond-to(pc) {
		height: 56px;
		font-size: 14px;
	}
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
::placeholder {
	color: rgba(13, 18, 64, 0.4);
}
:-ms-input-placeholder {
    opacity: 0.4 !important;
}
input[type='checkbox'] + label {
	display: inline-block;
	position: relative;
	padding: 0 0 0 23px;
	cursor: pointer;
	color: $color-def;
	font-size: 16px;
	line-height: 26px;
	&:before {
		display: block;
		position: absolute;
		content: '';
		width: 16px;
		height: 16px;
		border: solid 1px rgba(85, 57, 255, 0.4);
		top: 4px;
		left: 0;
		box-sizing: border-box;
		background: #fff;
		border-radius: 4px;
	}
	@include respond-to(pc) {
		font-size: 14px;
	}
}
input[type='checkbox'] {
	position: absolute;
	opacity: 0;
	&:checked + label:after {
		content: '';
		display: block;
		position: absolute;
		top: 7px;
		left: 4px;
		width: 9px;
		height: 6px;
		color: $color-def;
		border: {
			width: 0 0 2px 2px;
			style: solid;
			color: #fff;
		}
		transform: rotate(-45deg);
		box-sizing: border-box;
	}
	&:checked + label:before {
		background: $color-point;
		border: none;
	}
}
input[type='radio'] + label {
	display: inline-block;
	align-items: center;
	position: relative;
	padding: 0 0 0 24px;
	cursor: pointer;
	color: rgba($color: $color-def, $alpha: 0.6);
	font: {
		family: $font-def;
		//size: 16px;
	}
	line-height: 18px;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		min-width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 1px solid rgba(85, 57, 255, 0.4);;
		background: #fff;
		box-sizing: border-box;
	}
}
input[type='radio'] {
	position: absolute;
	opacity: 0;
	&:checked + label:after {
		content: '';
		display: block;
		position: absolute;
		top: 3px;
		left: 3px;
		min-width: 10px;
		height: 10px;
		background: $color-point;
		border-radius: 50%;
	}
}
textarea {
	width: 100%;
	padding: 24px;
	color: rgba(13, 18, 64, 0.4);
	font: {
		family: $font-def;
		size: 16px;
	}
	line-height: 26px;
	vertical-align: top;
	outline: 0 none;
	background-color: rgba(85, 57, 255, 0.03);
	border: none;
	border-radius: 2px;
	box-sizing: border-box;
	resize: none;
	white-space:pre-wrap
}
select {
	height: 60px;
	padding: 0 50px 0 16px;
	background: rgba(85, 57, 255, 0.03) url('../images/icon_arrow.svg') no-repeat 100% 50%;
	border-radius: 2px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: $color-def;
	font-size: 22px;
	vertical-align: top;
	box-sizing: border-box;
	border: none;
	outline: 0 none;
	&:focus {
		border-color: #145646;
	}
	@include respond-to(pc) {
		height: 56px;
	}
}
select::-ms-expand {
	display: none;
}
input[type="file"] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip:rect(0,0,0,0);
	border: 0;
}
/* .file-wrap {
	position: relative;
	label {
		display: inline-block;
		//position: absolute;
		min-width: 100px;
		height: 49px;
		border: 1px solid $color-point;
		border-radius: 0;
		white-space: nowrap;
		box-sizing: border-box;
		color: $color-point;
		font-weight: bold;
		text-align: center;
		line-height: 47px;
	}
	&.type2 {
		display: flex;
		flex-direction: row-reverse;
		input {
			border-right: none;
		}
	}
}
.photo-up {
	position: relative;
	label {
		display: inline-block;
		min-width: 80px;
		height: 80px;
		border-radius: 0;
		white-space: nowrap;
		box-sizing: border-box;
		background: #f3f9f6;
		color: $color-point;
		font-size: 12px;
		text-align: center;
		&:before {
			content: '';
			display: block;
			width: 48px;
			height: 48px;
			margin: 8px auto 0;
			background: url('../images/icon_photo.svg') no-repeat 0 0;
		}
	}
} */