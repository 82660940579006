.def-tab {
    margin-top: 40px;
    border-bottom: 1px solid rgba(13, 18, 64, 0.05);
    ul {
        display: flex;
        font-size: 16px;
        li {
            margin-right: 24px;
            a {
                display: block;
                padding-bottom: 8px;
                color: rgba(13, 18, 64, 0.4);
            }
            &.selected {
                border-bottom: 1px solid $color-def;
                font-weight: bold;
                a {
                    color: $color-def;
                }
            }
        }
    }
    @include respond-to(pc) {
        ul {
            li {
                margin-right: 40px;
            }
        }
    }
}

.profile-box {
    position: relative;
    border-radius: 4px;
    border: solid 1px rgba(85, 57, 255, 0.05);
    background-color: rgba(85, 57, 255, 0.03);
    .def-info {
        position: relative;
        padding: 24px 24px 16px;
        box-sizing: border-box;
        .profile {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            padding-right: 35px;
            //padding-bottom: 24px;
            //border-bottom: 1px solid rgba(85, 57, 255, 0.05);
            .photo {
                min-width: 64px;
                height: 64px;
                margin-right: 16px;
                background: {
                    size: cover;
                }
                border-radius: 50%;
            }
            .info {
                .name {
                    strong, > span {
                        display: block;
                        font-size: 14px;
                    }
                    > span {
                        margin-top: 4px;
                        color: rgba(13, 18, 64, 0.6);
                    }
                }
            }
        }
        > p, .pjt-c {
            margin-top: 24px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 14px;
            line-height: 22px;
            &.line1 {
                overflow: hidden;
                height: 22px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .pjt-c {
            h3 {
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
            }
            p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .date {
            color: rgba(13, 18, 64, 0.4);
            font-size: 14px;
        }
        a.dir-go {
            display: block;
            position: absolute;
            top: 40px;
            right: 35px;
            width: 32px;
            height: 32px;
            background: url('../images/icon_arrow02.svg') no-repeat 0 0;
            span {
                @include hiddenText;
            }
        }
    }
    .sub-info {
        padding: 8px 24px 16px;
        border-top: 1px solid rgba(85, 57, 255, 0.05);
        .ing {
            display: -webkit-inline-flex;
            padding: 10px 12px;
            border-radius: 4px;
            background-color: rgba(13, 18, 64, 0.02);
            font-size: 14px;
            &:before {
                content: '';
                width: 19px;
                height: 19px;
                margin-right: 3px;
                background: url('../images/imo_people.png') no-repeat 0 0;
                background-size: auto 100%;
            }
            b {
                padding-left: 4px;
            }
        }
        .q-cnts {
            margin-top: 16px;
            font-size: 14px;
            line-height: 22px;
        }
        .link-p {
            width: calc(100% + 48px);
            margin: {
                bottom: 14px;
                left: -24px;
            }
            padding: {
                top: 10px;
                left: 24px;
            }
            box-sizing: border-box;
            font-size: 14px;
            a {
                display: inline-flex;
                color: rgba(13, 18, 64, 0.4);
                font-weight: bold;
                &:after {
                    content: '';
                    width: 7px;
                    height: 7px;
                    margin-top: 4px;
                    margin-left: 2px;
                    border: {
                        width: 0 2px 2px 0;
                        style: solid;
                        color: rgba(13, 18, 64, 0.4);
                        radius: 2px;
                    }
                    transform: rotate(-45deg);
                }
            }
        }
        table {
            font-size: 14px;
            th, td {
                padding: 8px 0;
            }
            th {
                color: $color-def;
                font-weight: bold;
                text-align: left;
                vertical-align: top;
            }
            td {
                text-align: right;
                color: rgba(13, 18, 64, 0.6);
            }
        }
        .btn-view {
            position: absolute;
            top: 35px;
            right: 23px;
            width: 32px;
            height: 32px;
            background: url('../images/icon_arrow02.svg') no-repeat 0 0;
            span {
                display: block;
                font-size: 1px;
                text-indent: -9999px;
            }
        }
    }
    @include respond-to(pc) {
        display: flex;
        > div  {
            flex: 1;
            width: 50%;
            &.def-info {
                .profile {
                    width: calc(100% + 48px);
                    margin-left: -24px;
                    padding-right: 0;
                    padding: 0 24px 24px;
                    box-sizing: border-box;
                    border-bottom: 1px solid rgba(85, 57, 255, 0.05);
                }
                > p {
                    &.line1 {
                        height: 50px;
                    }
                }
            }
            &.sub-info {
                display: flex;
                flex-direction: column;
                padding: 16px 24px 24px;
                border-top: none;
                border-left: 1px solid rgba(85, 57, 255, 0.05);
                .link-p {
                    padding-bottom: 20px;
                    border-bottom: 1px solid rgba(85, 57, 255, 0.05);
                }
                .btn-view {
                    position: static;
                    width: auto;
                    height: auto;
                    margin-top: 16px;
                    background: none;
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 48px;
                        border-radius: 2px;
                        background-color: $color-point;
                        color: #fff;
                        span {
                            font-size: 14px;
                            text-indent: 0;
                        }
                    }
                }
                table {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}
.pjt-info {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 24px;
    > h3 {
        margin-top: 40px;
        padding-bottom: 17px;
        font-size: 22px;
        line-height: 34px;
        border-bottom: 1px solid rgba(13, 18, 64, 0.05);
    }
    > h4 {
        @include sTit;
    }
    .info-road {
        margin-top: -35px;
        margin-bottom: 15px;
        text-align: right;
        button {
            color: $color-point;
            font-size: 12px;
            text-decoration: underline;
        }
    }
    .pjt-cnts {
        margin-top: 16px;
        color: rgba(13, 18, 64, 0.6);
        line-height: 22px;
        p {
            white-space: pre-wrap;
        }
        ul + p {
            margin-top: 30px;
        }
        @include respond-to(pc) {
            > ul {
                padding-left: 8px;
                li {
                    display: flex;
                    &:before {
                        content: '';
                        width: 5px;
                        height: 5px;
                        margin: 8px 5px 0 0;
                        background: rgba(13, 18, 64, 0.6);
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    .in-cnts {
        padding: 24px 0;
        border-bottom: 1px solid rgba(13, 18, 64, 0.05);
        color: rgba(13, 18, 64, 0.6);
        line-height: 22px;
        h5 {
            font-size: 16px;
        }
        > p {
            margin-top: 16px;
            em {
                color: $color-point;
                font-weight: bold;
            }
        }
        input, .fm-box {
            margin-top: 8px;
        }
        select {
            width: 100%;
            margin-top: 8px;
            color: rgba(13, 18, 64, 0.4);
            font-size: 16px;
        }
        textarea {
            margin-top: 8px;
            height: 196px;
            color: $color-def;
        }
        .int-form {
            input {
                margin-top: 0;
                color: $color-point;
            }
        }
        &:first-child {
            padding-top: 40px;
        }
        @include respond-to(pc) {
            line-height: 22px;
            &.no-line {
                padding: 32px 0 0;
                border: none;
                > p {
                    margin-top: 8px;
                    &.desc {
                        margin-top: 30px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .in-cnts2 {
        margin-top: 20px;
        color: rgba(13, 18, 64, 0.6);
    }
    .sug-type {
        margin-top: 30px;
        label {
            font: {
                weight: bold;
                size: 16px;
            }
        }
        .sel-w {
            position: relative;
            margin-top: 6px;
            button {
                display: block;
                position: relative;
                width: 100%;
                height: 60px;
                padding: 0 24px 0 8px;
                border-radius: 2px;
                background-color: rgba(85, 57, 255, 0.03);
                color: rgba(13, 18, 64, 0.4);
                text-align: left;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 27px;
                    right: 11px;
                    width: 10px;
                    height: 6px;
                    background: url('../images/icon_arrow.svg') no-repeat 0 0;
                }
            }
            .opt-li {
                display: none;
                margin-top: 5px;
                padding: 16px 16px 6px;
                border: 1px solid $color-point;
                border-radius: 2px;
                font-size: 14px;
                p {
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 12px;
                }
                ul {
                    padding-top: 5px;
                    li {
                        padding: 10px 0;
                        &.selected {
                            color: $color-point;
                            a {
                                color: $color-point;
                            }
                        }
                    }
                }
            }
            &.selected {
                button {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
                .opt-li {
                    display: block;
                }
            }
        }
        @include respond-to(pc) {
            display: flex;
            label {
                width: 138px;
            }
            .sel-w {
                flex: 1;
                margin-top: 0;
            }
        }
    }
}
.member-wrap {
    padding-bottom: 80px;
    .in-info {
        max-width: 800px;
        margin: 0 auto;
        padding: 0 24px;
        .ass-form {
            > a {
                display: block;
                margin-top: 40px;
                padding: 24px;
                background: $color-point2;
                font-size: 14px;
                text-align: center;
                line-height: 22px;
                &:before {
                    content: '';
                    display: block;
                    position: relative;
                    left: calc(50% - 70px);
                    width: 200px;
                    height: 160px;
                    background: url('../images/ass_img.png') no-repeat 0 0;
                    background-size: contain;
                }
                .txt {
                    margin-top: 16px;
                }
            }
            @include respond-to(pc) {
                > a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 24px;
                    text-align: left;
                    &:before {
                        position: static;
                    }
                    .txt {
                        max-width: 340px;
                        margin-top: 0;
                        margin-left: 68px;
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
        .inbox {
            position: relative;
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid rgba(13, 18, 64, 0.05);
            &:first-child {
                margin-top: 40px;
                padding-top: 0;
                border: none;
            }
            h2 {
                font-size: 16px;
            }
            .btn-more {
                position: absolute;
                top: 24px;
                right: 0;
                color: $color-point;
                &:after {
                    content: '>';
                    margin-left: 5px;
                }
            }
            @include respond-to(pc) {
                margin-top: 32px;
                padding-top: 32px;
                &:first-child {
                    margin-top: 64px;
                }
                .btn-more {
                    top: 32px;
                }
            }
        }
        .ass-form + .inbox {
            margin-top: 0;
            border-top: none;
        }
    }
}
.data-status {
    margin-top: 20px;
    padding: 20px 22px;
    border-radius: 4px;
    background-color: rgba(13, 18, 64, 0.02);
    h2 {
        font: {
            size: 16px;
            weight: normal;
        }
        em {
            font-weight: bold;
        }
    }
    ul {
        li {
            position: relative;
            margin-top: 25px;
            padding-left: 64px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                width: 54px;
                height: 54px;
                background: #fff url('../images/icon_datast.svg') no-repeat 14px 50%;
                border-radius: 50%;
            }
            p {
                padding-top: 5px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
            }
            > em {
                display: block;
                margin-top: 3px;
                font: {
                    size: 18px;
                    weight: 500;
                }
                span {
                    padding-left: 5px;
                    font: {
                        size: 16px;
                        weight: normal;
                    }
                }
            }
            &.t-like {
                &:before {
                    background-position: -76px 50%;
                }
            }
            &.t-long {
                &:before {
                    background-position: -121px 50%;
                }
            }
            &.t-many {
                &:before {
                    background-position: -168px 50%;
                }
            }
            &.t-recu {
                &:before {
                    background-position: -32px 50%;
                }
            }
        }
    }
    @include respond-to(pc) {
        padding: 30px 40px;
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
                box-sizing: border-box;
            }
        }
    }
}
.graph-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding: 24px 0;
    border-radius: 4px;
    border: solid 1px rgba(13, 18, 64, 0.05);
    background-color: rgba(13, 18, 64, 0.02);
    ul {
        padding-top: 15px;
        font-size: 16px;
        li {
            display: flex;
            align-items: center;
            margin-top: 8px;
            em {
                font-weight: bold;
            }
            &:before {
                content: '';
                min-width: 8px;
                height: 8px;
                margin-right: 8px;
                background: #5539ff;
                border-radius: 50%;
            }
            &.gt2 {
                &:before {
                    background: #96ffc6;
                }
            }
            &.gt3 {
                &:before {
                    background: #ffb494;
                }
            }
        }
    }
    @include respond-to(pc) {
        flex-direction: row;
        justify-content: center;
        padding: 32px 0;
        .graph {
            img {
                width: 200px;
                height: 200px;
            }
        }
        ul {
            margin-left: 80px;
            padding-top: 0;
            li {
                margin-top: 16px;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
.new-member {
    .member-box {
        margin-top: 16px;
        border: solid 1px rgba(85, 57, 255, 0.05);
        background-color: rgba(85, 57, 255, 0.03);
        border-radius: 4px;
        .profile {
            display: flex;
            align-items: center;
            padding: 24px;
            .photo {
                min-width: 64px;
                height: 64px;
                margin-right: 16px;
                background: {
                    size: cover;
                    position: 50% 50%;
                    repeat: no-repeat;
                }
                border-radius: 50%;
            }
            .info {
                flex: 1;
                .name {
                    //display: flex;
                    h3 {
                        font-size: 14px;
                        box-sizing: border-box;
                    }
                    span {
                        display: block;
                        margin-top: 4px;
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 14px;
                    }
                }
                p {
                    margin-top: 4px;
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 14px;
                }
            }
        }
        .use-pjt {
            padding: 16px 24px 24px;
            border-top: 1px solid rgba(85, 57, 255, 0.05);
            h4, p {
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
                line-height: 22px;
            }
            .mb-btn {
                display: flex;
                margin-top: 16px;
                a, button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: auto;
                    height: 52px;
                    margin-left: 8px;
                    border-radius: 2px;
                    background-color: rgba(85, 57, 255, 0.05);
                    color: $color-point;
                    text-align: center;
                    white-space: nowrap;
                    &:first-child {
                        margin-left: 0;
                    }
                    &.btn-like {
                        min-width: 52px;
                        max-width: 52px;
                        background: {
                            image: url('../images/icon_heart_no.svg');
                            repeat: no-repeat;
                            position: 50% 50%;
                        }
                        &.act {
                            background-image: url('../images/icon_heart.svg');
                        }
                        span {
                            @include hiddenText;
                        }
                    }
                    &.actv {
                        background: $color-point;
                        color: #fff;
                        > span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @include respond-to(pc) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .member-box {
            width: calc(50% - 12px);
            margin-top: 24px;
            .use-pjt {
                p {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .mb-btn {
                    a, button {
                        &.actv {
                            span {
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.skill-wrap {
    margin-top: 16px;
    padding: 24px;
    border-radius: 4px;
    border: solid 1px rgba(13, 18, 64, 0.05);
    background-color: rgba(13, 18, 64, 0.02);
    .in-tab {
        border-bottom: 1px solid rgba(13, 18, 64, 0.05);
        ul {
            display: flex;
            li {
                margin-right: 40px;
                a {
                    display: block;
                    padding-bottom: 8px;
                    color: rgba(13, 18, 64, 0.4);
                    white-space: nowrap;
                }
                &.selected {
                    margin-bottom: -1px;
                    border-bottom: 1px solid $color-def;
                    a {
                        color: $color-def;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .skill-box {
        display: flex;
        flex-wrap: wrap;
        padding-top: 16px;
        > span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            margin: 8px 8px 0 0;
            padding: 0 16px;
            border-radius: 2px;
            border: solid 1px rgba(13, 18, 64, 0.05);
            background-color: rgba(13, 18, 64, 0.02);
            color: rgba(13, 18, 64, 0.6);
            white-space: nowrap;
        }
        .in-more {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 42px;
            margin: 8px 8px 0 0;
            padding: 0 36px 0 16px;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.05);
            color: $color-point;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                background: $color-point;
            }
            &:before {
                top: calc(50% - 1px);
                right: 19px;
                width: 11px;
                height: 1px;
            }
            &:after {
                top: calc(50% - 6px);
                right: 24px;
                width: 1px;
                height: 11px;
            }
        }
    }
    .no-data {
        p {
            margin-top: 24px;
            color: rgba(13, 18, 64, 0.4);
            font-weight: bold;
            line-height: 26px;
        }
    }
    @include respond-to(pc) {
        .skill-box {
            > span, .in-more {
                height: 38px;
                font-size: 14px;
            }
            > span {
                padding: 0 24px;
            }
        }
        .no-data {
            p {
                br {
                    display: none;
                }
            }
        }
    }
}
.no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    margin-top: 40px;
    text-align: center;
    &:before {
        content: '';
        width: 200px;
        height: 200px;
        background: url('../images/no_result.png') no-repeat 50% 50%;
        background-size: cover;
    }
    p {
        margin-top: 24px;
        color: rgba(13, 18, 64, 0.4);
        line-height: 22px;
    }
    .btn-reset {
        width: 100%;
        margin-top: 24px;
        color: $color-point;
        font-weight: 16px;
        text: {
            align: center;
            decoration: underline;
        }
    }
    @include respond-to(pc) {
        p {
            font-size: 22px;
            line-height: 30px;
        }
    }
}
.invite-w {
    padding: 40px 24px;
    .inner {
        max-width: 608px;
        margin: 0 auto;
        padding: 32px 24px 24px;
        border: 1px solid rgba(111, 114, 138, 0.05);
        box-sizing: border-box;
        > p {
            padding-bottom: 8px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 22px;
            text-align: center;
            line-height: 34px;
        }
        .code-copy {
            margin-top: 16px;
            padding: 16px 24px 24px;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.03);
            input {
                height: auto;
                background: none;
                color: rgba(13, 18, 64, 0.6);
                text-align: center;
            }
            button {
                width: 100%;
                height: 40px;
                margin-top: 16px;
                background: $color-point;
                border-radius: 2px;
                color: #fff;
            }
        }
        .inv-end {
            margin-top: 16px;
            padding: 16px 24px;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.03);
            text-align: center;
            p {
                color: rgba(13, 18, 64, 0.6);
                line-height: 26px;
                em {
                    font-weight: bold;
                }
            }
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                margin-top: 16px;
                border-radius: 2px;
                background-color: rgba(13, 18, 64, 0.05);
                color: rgba(13, 18, 64, 0.4);
            }
        }
    }
    @include respond-to(pc) {
        padding-top: 64px;
        .inner {
            > p {
                br {
                    display: none;
                }
            }
            .code-copy {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                input {
                    padding: 0;
                    text-align: left;
                }
                button {
                    min-width: 80px;
                    width: 80px;
                    margin: 0;
                }
            }
            .inv-end {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                > div {
                    min-width: 80px;
                    width: 80px;
                    margin: 0;
                }
            }
        }
    }
}
// pw reset
.pw-reset {
    max-width: 400px;
    margin: 0 auto;
    > p {
        margin-top: 25px;
        font-size: 16px;
        line-height: 26px; 
        em {
            font-weight: bold;
        }
    }
    .desc-link {
        margin-top: 32px;
        font-size: 14px;
        p {
            color: rgba(13, 18, 64, 0.6);
            a {
                color: $color-point;
            }
        }
    }
    .email-input {
        margin-top: 28px;
        text-align: left;
        label {
            display: block;
            padding-bottom: 8px;
        }
        > div {
            margin-top: 24px;
            > span {
                display: block;
                position: relative;
                button {
                    top: 20px;
                }
            }
            &:first-child {
                margin-top: 0;
            }
        }
    }
    .def-btn {
        display: block;
        width: 100%;
        height: 48px;
        margin-top: 32px;
        background: $color-point;
        border-radius: 2px;
        color: #fff;
        line-height: 48px;
    }
}
// 동료 추가
.mem-add {
    overflow: auto;
    //max-width: 548px;
    height: 100vh;
    padding: 130px 24px;
    box-sizing: border-box;
    .viewport {
        max-width: 548px;
        margin: 0 auto;
        h2 {
            display: block;
            font: {
                size: 28px;
                weight: normal;
            }
            text-align: center;
            line-height: 42px;
        }
        > p {
            margin-top: 20px;
            color: rgba(13, 18, 64, 0.6);
            line-height: 22px;
            > em { 
                color: $color-point;
            }
            a {
                color: $color-point;
                text-decoration: underline;
            }
            &.em {
                color: $color-def;
            }
        }
        .pjt-chklist {
            padding-top: 30px;
            ul {
                li {
                    margin-top: 20px;
                    i {
                        position: relative;
                        padding-left: 15px;
                        color: $color-point;
                        font-size: 14px;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 6px;
                            left: 0;
                            width: 7px;
                            height: 4px;
                            margin-right: 8px;
                            border-radius: 10px;
                            background: #5539ff;
                            transform: rotate(-45deg);
                        }
                    }
                    label {
                        display: block;
                        position: relative;
                        margin-top: 3px;
                        padding: 0 30px 0 0;
                        line-height: 26px;
                        &:before {
                            left: auto;
                            right: 0;
                            width: 20px;
                            height: 20px;
                            border-width: 2px;
                            border-color: $color-point;
                        }
                        &:after {
                            left: auto;
                            top: 9px;
                            right: 5px;
                        }
                    }
                    p {
                        margin-top: 3px;
                        line-height: 26px;
                    }
                    p.desc {
                        color: rgba($color: #0D1240, $alpha: 0.6);
                        font-size: 14px;
                        line-height: 22px;
                    }
                    > div {
                        display: none;
                        padding-top: 10px;
                        padding-left: 20px;
                        > label {
                            display: block;
                            margin-top: 10px;
                            padding-bottom: 6px;
                            font-size: 14px;
                        }
                    }
                    input:checked ~ div {
                        display: block;
                    }
                    &.my-info {
                        padding: 20px 0;
                        border: {
                            width: 1px 0;
                            style: solid;
                            color: rgba(13, 18, 64, 0.1);
                        }
                        > div {
                            display: block;
                        }
                    }
                }
            }
        }
        .btn {
            margin-top: 60px;
            &.mtype2 {
                margin-top: 30px;
            }
        }
        .num-sect {
            margin-top: 50px;
            padding: 20px;
            background: rgba(13, 18, 64, 0.02);
            h3 {
                font-weight: normal;
            }
            ul {
                li {
                    position: relative;
                    margin-top: 28px;
                    padding-left: 64px;
                    > span {
                        display: block;
                        margin-top: 4px;
                        color: $color-point;
                        white-space: nowrap;
                        em {
                            margin-right: 3px;
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -5px;
                        left: 0;
                        width: 54px;
                        height: 54px;
                        background: url('../images/icon_smem.svg') no-repeat 0 0;
                    }
                    &.nv2 {
                        &:before {
                            background-position: 0 -56px;
                        }
                    }
                    &.nv3 {
                        &:before {
                            background-position: 0 -112px;
                        }
                    }
                    &.nv4 {
                        &:before {
                            background-position: 0 -168px;
                        }
                    }
                    &.nv5 {
                        &:before {
                            background-position: 0 100%;
                        }
                    }
                }
            }
        }
    }
    @include respond-to(pc) {
        .viewport {
            h2 {
                font-size: 22px;
                br {
                    display: none;
                }
            }
            .btn {
                > a, > button {
                    max-width: 400px;
                    margin: 0 auto !important;
                }
            }
            .num-sect {
                padding: 38px;
                ul {
                    li {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        &:before {
                            position: static;
                            margin-right: 10px;
                        }
                        > span {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}