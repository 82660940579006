// Define breakpoints
$breakpoints: (
	xMo: 560px,
	pc2: 768px,
	pc: 786px,
	pcMain: 1000px,
	pcSMax: 1366px,
	pcMax: 1400px,

);
// Create mixin
@mixin respond-to($breakpoint) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}

/* var */
$color-def: #0d1240;
$color-point: #5539ff;
$color-point2: #E0FE52;
$color-err: #ff4e16;
$font-def: 'SFUIDisplay', 'NotoSansCJKkr','Roboto','Droid Sans','Malgun Gothic','Helvetica','Apple-Gothic','애플고딕','Tahoma',dotum,'돋움',gulim,'굴림', sans-serif;
$font-landing: 'Pretendard','SFUIDisplay', 'NotoSansCJKkr','Roboto','Droid Sans','Malgun Gothic','Helvetica','Apple-Gothic','애플고딕','Tahoma',dotum,'돋움',gulim,'굴림', sans-serif;

/* mixin */
//base
@mixin hiddenText {
	overflow: hidden;
    display: inline-block;
	font-size: 1px;
	line-height: 0;
	text: {
		indent: -9999px;
		align: left;
	}
}
@mixin clearfix {
	&:after {
		display: block;
		content:'';
		clear: both;
	}
}
//contents
@mixin cateBox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    padding: 8px 16px;
    border-radius: 2px;
    border: solid 1px rgba(13, 18, 64, 0.05);
    background-color: rgba(13, 18, 64, 0.02);
    color: rgba(13, 18, 64, 0.6);
    white-space: nowrap;
    box-sizing: border-box;
}
@mixin tit {
	font-size: 32px;
	text-align: center;
	@include respond-to(pc) {
		font-size: 44px;
	}
}
@mixin sTit {
	display: flex;
	align-items: center;
	margin-top: 40px;
	padding-bottom: 13px;
	border-bottom: 1px solid $color-point;
	color: $color-point;
	font: {
		size: 16px;
		weight: normal;
	}
	&:before {
		content: '';
		width: 20px;
		height: 10px;
		margin-right: 8px;
		border-radius: 10px;
		background: $color-point;
		transform: rotate(-45deg);
	}
}
@mixin scrollN {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none; /* Chrome, Safari, Opera*/
	}
}