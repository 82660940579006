@charset "utf-8";

@import "layout.scss";
@import "common.scss";
@import "index.scss";
@import "member.scss";
@import "landing.scss";

//common
.btn {
    display: flex;
    margin-top: 40px;
    box-sizing: border-box;
    .inner {
        display: flex;
        width: 100%;
    }
    a, button {
        display: block;
        width: 100%;
        height: 52px;
        margin-left: 8px;
        padding: 0 25px;
        color: $color-point;
        font-size: 16px;
        text-align: center;
        line-height: 52px;
        box-sizing: border-box;
        white-space: nowrap;
        border-radius: 2px;
        &.actv {
            background: $color-point !important;
            color: #fff !important;
            &.save {
               // min-width: 70%;
            }
        }
        &.sub {
            margin-right: 8px;
            background-color: rgba(85, 57, 255, 0.05);
            color: $color-point;
        }
        &.prev {
            max-width: 88px;
            margin-right: 8px;
            background-color: rgba(85, 57, 255, 0.05);
            color: $color-point;
        }
        &.cel {
            max-width: 80px;
        }
        &.disb,
        &:disabled {
            background-color: rgba(13, 18, 64, 0.05);
            color: rgba(13, 18, 64, 0.4);
        }
        &.wtype {
            background: none;
            color: $color-point;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        @include respond-to(pc) {
            height: 48px;
            font-size: 14px;
            &.cel {
                max-width: 190px;
            }
            &.actv {
                &.save {
                    max-width: 436px;
                }
            }
        }
    }
    /* &.fx-type {
        display: flex;
        a,  button {
            width: auto;
            flex: auto;
            &:last-child {
                //flex: 1;
                //margin-left: 8px;
            }
        }
    } */
    &.type2 {
        display: flex;
        > a, > button {
            height: 48px;
            //margin-left: 8px;
            border-radius: 2px;
            line-height: 48px;
            &.cancel {
                width: 87px;
                background-color: rgba(85, 57, 255, 0.05);
                color: $color-point;
            }
        }
    }
    &.flex-col {
        flex-direction: column;
        margin-top: 32px;
        a, button {
            display: block;
            margin-top: 8px;
        }
    }
}
.price-w {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding-right: 24px;
    background-color: rgba(85,57,255,0.03);
    input {
        margin-top: 0 !important;
        background: none !important;
        text-align: right;
        &::placeholder {
            text-align: left;
        }
    }
    span {
        color: $color-def;
        white-space: nowrap;
    }
}
.dimd-layer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 16px;
    background-color: rgba(13, 18, 64, 0.6);
    box-sizing: border-box;
    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 52px;
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 13px);
            left: 50%;
            width: 2px;
            height: 25px;
            background-color: rgba(13, 18, 64, 0.4);
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        span {
            @include hiddenText;
        }
    }
    .btn {
        a, button {
            //margin: 0;
        }
        &.type2 {
            a, button {
                &:last-child {
                    //margin-left: 8px;
                }
            }
        }
    }
    .ly-box {
        position: relative;
        width: 100%;
        margin: auto;
        background: #f9fdff;
        .inner {
            max-width: 320px;
            margin: 0 auto;
            > h3 {
                font: {
                    size: 22px;
                    weight: normal;
                }
                line-height: 34px;
            }
            > strong {
                display: block;
                margin-bottom: 20px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                text-align: left;
            }
            > p {
                font-size: 16px;
                color: rgba(13, 18, 64, 0.6);
                text-align: left;
                line-height: 26px;
                &.altype2 {
                    text-align: center;
                }
            }
            > h3 + p,
            p ~ p {
                margin-top: 24px;
            }
            .s-desc {
                margin-top: 32px;
                padding: 16px;
                background-color: rgba(13, 18, 64, 0.02);
                color: rgba(13, 18, 64, 0.4);
                font-size: 14px;
                line-height: 22px;
            }
            .sp-end + .s-desc {
                margin-top: 16px;
            }
            .ft-desc {
                color: rgba(13, 18, 64, 0.6);
                font-size: 12px;
                line-height: 1.5;
                &:before {
                    content: '*';
                }
            }
        }
        > section {
            max-width: 320px;
            margin: 0 auto;
            .hgroup {
                padding-bottom: 24px;
                border-bottom: 1px solid rgba(13, 18, 64, 0.05);
                h1 {
                    font: {
                        size: 22px;
                        weight: normal;
                    }
                    text-align: center;
                }
                p {
                    margin-top: 8px;
                    color: rgba(13, 18, 64, 0.6);
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            @include respond-to(pc) {
                padding: 30px 0 40px;
            }
        }
        &.alert-box {
            max-width: 640px;
            padding: 40px 24px 24px !important;
            font-size: 22px;
            text-align: center;
            line-height: 34px;
            box-sizing: border-box;
            > p {
                color: rgba(13, 18, 64, 0.6);
                text-align: left;
            }
            .btn {
                max-width: 320px;
                margin: {
                    left: auto;
                    right: auto;
                }
            }
            p.link {
                margin-top: 10px;
                font-size: 14px;
                text-align: center;
                a {
                    text-decoration: underline;
                }
            }
            @include respond-to(pc) {
                padding: 64px 80px !important;
            }
        }
        &.smtype {
            max-width: 480px;
            padding: 40px 24px 24px;
            box-sizing: border-box;
            > p {
                font-size: 16px;
                line-height: 26px;
            }
        }
        .h-pay {
            text-align: left;
            > label {
                font: {
                    size: 16px;
                    weight: bold;
                }
            }
            > div {
                display: flex;
                align-items: center;
                background-color: rgba(85, 57, 255, 0.03);
                input {
                    background: none;
                }
                span {
                    padding-right: 20px;
                    font-size: 16px;
                }
            }
        }
        .alrtype2 {
            max-width: 320px;
            margin: 0 auto;
            > h3 {
                font-size: 22px;
                padding-bottom: 24px;
                border-bottom: 1px solid rgba(13, 18, 64, 0.05);
                font-weight: normal;
            }
            > p {
                margin-top: 24px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                text-align: left;
                line-height: 26px;
            }
            @include respond-to(pc) {
                margin-top: -30px;
            }
        }
    }
    .pjt-road {
        h1 {
            font: {
                size: 22px;
                weight: normal;
            }
            text-align: center;
        }
        > p {
            margin-top: 8px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 16px;
            text-align: center;
            line-height: 26px;
        }
        .cnts {
            margin-top: 24px;
            border-top: 1px solid rgba(13, 18, 64, 0.05);
            .pjt-list {
                padding-top: 16px;
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 8px;
                    padding: 16px;
                    border-radius: 4px;
                    background-color: rgba(85, 57, 255, 0.03);
                    font-size: 16px;
                    line-height: 22px;
                    em {
                        overflow: hidden;
                        //width: calc(100% - 100px);
                        text-align: left;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    span {
                        color: rgba(13, 18, 64, 0.6);
                        text-align: right;
                    }
                }
            }
            .no-data {
                margin-top: 24px;
                padding: 16px;
                border-radius: 4px;
                background-color: rgba(13, 18, 64, 0.02);
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                line-height: 26px;
                &:before {
                    display: none;
                }
            }
        }
        @include respond-to(pc) {
            overflow: auto;
            width: calc(100% + 48px);
            max-height: 420px;
            margin: {
                top: 24px;
                left: -24px;
            }
            padding: 0 80px;
            box-sizing: border-box;
            .cnts {
                .pjt-list {
                    li {
                        em {
                            font: {
                                weight: 500;
                                size: 14px;
                            }
                        }
                        span {
                            font-size: 14px;
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .recu-chk-wrap {
        max-width: 320px;
        margin: 0 auto;
        h1 {
            font: {
                size: 22px;
                weight: normal;
            }
            text-align: center;
        }
        > p {
            margin-top: 24px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 16px;
            //text-align: center;
            line-height: 26px;
        }
        .recu-chk {
            > ul {
                > li {
                    margin-top: 24px;
                    label {
                        display: block;
                        font: {
                            size: 16px;
                            weight: bold;
                        }
                    }
                    select,
                    > input[type='text'],
                    textarea {
                        width: 100%;
                        margin-top: 8px;
                        font-size: 16px;
                    }
                    .inpt-w {
                        display: flex;
                        select {
                            width: 124px;
                            margin-right: 10px;
                        }
                    }
                    .add-link {
                        margin-top: 20px;
                        text-align: right;
                        a {
                            color: $color-point;
                            font-size: 16px;
                            text-decoration: underline;
                        }
                    }
                    .sel-form {
                        margin-top: 8px;
                    }
                }
            }
            > p {
                margin-top: 32px;
                font-size: 16px;
                color: rgba(13, 18, 64, 0.6);
                line-height: 26px;
                em {
                    color: $color-point;
                    font-weight: bold;
                }
            }
        }
        @include respond-to(pc) {
            padding: 30px 0 40px;
        }
    }
    .cho-list {
        padding-top: 16px;
        text-align: left;
        li {
            position: relative;
            margin-top: 8px;
            label {
                display: block;
                padding: 16px;
                border-radius: 4px;
                background-color: rgba(85, 57, 255, 0.03);
                color: $color-def;
                font-size: 16px;
                line-height: 26px;
                &:before,
                &:after {
                    display: none !important;
                }
            }
            input:checked + label {
                padding: 15px;
                border: 1px solid $color-point;
                color: $color-point;
            }
            input[type="text"] {
                position: absolute;
                top: 0;
                left: 50px;
                width: calc(100% - 50px);
                background: none;
            }
            &.etc {
                label {
                    color: rgba(13, 18, 64, 0.4);
                }
            }
        }
        > p {
            margin-top: 24px;
            font-size: 16px;
            a {
                color: $color-point;
                text-decoration: underline;
            }
        }
    }
    .sp-end {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        padding-top: 16px;
        font-size: 14px;
        border-top: 1px solid rgba(13, 18, 64, 0.05);
        label, .tit {
            white-space: nowrap;
            color: $color-def;
            font: {
                size: 14px;
                weight: bold;
            }
            span {
                padding-left: 5px;
                font-size: 12px;
                color: rgba(13, 18, 64, 0.6);
            }
        }
        > input {
            max-width: 87px;
            height: auto;
            margin-left: auto;
            padding: 0 8px;
            background: none;
            color: $color-point;
            text-align: center;
            text-decoration: underline;
            border-radius: 4px;
            background-color: rgba(85, 57, 255, 0.03);
            box-sizing: border-box;
        }
        ul {
            min-width: 100%;
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 20px;
                color: rgba(13, 18, 64, 0.6);
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    margin-left: auto;
                    input {
                        max-width: 90px;
                        height: 26px;
                        padding: 0 8px;
                        text-align: right;
                        text-decoration: underline;
                        color: $color-point;
                        line-height: 26px;
                        border-radius: 4px;
                        background-color: rgba(85, 57, 255, 0.03);
                        box-sizing: border-box;
                    }
                    span {
                        margin-left: 8px;
                        font-size: 16px;
                        color: rgba(13, 18, 64, 0.6);
                        text-decoration: underline;
                        line-height: 26px;
                    }
                    p {
                        position: absolute;
                        z-index: 10;
                        top: 25px;
                        right: -15px;
                        padding: 8px;
                        color: #ff4e16;
                        font-size: 12px;
                        white-space: nowrap;
                        border: 1px solid rgba(13, 18, 64, 0.05);
                        background: #fff;
                        line-height: 18px;
                    }
                    &.prg-work {
                        margin-top: 3px;
                        text-align: left;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                > i {
                    white-space: nowrap;
                    color: $color-def;
                    font-weight: bold;
                }
                > span {
                    color: rgba(13, 18, 64, 0.6);
                }
                textarea {
                    min-width: 100%;
                    height: 80px;
                    margin-top: 3px;
                    padding: 16px;
                    font-size: 14px;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
            &.type2 {
                li {
                    margin-top: 0;
                }
            }
        }
    }
    .sp-end2 {
        margin-top: 24px;
        padding-top: 16px;
        font-size: 14px;
        border-top: 1px solid rgba(13, 18, 64, 0.05);
        .tit {
            display: block;
            white-space: nowrap;
            color: $color-def;
            text-align: left;
            font: {
                size: 14px;
                weight: bold;
            }
            span {
                padding-left: 5px;
                font-size: 12px;
                color: rgba(13, 18, 64, 0.6);
            }
        }
        ul {
            min-width: 100%;
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 20px;
                color: rgba(13, 18, 64, 0.6);
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    margin-left: auto;
                    input {
                        max-width: 90px;
                        height: 26px;
                        padding: 0 8px;
                        text-align: right;
                        text-decoration: underline;
                        color: $color-point;
                        line-height: 26px;
                        border-radius: 4px;
                        background-color: rgba(85, 57, 255, 0.03);
                        box-sizing: border-box;
                    }
                    span {
                        margin-left: 8px;
                        font-size: 16px;
                        color: rgba(13, 18, 64, 0.6);
                        text-decoration: underline;
                        line-height: 26px;
                    }
                    p {
                        position: absolute;
                        z-index: 10;
                        top: 25px;
                        right: -15px;
                        padding: 8px;
                        color: #ff4e16;
                        font-size: 12px;
                        white-space: nowrap;
                        border: 1px solid rgba(13, 18, 64, 0.05);
                        background: #fff;
                        line-height: 18px;
                    }
                    &.prg-work {
                        margin-top: 3px;
                        text-align: left;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                > i {
                    white-space: nowrap;
                    color: $color-def;
                    font-weight: bold;
                }
                > span {
                    color: rgba(13, 18, 64, 0.6);
                }
                textarea {
                    min-width: 100%;
                    height: 80px;
                    margin-top: 3px;
                    padding: 16px;
                    font-size: 14px;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
            &.type2 {
                li {
                    margin-top: 0;
                }
            }
        }
        .price-w {
            width: 100%;
            margin-top: 0;
            background: none;
            input {
                background: rgba(85, 57, 255, 0.03) !important;
                color: $color-point;
                &::placeholder {
                    text-align: right;
                }
            }
            span {
                padding-left: 6px;
            }
        }
        .week-pay {
            display: flex;
            width: 100%;
            font-weight: bold;
            em {
                margin-left: auto;
                font-size: 16px;
            }
        }
        .pay-result {
            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 23px;
                margin-top: 6px;
                color: rgba(13, 18, 64, 0.6);
                > em {
                    color: $color-def;
                    font-weight: bold;
                    span {
                        color: rgba(13, 18, 64, 0.6);
                        font: {
                            size: 12px;
                            weight: normal;
                        }
                    }
                }
                > strong {
                    color: $color-def;
                    font-size: 16px;
                }
                > i {
                    display: flex;
                    align-items: center;
                    &:before {
                        content: '';
                        min-width: 14px;
                        height: 18px;
                        margin-right: 5px;
                        background: url('../images/icon_rep.svg') no-repeat 0 0;
                    }
                }
                &.app-price {
                    margin-top: 15px;
                    &.matching {
                        > em, > strong {
                            color: $color-point;
                        }
                    }
                }
                &.add-mius {
                    color: rgba(85, 57, 255, 0.8);
                }
            }
            .desc-match {
                display: flex;
                align-items: center;
                margin-top: 2px;
                color: rgba(13, 18, 64, 0.6);
                &:before {
                    content: '';
                    min-width: 14px;
                    height: 18px;
                    margin-right: 5px;
                    background: url('../images/icon_rep.svg') no-repeat 0 0;
                }
            }
        }
    }
    .desc-box {
        margin-top: 24px;
        padding: 16px;
        background-color: rgba(13, 18, 64, 0.02);
        border-radius: 4px;
        color: rgba(13, 18, 64, 0.4);
        font-size: 14px;
        text-align: left;
        line-height: 22px;
        ul {
            li {
                display: flex;
                &:before {
                    content: '';
                    min-width: 4px;
                    height: 4px;
                    margin: 8px 5px 0 0;
                    background-color: rgba(13, 18, 64, 0.4);
                    border-radius: 50%;
                }
            }
        }
    }
    .g-price {
        margin: 24px 0 0;
        padding: 16px;
        border-radius: 4px;
        background-color: rgba(85, 57, 255, 0.03);
        p {
            color: $color-point;
            font: {
                size: 16px;
                weight: bold;
            }
            span {
                padding-left: 20px;
            }
        }
    }
    .g-price + p {
        margin-top: 24px;
    }
    .scenario-w {
        h1 {
            font: {
                size: 22px;
                weight: normal;
            }
            text-align: center;
        }
        ul {
            margin-top: 32px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 16px;
            line-height: 26px;
            li {
                display: flex;
                &:before {
                    content: '';
                    min-width: 5px;
                    height: 5px;
                    margin: 9px 10px 0 0;
                    background: rgba(13, 18, 64, 0.6);
                    border-radius: 50%;
                }
            }
        }
    }
    .hope-date {
        margin-top: 16px;
        text-align: left;
        .date-w {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 16px;
            em {
                font-weight: bold;
            }
            span {
                color: rgba(13, 18, 64, 0.6);
                text-decoration: underline;
            }
        }
        > p {
            margin-top: 24px;
            padding: 10px 16px;
            border-radius: 4px;
            background-color: rgba(13, 18, 64, 0.02);
            color: rgba(13, 18, 64, 0.4);
            font-size: 14px;
        }
    }
    .ch-pw {
        text-align: left;
        line-height: 100%;
        > div {
            margin-top: 25px;
            label {
                display: block;
                padding-bottom: 8px;
                font-size: 14px;
            }
            > span {
                display: block;
                position: relative;
                button {
                    top: 18px;
                }
            }
        }
    }
    .cho-date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        > label {
            margin-right: 18px;
            font: {
                size: 14px;
                weight: bold;
            }
        }
        .d-w {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 14px;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.03);
            input {
                width: 135px;
                height: auto;
                padding: 0;
                font-size: 16px;
                background: none;
            }
            button.cal {
                width: 22px;
                height: 22px;
                background: url('../images/icon_calendar.svg') no-repeat 100% 0;
                span {
                    @include hiddenText;
                }
            }
        }
    }
}
//join
.join-sign {
    .visual {
        display: none;
    }
    .join-w,
    .join-end {
        padding: 130px 24px;
        box-sizing: border-box;
        .inner {
            max-width: 400px;
            margin: 0 auto;
            text-align: center;
            .link {
                display: inline-block;
                margin-top: 16px;
                color: $color-point;
                text-decoration: underline;
            }
        }
    }
    .join-w {
        .inner {
            h2 {
                font: {
                    size: 32px;
                }
                text-align: center;
                line-height: 48px;
                &:after {
                    content: '';
                    display: block;
                    position: relative;
                    height: 27px;
                    margin: -22px auto 0;
                    background: url('../images/line_img.png') no-repeat 50% 0;
                    background-size: auto 100%;
                    transform: rotate(5deg);
                    z-index: -1;
                }
                &.noline {
                    &:after {
                        display: none;
                    }
                }
                &.m-join {
                    font-size: 22px;
                    line-height: 34px;
                    &:after {
                        margin-top: -8px;
                        background: {
                            width: 168px;
                            height: 31px;
                            image: url('../images/join_line.png');
                            size: contain;
                        }
                        transform: rotate(3deg);
                    }
                }
                @include respond-to(pc) {
                    &.m-join {
                        font-size: 32px;
                        &:after {
                            width: 190px;
                            height: 39px;
                            margin-right: -50px;
                            transform: rotate(0);
                        }
                    }
                }
            }
            > p {
                margin-top: 24px;
                color: rgba(13, 18, 64, 0.6);
                text-align: center;
                line-height: 26px;
                &.tit {
                    margin-top: 0;
                }
            }
            .chk-w {
                margin-top: 40px;
                padding: 28px;
                background: rgba(85,57,255, 0.05);
                border-radius: 4px;
                > p {
                    margin-top: 24px;
                    text-align: left;
                }
            }
            .type-choice {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 32px;
                > span {
                    padding: 8px 8px;
                    label {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        height: 200px;
                        padding: 0;
                        background-color: rgba(85, 57, 255, 0.03);
                        border-radius: 4px;
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 16px;
                        box-sizing: border-box;
                        &:before {
                            position: static;
                            width: 100px;
                            height: 100px;
                            margin-bottom: 18px;
                            background: url('../images/img_typechoice.png') no-repeat 0 0;
                            background-size: auto 100%;
                            border: none;
                        }
                        &:after {
                            display: none;
                        }
                    }
                    input:checked + label {
                        color: $color-point;
                        border: 1px solid $color-point;
                    }
                    &.ch-memb {
                        label {
                            &:before {
                                background-position: 100% 0;
                            }
                        }
                    }
                }
            }
            .btn-link {
                margin-top: 32px;
                button {
                    color: $color-point;
                    text-decoration: underline;
                }
            }
        }
    }
    .join-end {
        text-align: center;
        strong {
            display: block;
            margin-top: 43px;
            font: {
                size: 32px;
            }
            line-height: 48px;
            span {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: -39px;
                    left: -4px;
                    width: 56px;
                    height: 84px;
                    background: url('../images/line_cir.png') no-repeat 0 0;
                    background-size: 100% auto;
                }
            }
            br {
                //display: none;
            }
            &.type2 {
                font-size: 32px;
                line-height: 48px;
            }
        }
        p {
            margin-top: 23px;
            color: rgba(13, 18, 64, 0.6);
            line-height: 26px;
        }
        .code-int {
            margin-top: 40px;
            p.err {
                margin-top: 5px;
                color: $color-err;
                font-size: 12px;
                text-align: left;
            }
        }
    }
    @include respond-to(pc) {
        display: flex;
        > div {
            width: 50%;
            &.visual {
                overflow: hidden;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                height: 100vh;
                background: $color-point;
                .inner {
                    position: relative;
                    max-width: 588px;
                    margin: 0 auto;
                    padding: 0 30px;
                    img {
                        width: 100%;
                    }
                }
                span {
                    display: block;
                    position: absolute;
                    img {
                        width: 100%;
                    }
                    &.j1 {
                        right: 0;
                        width: 240px;
                    }
                    &.j2 {
                        top: calc(50% - 134px);
                        width: 268px;
                    }
                    &.j3 {
                        right: 0;
                        bottom: 0;
                        width: 305px;
                    }
                }
            }
            &.join-w,
            &.join-end {
                overflow: hidden;
                overflow-y: auto;
                position: relative;
                height: calc(100vh - 70px);
                padding-top: 90px;
                margin-top: 70px;
            }
            &.join-w {
                .inner {
                    h2 {
                        display: inline-block;
                        br {
                            display: none;
                        }
                        &:after {
                            margin-right: -20px;
                            background-position: 100% 0;
                            
                        }
                    }
                    > p {
                        text-align: left;
                    }
                    .type-choice {
                        flex-direction: row;
                        > span {
                            label {
                                width: 192px;
                            }
                        }
                    }
                    > p {
                        line-height: 22px;
                        .only-mo {
                            display: none;
                        }
                    }
                }
            }
            &.join-end {
                .inner {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    strong {
                        br {
                            display: none;
                        }
                        &.type2 {
                            font: {
                                weight: normal;
                                size: 22px;
                            }
                        }
                    }
                    p.invt {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    @include respond-to(pcMax) {
        > div {
            &.join-w {
                &.arrtype {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: calc(50% - 45px);
                        left: 0;
                        width: 163px;
                        height: 90px;
                        background: url('../images/join_arrow.png') no-repeat 0 0;
                        background-size: cover;
                    }
                }
            }
        }
    }
}
.chk-inc {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    padding: 17px 10px;
    background-color: #f9fdff;
    p {
        display: flex;
        align-items: center;
        font-size: 14px;
        &:before {
            content: '';
            width: 16px;
            height: 16px;
            margin: {
                top: -2px;
                right: 8px;
            }
            background: url('../images/icon_chk.svg') no-repeat 0 0;
        }
    }
}
.join-form {
    text-align: left;
    h3 {
        display: flex;
        align-items: center;
        margin-top: 40px;
        padding-bottom: 13px;
        border-bottom: 1px solid $color-point;
        color: $color-point;
        font: {
            size: 16px;
            weight: normal;
        }
        &:before {
            content: '';
            width: 20px;
            height: 10px;
            margin-right: 8px;
            border-radius: 10px;
            background: $color-point;
            transform: rotate(-45deg);
        }
        &.noline {
            padding-bottom: 0;
            border: none;
            font-size: 12px;
            &:before {
                width: 9px;
                height: 4px;
                margin-top: -4px;
                margin-right: 5px;
            }
        }
    }
    > p {
        margin-top: 16px;
        color: rgba(13, 18, 64, 0.6);
        &.tit {
            color: $color-def;
            font-size: 14px;
        }
    }
    .noti-box {
        margin-top: 24px;
        padding: 15px;
        background: #f9fdff;
        text-align: center;
        p {
            color: rgba(13, 18, 64, 0.6);
            font-size: 16px;
        }
    }
    ul.fm-list {
        > li {
            position: relative;
            margin-top: 35px;
            > label,
            .tit {
                display: block;
                padding-bottom: 6px;
                font-size: 14px;
                font-weight: bold;
            }
            > input {
                font-size: 14px;
            }
            > select {
                width: 100%;
                color: rgba(13, 18, 64, 0.4);
                font-size: 14px;
            }
            > textarea {
                height: 112px;
                margin-top: 10px;
                padding: 17px 24px;
                font-size: 14px;
            }
            .fm-inbtn {
                height: 48px;
                padding: 0 24px;
                border: 1px solid $color-point;
                border-radius: 2px;
                color: $color-point;
                line-height: 46px;
            }
            .txt-count {
                position: absolute;
                top: 0;
                right: 0;
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
            }
            .c-phone {
                display: flex;
                position: relative;
                select {
                    min-width: 100px;
                    margin-right: 8px;
                }
                > button {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    width: 105px;
                    height: 40px;
                    background: $color-point;
                    color: #fff;
                    border-radius: 2px;
                    text-align: center;
                    &:disabled {
                        background-color: rgba(13, 18, 64, 0.05);
                        color: rgba(13, 18, 64, 0.4);
                    }
                }
                .link {
                    color: $color-point;
                    text-decoration: underline;
                }
                &.end {
                    select {
                        background: {
                            color: rgba(13, 18, 64, 0.05);
                            //image: url('../images/icon_arrow_g.svg');
                        }
                    }
                    input {
                        background: rgba(13, 18, 64, 0.05);;
                    }
                }
            }
            .e-mail {
                position: relative;
                > button {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    height: 40px;
                    padding: 0 12px;
                    background: $color-point;
                    color: #fff;
                    border-radius: 2px;
                }
                input:disabled + button {
                    background-color: rgba(13, 18, 64, 0.05);
                    color: rgba(13, 18, 64, 0.4);
                }
                .loading {
                    position: absolute;
                    top: 20px;
                    right: 105px;
                    img {
                        width: 17px;
                        height: 17px;
                    }
                }
                .time {
                    position: absolute;
                    top: -20px;
                    right: 0;
                    color: rgba(13, 18, 64, 0.6);
                    font-size: 12px;
                    a, button {
                        margin-left: 3px;
                        color: $color-point;
                        text-decoration: underline;
                        font-size: 12px;
                    }
                }
            }
            .inc-num {
                position: relative;
                margin-top: 8px;
                padding-left: 108px;
                button {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    width: 105px;
                    height: 40px;
                    background-color: rgba(85, 57, 255, 0.05);
                    color: $color-point;
                    text-align: center;
                }
            }
            > p {
                margin-top: 8px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 12px;
                &.err {
                    color: #f00;
                }
                a {
                    color: $color-point;
                    text-decoration: underline;
                }
            }
            .agree {
                margin-top: 8px;
                padding-top: 0;
            }
            .re-inc {
                position: absolute;
                top: 5px;
                right: 0;
                color: $color-point;
                font-size: 12px;
                text-decoration: underline;
            }
            .time {
                position: absolute;
                top: 0;
                right: 0;
                color: rgba(13, 18, 64, 0.6);
                font-size: 12px;
                a, button {
                    margin-left: 3px;
                    color: $color-point;
                    text-decoration: underline;
                    font-size: 12px;
                }
            }
            .tex-info {
                margin-top: 9px;
                padding: 16px 24px;
                border-radius: 2px;
                background-color: rgba(13, 18, 64, 0.02);
                p {
                    color: rgba(13, 18, 64, 0.6);
                    line-height: 22px;
                }
                a {
                    display: inline-block;
                    margin-top: 8px;
                    color: $color-point;
                    text-decoration: underline;
                }
            }
        }
    }
    ul.lifestyle-choice {
        padding-bottom: 25px;
        li {
            position: relative;
            margin-top: 15px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 15px;
                right: 14px;
                width: 117px;
                height: 90px;
                background: url('../images/icon_join.png') no-repeat 0 0;
                background-size: auto 100%;
            }
            label {
                display: flex;
                flex-direction: column;
                min-height: 120px;
                padding: 16px 14px;
                border-radius: 10px;
                box-shadow: 2px 2px 10px 4px rgba(63, 63, 63, 0.2);
                border: solid 1px rgba(85, 57, 255, 0.1);
                box-sizing: border-box;
                span {
                    margin-top: auto;
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 14px;
                }
                em {
                    font: {
                        size: 16px;
                        weight: bold;
                    }
                }
                &:before {
                    top: 15px;
                    left: 14px;
                }
                &:after {
                    top: 18px;
                    left: 17px;
                }
            }
            input[type="checkbox"]:checked + label {
                box-shadow: 2px 2px 10px 4px rgba(85, 57, 255, 0.2);
            }
            &.m2 {
                &:before {
                    background-position: -117px 0;
                }
            }
            &.m3 {
                &:before {
                    background-position: -234px 0;
                }
            }
            &.m4 {
                &:before {
                    background-position: -351px 0;
                }
            }
            &.m5 {
                &:before {
                    background-position: -470px 0;
                }
            }
        }
        @include respond-to(pc) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                width: calc(50% - 10px);
                &:before {
                    width: 91px;
                    height: 70px;
                }
                label {
                    min-height: 146px;
                    line-height: 140%;
                    box-shadow: 2px 2px 10px 4px rgba(63, 63, 63, 0.08);
                    span {
                        font-size: 12px;
                    }
                    em {
                        font-size: 14px;
                    }
                }
                &.m2 {
                    &:before {
                        background-position: -91px 0;
                    }
                }
                &.m3 {
                    &:before {
                        background-position: -182px 0;
                    }
                }
                &.m4 {
                    &:before {
                        background-position: -273px 0;
                    }
                }
                &.m5 {
                    &:before {
                        background-position: -357px 0;
                    }
                }
            }
        }
    }
    .job-sug {
        padding-top: 7px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(13, 18, 64, 0.05);
        > div {
            display: flex;
            align-items: center;
            margin-top: 8px;
            select {
                margin-right: 10px;
                font-size: 16px;
                &:first-child {
                    width: 35%;
                }
                &:nth-child(2) {
                    flex: 1;
                }
            }
            > button {
                position: relative;
                min-width: 40px;
                width: 40px;
                height: 40px;
                margin-left: auto;
                border-radius: 2px;
                background-color: rgba(85, 57, 255, 0.05);
                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: calc(50% - 7px);
                    left: calc(50% - 1px);
                    width: 2px;
                    height: 13px;
                    background-color: rgba(13, 18, 64, 0.6);
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
                span {
                    @include hiddenText;
                }
            }
        }
        .j-add {
            width:  100%;
            margin-top: 30px;
            color: $color-point;
            text-align: center;
        }
        @include respond-to(pc) {
            padding-bottom: 15px;
            > div {
                select {
                    flex: 1;
                }
            }
            .j-add {
                margin-top: 15px;
                text: {
                    align: right;
                    decoration: underline;
                }
            }
        }
    }
    h4 {
        margin-top: 35px;
        padding-bottom: 8px;
        font: {
            size: 14px;
            //weight: normal;
        }
        > span {
            padding-left: 10px;
            color: rgba(13, 18, 64, 0.4); 
            font-weight: normal;
        }
    }
    .chk-box {
        padding: 24px;
        border-radius: 8px;
        background-color: rgba(85,57,255,0.05);
        input + label {
            color: $color-def;
        }
        ul {
            li {
                margin-top: 15px;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        > p {
            margin-top: 15px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 12px;
        }
        &.myp-type {
            background-color: rgba(85, 57, 255, 0.05);
            border: none;
            border-radius: 2px;
        }
        &.err {
            border: 1px solid #ff4e16;
            background-color: rgba(255, 78, 22, 0.05);
        }
    }
    h3 + .chk-box {
        margin-top: 25px;
    }
    .agree {
        padding-top: 22px;
        li {
            display: flex;
            align-items: center;
            margin-top: 13px;
            label {
                a {
                    text-decoration: underline;
                }
            }
            > a {
                min-width: 26px;
                height: 26px;
                background: url('../images/icon_dirgo.svg') no-repeat 6px 50%;
                box-sizing: border-box;
                > span {
                    @include hiddenText;
                }
            }
            input.em ~ a {
                background-position: -15px 50%;
            }
        }
    }
    input.em + label {
        &:before {
            border-color: #ff4e16;
        }
        color: #ff4e16;
        a {
            color: #ff4e16;
        }
    }
    .recom-sug {
        h4 {
            margin-top: 30px;
        }
        .job-sug {
            padding-bottom: 0;
            border: none;
        }
    }
}
.w-choice {
    padding: 20px;
    border-radius: 2px;
    background-color: rgba(85, 57, 255, 0.03);
    h5 {
        margin-top: 25px;
        font-weight: normal;
        &:first-child {
            margin-top: 0;
        }
    }
    .ch-list {
        display: flex;
        flex-wrap: wrap;
        > span {
            margin-top: 10px;
            margin-right: 10px;
            //white-space: nowrap;
            label {
                display: flex;
                align-items: center;
                height: 48px;
                padding: 0 12px;
                border-radius: 2px;
                background-color: rgba(85, 57, 255, 0.03);
                color: $color-point;
                line-height: 120%;
                &:before {
                    display: none;
                }
                &:after {
                    top: 18px;
                    left: 12px;
                    width: 10px;
                    height: 5px;
                    border-width: 0 0 1px 1px;
                }
            }
            input[type="checkbox"]:checked + label {
                padding-left: 30px;
                background: $color-point;
                color: #fff;
            }
        }
    }
}
//mypage
.part-t {
    display: flex;
    align-items: center;
    margin-top: 5px;
    > span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 88px;
        width: 88px;
        height: 22px;
        margin-right: 8px;
        background: rgba(150, 255, 198, 0.05);
        border: 1px solid #96FFC6;
        border-radius: 6px;
    }
    &.gh2 {
        > span {
            background: rgba(224, 254, 82, 0.05);
            border-color: #E0FE52;
        }
    }
    &.gh3 {
        > span {
            background: rgba(255, 180, 148, 0.05);
            border-color: #FFB494;
        }
    }
}
.mypage-w {
    .header {
        padding: 120px 24px 40px;
        background: $color-point;
        .info-box {
            display: flex;
            flex-direction: column;
            max-width: 600px;
            margin: 40px auto 0;
            padding: 60px 0 45px;
            background: #fff;
            text-align: center;
            h2 {
                position: relative;
                font: {
                    size: 22px;
                    weight: normal;
                }
                span {
                    position: relative;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -3px;
                    left: calc(50% - 40px);
                    width: 80px;
                    height: 33px;
                    background-image: linear-gradient(to left, #e0fe52, rgba(224, 254, 82, 0));
                    border-radius: 30px;
                    transform: rotate(-45deg);
                }
            }
            > p {
                margin-top: 30px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                line-height: 26px;
                > span {
                    display: none;
                }
            }
            > span {
                display: block;
                margin-top: 16px;
                font-size: 14px;
                color: rgba(13, 18, 64, 0.6);
            }
            .in-btn {
                a {
                    display: block;
                    position: relative;
                    height: 64px;
                    margin-top: 27px;
                    background: #e0fe52;
                    font-size: 16px;
                    line-height: 64px;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 30px;
                        left: calc(50% - 185px);
                        width: 109px;
                        height: 88px;
                        background: url('../images/mypage_arrow.png') no-repeat 0 0;
                        background-size: 100% auto;
                    }
                }
            }
        }
        &.reject {
            .info-box {
                padding: 50px 24px 24px;
                .res-cnts {
                    margin-top: 35px;
                    font-size: 14px;
                    line-height: 22px;
                    color: rgba(13, 18, 64, 0.6);
                    > strong {
                        color: #000;
                    }
                }
            }
        }
        @include respond-to(pc) {
            overflow: hidden;
            position: relative;
            padding-bottom: 80px;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
            }
            &:before {
                top: 135px;
                left: calc(50% - 490px);
                width: 220px;
                height: 253px;
                background: url('../images/mypageimg_left01.png') no-repeat 0 100%;
                background-size: 100% auto;
            }
            &:after {
                bottom: -50px;
                left: calc(50% + 310px);
                width: 181px;
                height: 297px;
                background: url('../images/mypageimg_right01.png') no-repeat 0 0;
                background-size: 100% auto;
            }
            .info-box {
                position: relative;
                > p {
                    br {
                        display: none;
                    }
                    span {
                        display: inline;
                    }
                }
            }
            &.reject {
                &:before {
                    width: 236px;
                    height: 242px;
                    background-image: url('../images/mypageimg_left03.png')
                }
                &:after {
                    left: calc(50% + 350px);
                    width: 132px;
                    height: 305px;
                    background-image: url('../images/mypageimg_right03.png')
                }
            }
            &.end {
                &:before {
                    width: 222px;
                    height: 257px;
                    background-image: url('../images/mypageimg_left02.png')
                }
                &:after {
                    left: calc(50% + 270px);
                    width: 203px;
                    height: 350px;
                    background-image: url('../images/mypageimg_right04.png')
                }
            }
        }
    }
    .header-type2 {
        padding: 80px 0 30px;
        background: $color-point;
        .prg-pag {
            display: flex;
            flex-wrap: wrap;
            max-width: 1296px;
            min-height: 48px;
            margin: 0 auto;
            padding: 0 20px;
            box-sizing: border-box;
            button {
                display: flex;
                align-items: center;
                color: #fff;
                > span {
                    overflow: hidden;
                    min-width: 28px;
                    height: 28px;
                    margin-right: 5px;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &:before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../images/icon_prev.svg') no-repeat 0 0;
                }
                &.prev {
                    &:before {
                        margin-right: 7px;
                    }
                }
                &.next {
                    flex-direction: row-reverse;
                    margin-left: auto;
                    &:before {
                        margin-left: 7px;
                        transform: rotate(180deg);
                    }
                }
            }
            .prg-paging {
                display: flex;
                justify-content: center;
                min-width: 100%;
                margin-top: 14px;
                a {
                    width: 6px;
                    height: 6px;
                    margin: 0 5px;
                    background: rgba($color: #fff, $alpha: 0.4);
                    border-radius: 3px;
                    &.selected {
                        width: 22px;
                        background: #fff;
                    }
                }
            }
        }
        .viewport {
            overflow: auto;
            display: flex;
            //margin-top: 7px;
            //justify-content: center;
            margin-top: -80px;
            padding-top: 60px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            .pf-info {
                margin: 0 auto;
                padding: 0 9px;
                box-sizing: border-box;
                .inbox {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 311px;
                    min-height: 305px;
                    margin: 30px 0 0;
                    padding: 0 16px 20px;
                    background: #fff;
                    border-radius: 10px;
                    text-align: center;
                    box-sizing: border-box;
                    .mem-w {
                        display: flex;
                        margin-top: 10px;
                        span {
                            overflow: hidden;
                            min-width: 36px;
                            width: 36px;
                            height: 36px;
                            margin-right: -8px;
                            border-radius: 50%;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .num {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            background: $color-point;
                            font-size: 14px;
                        }
                    }
                    .photo-name {
                        position: relative;
                        top: -60px;
                        margin-bottom: -60px;
                        text-align: center;
                        .photo {
                            overflow: hidden;
                            display: block;
                            width: 120px;
                            height: 120px;
                            margin: 0 auto;
                            border-radius: 50%;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .name {
                            display: block;
                            margin-top: 15px;
                            font-size: 22px;
                        }
                    }
                    .info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        padding-top: 10px;
                        .hash {
                            a {
                                display: inline-block;
                                margin: {
                                    top: 5px;
                                    right: 10px;
                                }
                                color: rgba($color: #5539FF, $alpha: 0.8);
                                white-space: nowrap;
                            }
                        }
                        .info-j {
                            margin-top: auto;
                            ul {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                font-size: 16px;
                                li {
                                    position: relative;
                                    margin-top: 3px;
                                    i {
                                        margin-right: 5px;
                                        color: rgba($color: #0D1240, $alpha: 0.4);
                                        font-size: 14px;
                                    }
                                    &:first-child {
                                        min-width: 100%;
                                        margin-bottom: 5px;
                                    }
                                    &:last-child {
                                        margin-left: 10px;
                                        padding-left: 11px;
                                        &:before {
                                            content: '';
                                            display: block;
                                            position: absolute;
                                            top: 2px;
                                            left: 0;
                                            width: 1px;
                                            height: 14px;
                                            background: rgba(13, 18, 64, 0.1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .in-btn {
                        margin-top: auto;
                        padding-top: 13px;
                        a, button {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 44px;
                            padding: 0 33px;
                            background: $color-point;
                            color: #fff;
                            box-sizing: border-box;
                            &:disabled,
                            &.disabled {
                                background: rgba($color: #0D1240, $alpha: 0.05);
                                color: rgba($color: #0D1240, $alpha: 0.4);
                            }
                        }
                    }
                    .pf-modify {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        button {
                            width: 23px;
                            height: 22px;
                            background: url('../images/icon_menu.svg') no-repeat 0 0;
                            span {
                                @include hiddenText;
                            }
                        }
                        > div {
                            display: none;
                            position: absolute;
                            top: 34px;
                            right: 0;
                            min-width: 130px;
                            background: #fff;
                            border: 1px solid #5539FF;
                            border-radius: 2px;
                            text-align: center;
                            a {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 42px;
                                color: $color-point;
                                white-space: nowrap;
                            }
                        }
                        &.selected {
                            > div {
                                display: block;
                            }
                        }
                    }
                    .inner {
                        display: flex;
                        flex-direction: column;
                        min-height: calc(305px - 20px);
                        padding: 30px 0 0;
                        text-align: left;
                        box-sizing: border-box;
                        h2 {
                            font: {
                                size: 18px;
                                weight: 700;
                            }
                            em {
                                overflow: hidden;
                                display: inline-block;
                                max-width: 220px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            span {
                                display: block;
                                position: relative;
                                margin-top: 8px;
                                margin-left: 10px;
                                padding-left: 11px;
                                font: {
                                    size: 16px;
                                    weight: 400;
                                }
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 4px;
                                    left: 0;
                                    width: 1px;
                                    height: 11px;
                                    background: rgba(13,18,64,0.1);
                                }
                            }
                        }
                        .part,
                        .mem-num {
                            i {
                                display: block;
                                padding-bottom: 7px;
                                color: rgba($color: #0D1240, $alpha: 0.4);
                                font-size: 14px;
                            }
                        }
                        .part {
                            margin-top: auto;
                        }
                        .mem-num {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 15px;
                            > div {
                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
                .secondteam {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 311px;
                    min-height: 305px;
                    margin-top: 30px;
                    padding: 20px 36px 20px 14px;
                    background: url('../images/img_secondteam_mo.png') no-repeat 50% 50%;
                    background-size: cover;
                    border-radius: 10px;
                    box-sizing: border-box;
                    color: #fff;
                    h2 {
                        color: #fff;
                        font: {
                            size: 18px;
                            weight: normal;
                        }
                    }
                    p {
                        margin-top: 5px;
                        font-size: 16px;
                        line-height: 26px;
                        br {
                            display: none;
                        }
                    }
                }
                &:first-child {
                    margin-left: calc(50% - 311px/2);
                }
                &:last-child {
                    margin-right: calc(50% - 311px/2);
                }
            }
        }
        @include respond-to(pc) {
            padding: 80px 0 30px;
            .viewport {
                margin-top: -72px;
                .pf-info {
                    padding: 0 90px;
                    .inbox {
                        flex-direction: row;
                        width: 720px;
                        min-height: 140px;
                        text-align: left;
                        .mem-w {
                            position: absolute;
                            top: 5px;
                            right: 50px;
                        }
                        .photo-name {
                            top: -30px;
                            margin-bottom: -30px;
                            .name {
                                margin-top: 10px;
                                font-size: 18px;
                            }
                        }
                        .info {
                            padding: 25px 0 0 25px;
                            .info-j {
                                ul {
                                    justify-content: flex-start;
                                    font-size: 14px;
                                }
                            }
                        }
                        .in-btn {
                            position: absolute;
                            bottom: 25px;
                            right: 27px;
                        }
                        .inner {
                            min-height: auto;
                            padding: 20px 15px 0;
                            h2 {
                                display: flex;
                                align-items: center;
                                span {
                                    margin-top: 0;
                                }
                            }
                            .pf-modify {
                                top: 25px;
                            }
                            .mem-num {
                                justify-content: space-between;
                                position: absolute;
                                bottom: 20px;
                                right: 25px;
                                text-align: right;
                                > div {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                    .secondteam {
                        justify-content: center;
                        width: 720px;
                        min-height: 140px;
                        padding: 0;
                        background-image: url('../images/img_secondteam.png');
                        h2 {
                            font-size: 20px;
                        }
                        p {
                            margin-top: 10px;
                            font-size: 14px;
                            br {
                                display: inline;
                            }
                        }
                    }
                    &:first-child {
                        margin-left: calc(50% - 900px/2) ;
                    }
                    &:last-child {
                        margin-right: calc(50% - 900px/2) ;
                    }
                }
            }
        }
    }
    .conts-w {
        max-width: 600px;
        margin: 0 auto;
        padding: 160px 24px 80px;
        > h2 {
            @include sTit;
            &:first-child {
                margin-top: 0;
            }
            span {
                margin-left: auto;
                color: rgba(13, 18, 64, 0.4);
                font-size: 14px;
            }
        }
        .bank-in {
            padding-top: 16px;
            .inbox {
                margin-top: 16px;
                padding: 24px 32px;
                border-radius: 4px;
                background-color: rgba(85, 57, 255, 0.03);
                box-sizing: border-box;
                > em {
                    font: {
                        weight: bold;
                        size: 16px;
                    }
                }
                > p {
                    margin-top: 8px;
                    color: rgba(13, 18, 64, 0.6);
                    font-size: 12px;
                }
                > strong {
                    display: block;
                    margin-top: 48px;
                    font: {
                        size: 22px;
                        weight: normal;
                    }
                    &.st {
                        color: $color-point;
                    }
                }
            }
            @include respond-to(pc) {
                display: flex;
                justify-content: space-between;
                .inbox {
                    width: calc(50% - 8px);
                }
            }
        }
        .team-box {
            position: relative;
            margin-top: 32px;
            padding: 24px 32px 0;
            background-color: rgba(85, 57, 255, 0.03);
            h3 {
                font-size: 16px;
            }
            > p {
                margin-top: 8px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 12px;
            }
            > em {
                position: absolute;
                top: 24px;
                right: 32px;
                height: 34px;
                padding: 0 16px;
                border-radius: 2px;
                background-color: rgba(85, 57, 255, 0.05);
                color: $color-point;
                font-size: 12px;
                line-height: 34px;
            }
            .total {
                display: flex;
                justify-content: space-between;
                margin-top: 32px;
                padding-bottom: 16px;
                font-size: 16px;
                strong {
                    font-weight: normal;
                }
            }
            .team-info {
                //display: none;
                width: calc(100% + 64px);
                margin-left: -32px;
                padding-top: 24px;
                background-color: rgba(85, 57, 255, 0.03);
                border-bottom: 1px solid rgba(85, 57, 255, 0.05);
                .hgroup {
                    margin-top: 33px;
                    padding: 0 32px;
                    > em {
                        display: inline-block;
                        height: 34px;
                        padding: 0 16px;
                        border-radius: 50px;
                        background-color: rgba(13, 18, 64, 0.05);
                        font-size: 12px;
                        line-height: 34px;
                    }
                    .total {
                        margin-top: 16px;
                        font-size: 14px;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                    &.sid-type {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .total {
                            margin-top: 0;
                        }
                    }
                }
                .prd-list {
                    margin-top: 18px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        padding: 12px 24px;
                        border-top: 1px solid rgba(85, 57, 255, 0.05);
                    }
                }
            }
            > button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(100% + 64px);
                height: 32px;
                margin: {
                    left: -32px;
                }
                background-color: rgba(85, 57, 255, 0.03);
                color: $color-point;
                font-size: 12px;
                line-height: 32px;
                &:after {
                    content: '';
                    width: 0; 
                    height: 0;
                    margin-left: 7px;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $color-point;
                }
                &.btn-close {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
            &.disb {
                background-color: rgba(13, 18, 64, 0.05);
                > em {
                    background-color: rgba(13, 18, 64, 0.05);
                    color: rgba(13, 18, 64, 0.6);
                }
                > button {
                    background-color: rgba(13, 18, 64, 0.05);
                    color: $color-def;
                    &:after {
                        border-top-color: $color-def;
                    }

                }
            }
        }
    }
    .header + .conts-w {
        padding-top: 0;
    }
    .conts-w2 {
        max-width: 720px;
        margin: 0 auto;
        padding: 10px 24px 95px;
        h3 {
            @include sTit;
            margin-top: 30px;
            padding-bottom: 0;
            border: none;
            span {
                margin-left: 7px;
                color: rgba($color: #0D1240, $alpha: 0.4);
                font-size: 14px;
            }
        }
        .c-desc {
            margin-top: 8px;
            line-height: 26px;
        }
        .pf-box {
            margin-top: 15px;
            padding: 20px;
            border: 1px solid rgba(13, 18, 64, 0.05);
            box-sizing: border-box;
            border-radius: 10px;
            background: #fff;
            h4 {
                margin-top: 25px;
                color: rgba($color: #0D1240, $alpha: 0.4);
                font: {
                    size: 14px;
                    weight: normal;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
            > p {
                margin-top: 5px;
                font-size: 14px;
                line-height: 22px;
            }
            .introduce {
                margin-top: 0;
                line-height: 26px;
            }
            .skill-list {
                ul {
                    display: inline;
                    //flex-wrap: wrap;
                    font-size: 14px;
                    li {
                        display: inline-flex;
                        align-items: center;
                        height: 32px;
                        margin: {
                            top: 7px;
                            right: 8px;
                        }
                        padding: 0 10px;
                        border-radius: 30px;
                        background: rgba($color: #5539FF, $alpha: 0.03);
                    }
                }
                a.more {
                    display: inline-block;
                    margin-top: 8px;
                    white-space: nowrap;
                    color: $color-point;
                    text-decoration: underline;
                }
            }
            .data-thumb {
                margin-top: 5px;
                > a {
                    display: block;
                    > span {
                        color: #0057FF;
                        font-size: 14px;
                    }
                    .inner {
                        display: flex;
                        margin-top: 10px;
                        img {
                            width: 121px;
                            margin-right: 15px;
                        }
                        p {
                            overflow: hidden;
                            display:-webkit-box;
                            height: 89px;
                            color: rgba($color: #0D1240, $alpha: 0.4);
                            font-size: 14px;
                            line-height: 22px;
                            word-wrap:break-word;
                            -webkit-line-clamp:4;
                            -webkit-box-orient:vertical;
                            text-overflow:ellipsis;
                        }
                    }
                }
            }
            .add-info {
                margin-top: 20px;
                padding-top: 13px;
                border-top: 1px solid rgba(13, 18, 64, 0.05);
                .sns-info {
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        display: flex;
                        margin: 6px 12px 6px 0;
                        padding: 8px 12px;
                        border-radius: 2px;
                        border: solid 1px rgba(85, 57, 255, 0.05);
                        background-color: #faf9ff;
                        font-size: 14px;
                        white-space: nowrap;
                        &:before {
                            content: '';
                            width: 18px;
                            height: 18px;
                            margin-right: 9px;
                            border-radius: 4px;
                            background: url('../images/icon_sns_p.svg') no-repeat 0 0;
                        }
                        &.dribble {
                            &:before {
                                background-position: -32px 0;
                            }
                        }
                        &.github {
                            &:before {
                                background-position: -64px 0;
                            }
                        }
                        &.blog {
                            &:before {
                                background-position: -96px 0;
                            }
                        }
                        &.behance {
                            &:before {
                                background-position: -128px 0;
                            }
                        }
                        &.plus {
                            flex-direction: row-reverse;
                            &:before {
                                margin: {
                                    left: 9px;
                                    right: 0;
                                }
                                background: url('../images/icon_dirgo.svg') no-repeat -40px 50%;
                            }
                        }
                    }
                }
            }
            .donut-w {
                margin-top: 5px;
                ul {
                    padding-top: 12px;
                    font-size: 14px;
                }
            }
            hr {
                display: block;
                margin: 20px 0;
                border: 0.5px solid rgba(13, 18, 64, 0.05);
            }
        }
        .pjt-box {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 300px;
            padding: 30px 20px 20px;
            margin-top: 15px;
            box-shadow: 0px 1px 8px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            box-sizing: border-box;
            h4 {
                font-size: 18px;
                span {
                    display: block;
                    margin-top: 5px;
                    font: {
                        size: 14px;
                        weight: normal;
                    }
                }
            }
            .mem-w {
                display: flex;
                margin-top: 10px;
                span {
                    overflow: hidden;
                    min-width: 36px;
                    width: 36px;
                    height: 36px;
                    margin-right: -8px;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .num {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    background: $color-point;
                    font-size: 14px;
                }
            }
            .part,
            .mem-num {
                display: flex;
                padding-top: 10px;
                i {
                    padding-bottom: 7px;
                    color: rgba($color: #0D1240, $alpha: 0.4);
                    font-size: 14px;
                }
            }
            .part {
                flex-direction: column;
                margin-top: auto;
            }
            .mem-num {
                justify-content: space-between;
                > div {
                    display: flex;
                    flex-direction: column;
                    &:last-child {
                        align-items: flex-end;
                    }
                }
            }
        }
        h3 + .pf-box,
        h3 + .pjt-box {
            margin-top: 12px;
        }
        .pjt-add {
            margin-top: 15px;
            padding: 15px 25px;
            background: rgba($color: #5539FF, $alpha: 0.03);
            border-radius: 10px;
            text-align: center;
            p {
                color: $color-point;
                font-size: 12px;
                line-height: 18px;
            }
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 280px;
                height: 40px;
                margin: 8px auto 0;
                background: $color-point;
                color: #fff;
                font-size: 14px;
            }
        }
        .rec-list {
            ul {
                padding-bottom: 20px;
                li {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    i {
                        display: block;
                        padding-bottom: 5px;
                        color: rgba($color: #0D1240, $alpha: 0.4);
                        font-size: 14px;
                    }
                    .pay {
                        color: $color-point;
                    }
                    .time-box {
                        display: inline-flex;
                        align-items: center;
                        height: 38px;
                        margin-right: auto;
                        padding: 0 12px;
                        background: rgba($color: #5539FF, $alpha: 0.04);
                        font-size: 14px;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
        .time-list {
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    display: flex;
                    align-items: center;
                    height: 38px;
                    margin: {
                        top: 7px;
                        right: 7px;
                    }
                    padding: 0 12px;
                    background: rgba($color: #5539FF, $alpha: 0.03);
                    font-size: 14px;
                    white-space: nowrap;
                }
            }
        }
        .tit-w {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 25px;
            h3 {
                margin-top: 0;
                margin-bottom: 8px;
            }
            .chk-st {
            }
        }
        .chk-st {
            display: flex;
            flex-wrap: wrap;
            > span {
                display: flex;
                align-items: center;
                margin-left: 5px;
                font-size: 12px;
                span {
                    @include hiddenText;
                }
                &:before {
                    content: '';
                    min-width: 18px;
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                    background: url('../images/icon_ref.svg') no-repeat 0 0;
                }
                &.c2 {
                    &:before {
                        background-position: -22px 0;
                    }
                }
                &.c3 {
                    &:before {
                        background-position: -44px 0;
                    }
                }
            }
        }
        .refchk-w {
            .refchk-box {
                margin-top: 8px;
                padding: 20px 14px;
                border: 1px solid rgba(13, 18, 64, 0.05);
                border-radius: 10px;
                .pf-w {
                    display: flex;
                    .photo {
                        overflow: hidden;
                        min-width: 60px;
                        width: 60px;
                        height: 60px;
                        margin-right: 10px;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .info {
                        .name-w {
                            > div {
                                display: flex;
                                align-items: center;
                                em {
                                    margin-right: 10px;
                                    font-size: 14px;
                                    a {
                                        color: $color-point;
                                        font-weight: bold;
                                        text-decoration: underline;
                                    }
                                }
                                .chk-st {
                                    span {
                                        margin-left: 2px;
                                        &:before {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                            > p {
                                margin-top: 4px;
                                color: rgba($color: #0D1240, $alpha: 0.6);
                                font-size: 14px;
                                span {
                                    &:before {
                                        content: '·';
                                        margin: 0 4px;
                                    }
                                    &:first-child {
                                        &:before {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        .w-pjt {
                            display: flex;
                            flex-direction: column;
                            margin-top: 8px;
                            font-size: 14px;
                            i {
                                color: rgba($color: #0D1240, $alpha: 0.6);
                            }
                            a {
                                color: $color-point;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                ul {
                    line-height: 22px;
                    font-size: 14px;
                    li {
                        position: relative;
                        margin-top: 10px;
                        padding-left: 15px;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 6px;
                            left: 0;
                            width: 7px;
                            height: 4px;
                            margin-right: 8px;
                            border-radius: 10px;
                            background: #5539ff;
                            transform: rotate(-45deg);
                        }
                    }
                }
                > p {
                    margin-top: 6px;
                    padding-left: 15px;
                    color: rgba($color: #0D1240, $alpha: 0.4);
                    font-size: 12px;
                }
            }
        }
        //second-team
        .member-list {
            .mem-c {
                display: block;
                color: rgba($color: $color-def, $alpha: 0.4);
                font-size: 14px;
                text-align: right;
            }
            .p-list {
                overflow: auto;
                width: 100vw;
                margin: {
                    left: -24px;
                    top: 8px;
                }
                padding-left: 24px;
                box-sizing: border-box;
                .viewport {
                    width: 720px;
                    margin-right: 24px;
                    padding: 29px 20px 25px;
                    background: rgba($color: $color-point, $alpha: 0.03);
                    box-sizing: border-box;
                    border-radius: 15px;
                    ul {
                        display: flex;
                        text-align: center;
                        li {
                            min-width: 140px;
                            color: $color-def;
                            .photo {
                                overflow: hidden;
                                display: block;
                                width: 60px;
                                height: 60px;
                                margin: 0 auto;
                                border-radius: 50%;
                                box-sizing: border-box;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                                &.no-photo {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 2px dashed rgba(85, 57, 255, 0.1);
                                    &:before { 
                                        content: '';
                                        display: block;
                                        width: 48px;
                                        height: 48px;
                                        background: rgba(85, 57, 255, 0.05) url('../images/icon_no_mem.svg') no-repeat 50% 50%;
                                        border-radius: 50%;
                                    }
                                }
                            }
                            > em {
                                display: block;
                                margin-top: 10px;
                                font-size: 14px;
                                a {
                                    color: $color-point;
                                    font-weight: bold;
                                    text-decoration: underline;
                                }
                            }
                            div {
                                color: rgba($color: $color-def, $alpha: 0.6);
                                font-size: 14px;
                                span {
                                    display: block;
                                    margin-top: 4px;
                                }
                            }
                            &.no-mem {
                                color: rgba($color: $color-def, $alpha: 0.4);
                                div {
                                    color: rgba($color: $color-def, $alpha: 0.4);
                                }
                            }
                        }
                    }
                }
            }
            .no-member {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 195px;
                margin-top: 8px;
                border: 1px dashed rgba(85, 57, 255, 0.4);
                box-sizing: border-box;
                border-radius: 15px;
                font-size: 14px;
                text-align: center;
                p {
                    line-height: 32px;
                    span {
                        display: inline-flex;
                        align-items: center;
                        position: relative;
                        width: 58px;
                        height: 28px;
                        margin-right: 4px;
                        padding: 0 5px;
                        background: $color-point;
                        color: #fff;
                        font-size: 8px;
                        border-radius: 20px;
                        box-sizing: border-box;
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 3px;
                            right: 3px;
                            width: 22px;
                            height: 22px;
                            border-radius: 50%;
                            background: #fff url('../images/icon_toggle.png') no-repeat 50% 50%;
                            background-size: cover;
                            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                        }
                    }
                }
            }
            @include respond-to(pc) {
                margin-top: -20px;
                .p-list {
                    width: auto;
                }
                .no-member {
                    background: #fff;
                    p {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
        .with-member {
            ul {
                li {
                    position: relative;
                    min-height: 100px;
                    margin-top: 10px;
                    padding: 20px 14px;
                    background: #fff;
                    border: 1px solid rgba(13, 18, 64, 0.05);
                    border-radius: 10px;
                    box-sizing: border-box;
                    .pf-w {
                        width: 100%;
                        padding-left: 70px;
                        box-sizing: border-box;
                        .photo {
                            overflow: hidden;
                            display: block;
                            position: absolute;
                            top: 20px;
                            left: 10px;
                            min-width: 60px;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .info {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 100%;
                            min-height: 60px;
                            > div {
                                display: flex;
                                align-items: center;
                                em {
                                    margin-right: 10px;
                                    font-size: 14px;
                                    a {
                                        color: $color-point;
                                        font-weight: bold;
                                        text-decoration: underline;
                                    }
                                }
                                .chk-st {
                                    > span {
                                        margin-left: 4px;
                                        &:before {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                            > p {
                                overflow: hidden;
                                display: block;
                                width: 80%;
                                //width: calc(100% - 70px);
                                margin-top: 4px;
                                color: rgba(13,18,64,0.6);
                                font-size: 14px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                box-sizing: border-box;
                                span {
                                    &:before {
                                        content: '·';
                                        margin: 0 4px;
                                    }
                                    &:first-child {
                                        &:before {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    input[type='checkbox'] + label {
                        position: absolute;
                        top: 36px;
                        right: 14px;
                        width: 58px;
                        height: 28px;
                        padding-left: 33px;
                        background: rgba(13, 18, 64, 0.1);
                        border-radius: 17px;
                        box-sizing: border-box;
                        font-size: 8px;
                        &:before {
                            top: 3px;
                            left: 3px;
                            width: 22px;
                            height: 22px;
                            border: none;
                            border-radius: 50%;
                            background: {
                                image: url('../images/icon_toggle.png');
                                size: cover;
                            }
                            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                        }
                        &:after {
                            display: none;
                        }
                        .add {
                            color: rgba($color: $color-def, $alpha: 0.6);
                        }
                        .chk {
                            display: none;
                        }
                    }
                    input[type='checkbox'] {
                        &:checked + label {
                            padding-left: 6px;
                            background: $color-point;
                            &:before {
                                left: auto;
                                right: 3px;
                                background-color: #fff;
                            }
                            .add {
                                display: none;
                            }
                            .chk {
                                display: block;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .no-info {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 98px;
                margin-top: 10px;
                background: rgba(85, 57, 255, 0.03);
                border-radius: 15px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
                text-align: center;
                line-height: 22px;
            }
            @include respond-to(pc) {
                ul {
                    li {
                        .pf-w {
                            padding-left: 90px;
                            .photo {
                                left: 20px;
                            }
                            .info {
                                > p {
                                    //width: 100%;
                                }
                            }
                        }
                    }
                }
                .no-info {
                    p {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
        @include respond-to(pc) {
            margin-top: 50px;
            h3 {
                span {
                    font-size: 12px;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
            .box-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .pf-box {
                    width: calc(50% - 10px);
                }
            }
            .pjt-box {
                height: 140px;
                padding-top: 20px;
                h4 {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    em {
                        overflow: hidden;
                        display: inline-block;
                        max-width: 220px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    span {
                        position: relative;
                        margin-top: 0;
                        margin-left: 10px;
                        padding-left: 11px;
                        font-size: 14px;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 5px;
                            left: 0;
                            width: 1px;
                            height: 11px;
                            background: rgba(13, 18, 64, 0.1);
                        }
                    }
                }
                .mem-w {
                    position: absolute;
                    top: 5px;
                    right: 35px;
                }
                .mem-num {
                    position: absolute;
                    right: 25px;
                    bottom: 20px;
                    text-align: right;
                    > div {
                        margin-left: 72px;
                    }
                }
            }
            .pjt-add {
                p {
                    br {
                        display: none;
                    }
                }
            }
            .rec-info {
                display: flex;
                justify-content: space-between;
                .posb {
                    display: flex;
                    align-items: center;
                    margin-top: 25px;
                    color: rgba($color: #0D1240, $alpha: 0.6);
                    font-size: 14px;
                    &:before {
                        content: '';
                        min-width: 16px;
                        height: 16px;
                        margin-top: -2px;
                        margin-right: 5px;
                        background: rgba($color: #0D1240, $alpha: 0.4) url('../images/icon_x.svg') no-repeat 50% 50%;
                        border-radius: 4px;
                    }
                    &.check {
                        &:before {
                            background: {
                                color: $color-point;
                                image: url('../images/icon_check.svg');
                            }
                        }
                    }
                }
                .posb ~ .posb {
                    margin-top: 8px;
                }
                .pf-box {
                    &:first-child {
                        min-width: 300px;
                        width: 300px;
                    }
                    &:last-child {
                        width: 390px;
                        margin-left: 30px;
                    }
                }
            }
            .refchk-w {
                .refchk-box {
                    .pf-w {
                        align-items: center;
                        .info {
                            flex: 1;
                            display: flex;
                            justify-content: space-between;
                            .w-pjt {
                                align-items: flex-end;
                                margin-top: 0;
                            }
                        }
                    }
                    ul, > p {
                        margin-left: 57px;
                    }
                }
            }
        }
    }
    .dashboard-w {
        //padding-top: 25px;
        padding: 25px 20px 0;
        .profile-add {
            max-width: 700px;
            margin: 0 auto 30px;
            padding: 24px 10px 20px;
            background: #F7F5FF url('../images/img_pf_add.png') no-repeat 100% 0;
            background-size: auto 79px;
            border: 1px solid rgba(85, 57, 255, 0.1);
            border-radius: 8px;
            font-size: 14px;
            h4 {
                color: $color-point;
                &:before {
                    content: '⚠️';
                    margin-right: 5px;
                }
                br {
                    display: none;
                }
            }
            p {
                margin-top: 6px;
                line-height: 22px;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 10px;
                padding: 4px 7px 11px;
                background: #fff;
                border-radius: 6px;
                li {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: calc(50% - 2px);
                    height: 40px;
                    margin-top: 5px;
                    padding: 0 6px;
                    background: rgba(85, 57, 255, 0.1);
                    border-radius: 5px;
                    box-sizing: border-box;
                    &:before {
                        margin-right: 5px;
                        font-size: 12px;
                    }
                    &.m1 {
                        &:before {
                            content: '📛';
                        }
                    }
                    &.m2 {
                        &:before {
                            content: '⏱️';
                        }
                    }
                    &.m3 {
                        &:before {
                            content: '💰';
                        }
                    }
                    &.m4 {
                        &:before {
                            content: '💼';
                        }
                    }
                    &.chk {
                        background: rgba(13, 18, 64, 0.02);
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: calc(50% - 8px);
                            right: 12px;
                            width: 16px;
                            height: 16px;
                            background-image: url('../images/icon_chk_p.svg');
                        }
                    }
                }
            }
            .btn {
                margin-top: 15px;
            }
        }
        .dashboard-cnts {
            .conts-w2 {
                margin-top: 0;
                padding: 0;
            }
        }
        @include respond-to(pcSMax) {
            //overflow: auto;
            display: flex;
            position: relative;
            max-width: 1330px;
            height: auto;
            margin: 0 auto;
            padding: 45px 20px 0;
            .profile-add {
                position: sticky;
                top: 100px;
                left: 0;
                min-width: 262px;
                width: 262px;
                margin: 0 0 auto;
                background-position: 93% 0;
                h4 {
                    line-height: 22px;
                    br {
                        display: inline;
                    }
                    &:before {
                        display: block;
                    }
                }
                ul {
                    display: block;
                    li {
                        width: 100%;
                    }
                }
            }
            .dashboard-cnts {
                //overflow: auto;
                .conts-w2 {
                    padding-left: 20px;
                }
            }
        }
    }
    .add-info-share {
        padding-top: 15px;
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 52px;
            margin-top: 15px;
            background-color: $color-point;
            color: #fff;
            border-radius: 30px;
            font-size: 16px;
            box-sizing: border-box;
            span {
                display: flex;
                align-items: center;
                margin-left: 10px;
                &:before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    background: url('../images/icon_pjt.svg') no-repeat 0 0;
                }
            }
            &.with {
                background-color: #0BCD64;
                span {
                    &:before {
                        background-position: -27px 0;
                    }
                }
            }
            &.share {
                background-color: #fff;
                border: 2px solid $color-point;
                color: $color-point;
                span {
                    &:before {
                        background-position: -55px 0;
                    }
                }
            }
        }
        @include respond-to(pc) {
            display: flex;
            button {
                width: auto;
                margin-right: 1px;
                padding: 0 24px;
                &.share {
                    margin-left: auto;
                }
            }
        }
    }
}
.myp-incnts {
    width: calc(100% - 48px);
    max-width: 600px;
    margin: 0 auto;
    padding: 24px;
    select {
        font-size: 16px;
    }
    > .tit {
        display: flex;
        align-items: center;
        margin-top: 60px;
        padding-bottom: 13px;
        border-bottom: 1px solid #5539ff;
        color: #5539ff;
        font-size: 16px;
        font-weight: normal;
        &:before {
            content: '';
            width: 20px;
            height: 10px;
            margin-right: 8px;
            border-radius: 10px;
            background: #5539ff;
            transform: rotate(-45deg);
        }
        &:first-child {
            margin-top: 16px;
        }
    }
    h4.stit {
        margin-top: 28px;
        color: rgba(107, 109, 128, 0.4);
        font-size: 14px;
    }
    .in-cnts {
        position: relative;
        margin-top: 32px;
        padding-top: 32px;
        border-top: 1px solid rgba(13, 18, 64, 0.05);;
        textarea {
            height: 200px;
            margin-top: -15px;
        }
        input[type="text"]{
            background-color: rgba(85, 57, 255, 0.03);
        }
        p {
            white-space: pre-wrap;
        }
        h4, h5,
        .tit {
            display: block;
            margin-top: 35px;
            font-size: 16px;
            font-weight: bold;
            &:first-child {
                margin-top: 0;
            }
            @include respond-to(pc) {
                br {
                    display: none;
                }
            }
        }
        .def-form {
            input {
                margin-top: 10px;
            }
        }
        .career-w {
            margin-top: 24px;
            input, select {
                margin: 0 16px;
            }
            input {
                max-width: 110px;
                text-align: center;
            }
            span {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 8px;
                color: rgba(13, 18, 64, 0.4);
                font-size: 16px !important;
                &:first-child {
                    margin-top: 0;
                }
                &.sel-w {
                    margin-top: 0;
                    select {
                        margin: 8px 8px 0 0;
                    }
                }
            }
        }
        .comp-intu {
            position: relative;
            margin-top: 24px;
            color: rgba(13, 18, 64, 0.6);
            line-height: 26px;
            > span {
                position: absolute;
                top: -45px;
                right: 0;
                color: rgba(13, 18, 64, 0.6);
                &.err {
                    color: $color-err;
                }
            }
            input[type="text"] {
                margin-top: -16px;
            }
            > p {
                padding-top: 4px;
                font-size: 14px;
                &.top-r {
                    position: absolute;
                    top: -50px;
                    right: 0;
                    a {
                        color: $color-point;
                        font-size: 12px;
                    }
                }
                &.ok {
                    color: $color-point;
                }
            }
            > div {
                position: relative;
                .abs-cnts {
                    position: absolute;
                    top: 0;
                    right: 24px;
                    color: $color-def;
                    font-weight: normal;
                }
            }
        }
        .toggle-chk {
            margin-top: 16px;
            label {
                display: block;
                position: relative;
                width: 40px;
                height: 24px;
                padding: 0;
                border-radius: 50px;
                background-color: rgba(85, 57, 255, 0.05);
                > span {
                    @include hiddenText;
                }
                &:before {
                    display: none;
                }
                &:after {
                   content: '';
                   display: block;
                   position: absolute;
                   top: 3px;
                   left: 3px;
                   width: 18px;
                   height: 18px;
                   background-color: rgba(13, 18, 64, 0.4);
                   border-radius: 50%;
                   border: none;
                   transform: rotate(0);
                }
            }
            input[type="checkbox"]:checked + label {
                &:after {
                    left: auto;
                    right: 3px;
                    background-color: $color-point;
                }
            }
        }
        .chk-box {
            margin-top: 8px;
            padding: 15px 24px 24px;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.03);
            span {
                display: block;
                margin-top: 9px;
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 50px;
                    padding: 8px 0;
                    border-radius: 2px;
                    background-color: rgba(85, 57, 255, 0.03);
                    box-sizing: border-box;
                    color: rgba(85, 57, 255, 0.8);
                    &:before,
                    &:after {
                        display: none;
                    }
                    > i {
                        margin-right: 5px;
                        font-weight: bold;
                    }
                }
                input:checked + label {
                    background: $color-point;
                    color: #fff;
                }
            }
            &.altype2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                span  {
                    width: calc(50% - 4px);
                }
            }
            @include respond-to(pc) {
                display: flex;
                span {
                    flex: 1;
                    margin: {
                        top: 0;
                        right: 9px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &.altype2 {
                    span {
                        flex: auto;
                        margin: 12px 0 0;
                        max-width: calc(25% - 7px);
                    }
                }
            }
        }
        .sel-form {
            display: flex;
            flex-wrap: wrap;
            margin-top: 8px;
            select {
                min-width: 100%;
                padding: 0 24px;
                &:disabled {
                    background-image: none;
                    color: rgba(13, 18, 64, 0.4);
                }
                &:last-child {
                    margin-top: 8px;
                }
            }
            input {
                display: flex;
                align-items: center;
                //min-height: 60px;
                margin-top: 8px;
                padding: 0 24px;
                box-sizing: border-box;
                p {
                    padding: 0 8px;
                    color: rgba(13, 18, 64, 0.4);
                }
            }
            > div {
                display: flex;
                flex: 1;
                justify-content: space-between;
                select {
                    min-width: auto;
                    width: calc(50% - 3px);
                    margin-top: 0 !important;
                    box-sizing: border-box;
                }
            }
            .fm-box {
                display: flex;
                justify-content: start;
                align-items: top;
                padding: 0 8px 8px !important;
                > span {
                    margin-top: 8px;
                }
                .in-sel {
                    input {
                        min-height: 38px;
                        background: none;
                        box-sizing: border-box;
                    }
                }
            }
            &.type2 {
                display: flex;
                select {
                    min-width: auto;
                    width: 124px;
                }
                input {
                    flex: 1;
                    margin-top: 0;
                    margin-left: 10px;
                    padding-left: 10px;
                }
                > div {
                    position: relative;
                    input {
                        padding-right: 60px;
                        color: $color-point;
                    }
                    button {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 40px;
                        height: 40px;
                        border-radius: 2px;
                        background-color: rgba(85, 57, 255, 0.05);
                        &:before,
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: calc(50% - 7px);
                            left: calc(50% - 1px);
                            width: 2px;
                            height: 13px;
                            background: $color-point;
                            border-radius: 2px;
                        }
                        &:before {
                            transform: rotate(45deg);
                        }
                        &:after {
                            transform: rotate(-45deg);
                        }
                        span {
                            @include hiddenText;
                        }
                    }
                }
            }
            @include respond-to(pc) {
               flex-wrap: nowrap;
               select {
                    width: 100%;
                    min-width: auto;
                    margin-right: 8px;
                    &:last-child {
                        min-width: 392px;
                        margin-top: 0;
                    }
               }
               .fm-box, input {
                   //min-width: 392px;
                   min-height: 56px;
                   margin-top: 0;
                   padding: 0 8px;
               }
               > div {
                    min-width: 60%;
                    select {
                        min-width: auto !important;
                    }
               }
               > div + select {
                   min-width: 40% !important;
               }
               &.othtype {
                   display: block;
                   input {
                       margin-top: 8px;
                       padding: 0 24px;
                   }
               }
               &.type2 {
                   select, input,
                    > div {
                        min-width: auto;
                        width: 50%;
                        margin-right: 0;
                   }
               }
            }
        }
        .pjt-more {
            padding-bottom: 10px;
        }
        .tag-box {
            margin-top: 16px;
            padding: 24px;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.03);
            > div {
                display: flex;
                flex-wrap: wrap;
                > span {
                    display: inline-flex;
                    align-items: center;
                    margin: 12px 9px 0 0;
                    padding: 12px 16px;
                    border-radius: 2px;
                    //white-space: nowrap;
                    background-color: rgba(85, 57, 255, 0.03);
                    &.selected {
                        background: $color-point;
                        color: #fff;
                    }
                    button {
                        position: relative;
                        width: 16px;
                        height: 16px;
                        margin-left: 8px;
                        &:before,
                        &:after {
                            content: '';
                            position: absolute;
                            top: -1px;
                            left: 50%;
                            width: 1px;
                            height: 16px;
                            background: #fff;
                        }
                        &:before {
                            transform: rotate(45deg);
                        }
                        &:after {
                            transform: rotate(-45deg);
                        }
                        span {
                            @include hiddenText;
                        }
                    }
                }
            }
            > input {
                margin-top: 12px;
            }
            @include respond-to(pc) {
                > div {
                    > span {
                        padding: 8px 24px;
                    }
                }
            }
        }
        .rcho-w {
            margin-top: 8px;
            //padding: 24px;
            //background-color: rgba(85, 57, 255, 0.05);
            > div {
                display: flex;
                align-items: center;
                height: 58px;
                label {
                    min-width: 102px;
                    padding-top: 3px;
                    white-space: nowrap;
                    line-height: 12px;
                }
            }
        }
        .not-open {
            margin-top: 8px;
            label {
                font-size: 14px;
                color: rgba(13, 18, 64, 0.6);
            }
        }
        .key-w {
            input {
                margin-top: 8px;
            }
            @include respond-to(pc) {
                display: flex;
                justify-content: space-between;
                input {
                    width: calc(33.3% - 5px);
                }
            }
        }
        > dl {
            dt {
                margin-top: 24px;
                color: rgba(13, 18, 64, 0.6);
                &:first-child {
                    //margin-top: 0;
                }
            }
            dd {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding-top: 4px;
                > span, > a {
                    @include cateBox;
                    margin: 8px 8px 0 0;
                }
                > a {
                    color: $color-point;
                }
                textarea {
                    height: 120px;
                    //margin-top: 5px;
                }
            }
            &.form {
                margin-top: 8px;
                padding: 24px;
                border-radius: 2px;
                background-color: rgba(85, 57, 255, 0.03);
                dt {
                    padding-top: 24px;
                    border-top: 1px solid rgba(85, 57, 255, 0.05);
                    line-height: 22px;
                    br {
                        display: none;
                    }
                    &:first-child {
                        padding-top: 0;
                        border-top: none;
                    }
                }
                dd {
                    margin-top: 8px;
                    padding: 0;
                    input {
                        margin-top: 0;
                    }
                    textarea {
                        margin-top: 0;
                    }
                }
                &.in-sns {
                    dt {
                        input {
                            width: calc(100% - 64px);
                        }
                        .sns {
                            display: flex;
                            color: $color-def;
                            &:before {
                                content: '';
                                min-width: 20px;
                                height: 20px;
                                margin-right: 8px;
                                background: url('../images/icon_sns.svg') no-repeat 0 0;
                            }
                            &.drib {
                                &:before {
                                    background-position: -30px 0;
                                }
                            }
                            &.github {
                                &:before {
                                    background-position: -60px 0;
                                }
                            }
                            &.blog {
                                &:before {
                                    background-position: -90px 0;
                                }
                            }
                            &.behance {
                                &:before {
                                    background-position: -120px 0;
                                }
                            }
                            &.insta {
                                &:before {
                                    background-position: -150px 0;
                                }
                            }
                        }
                    }
                    dd {
                        flex-wrap: nowrap;
                        .btn-del {
                            position: relative;
                            min-width: 56px;
                            height: 56px;
                            top: 0;
                            right: 0;
                            margin-left: 8px;
                            /* position: relative;
                            min-width: 56px;
                            height: 56px;
                            margin-left: 8px;
                            border-radius: 2px;
                            background-color: rgba(85, 57, 255, 0.05);
                            &:before,
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 21px;
                                left: 50%;
                                width: 2px;
                                height: 13px;
                                background-color: rgba(13, 18, 64, 0.6);;
                                border-radius: 2px;
                            }
                            &:before {
                                transform: rotate(-45deg);
                            }
                            &:after {
                                transform: rotate(45deg);
                            }
                            span {
                                @include hiddenText;
                            } */
                        }
                    }
                }
                @include respond-to(pc) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding-bottom: 4px;
                    dt {
                        display: flex;
                        align-items: center;
                        width: 26%;
                        min-height: 56px;
                        margin: 0;
                        padding: 24px 0;
                        color: $color-def;
                        br {
                            display: inline;
                        }
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                    dd {
                        width: 74%;
                        min-height: 56px;
                        margin: 0;
                        padding: 24px 0;
                        border-top: 1px solid rgba(85, 57, 255, 0.05);
                        textarea {
                            height: 56px;
                            padding: 16px;
                        }
                    }
                    &.in-sns {
                        dt {
                            input {
                                width: calc(100% - 8px);
                            }
                        }
                    }
                    dt:first-child + dd {
                        padding-top: 0;
                        border-top: none;
                    }
                }
            }
        }
        .add-form {
            padding: 0 24px 24px;
            background-color: rgba(85, 57, 255, 0.03);
            .inner {
                display: flex;
                flex-wrap: wrap;
                padding-top: 16px;
                border-top: 1px solid rgba(85, 57, 255, 0.03);
                a, button {
                    display: flex;
                    align-items: center;
                    height: 44px;
                    margin-right: 8px;
                    margin-top: 8px;
                    padding: 0 12px;
                    background-color: rgba(85, 57, 255, 0.03);
                    color: $color-point;
                    font-size: 14px;
                    &:before {
                        content: '';
                        width: 18px;
                        height: 18px;
                        margin-right: 9px;
                        background: url('../images/icon_sns_p.svg') no-repeat 0 0;
                    }
                    &.linkedin {
                        &:before {
                            background-position: 0 0;
                        }
                    }
                    &.drib {
                        &:before {
                            background-position: -32px 0;
                        }
                    }
                    &.github {
                        &:before {
                            background-position: -64px 0;
                        }
                    }
                    &.blog {
                        &:before {
                            background-position: -96px 0;
                        }
                    }
                    &.behance {
                        &:before {
                            background-position: -128px 0;
                        }
                    }
                    &.insta {
                        &:before {
                            background-position: -160px 0;
                        }
                    }
                    &.plus {
                        &:before {
                            background-position: -187px 0;
                        }
                    }
                }
            }
        }
        > p {
            margin-top: 9px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 14px;
            label {
                font-size: 14px;
                color: rgba(13, 18, 64, 0.6);
            }
        }
        .time-choice {
            margin-top: 8px;
            .in-form {
                select {
                    width: 100%;
                }
                .time {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 8px;
                    padding: 0 24px;
                    border-radius: 2px;
                    background-color: rgba(85, 57, 255, 0.03);
                    input {
                        margin-right: 10px;
                        padding: 0;
                        background: none;
                    }
                    span {
                        font-size: 16px;
                        white-space: nowrap;
                    }
                }
            }
            .desc {
                margin-top: 8px;
                padding: 16px 24px;
                border-radius: 2px;
                background-color: rgba(13, 18, 64, 0.02);
                p {
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 16px;
                }
            }
            @include respond-to(pc) {
                .in-form {
                    display: flex;
                    select {
                        width: 50%;
                    }
                    .time {
                        width: 50%;
                        margin: {
                            top: 0;
                            left: 8px;
                        }
                    }
                }
                .desc {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        .pjt-history {
            padding-top: 8px;
            > div {
                display: flex;
                flex-direction: column;
                margin-top: 8px;
                padding: 24px;
                background: rgba(13, 18, 64, 0.02);
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
                box-sizing: border-box;
                > p {
                    font-weight: bold;
                }
                > em {
                    margin-top: 16px;
                    font-weight: bold;
                    span {
                        padding-right: 4px;
                        font-weight: normal;
                    }
                }
                .date {
                    margin-top: 16px;
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 12px;
                }
            }
            @include respond-to(pc) {
                display: flex;
                justify-content: space-between;
                > div {
                    width: calc(50% - 4px);
                }
            }
        }
        .btn-calc {
            margin-top: 8px;
            button {
                color: $color-point;
                text-decoration: underline;
            }
            @include respond-to(pc) {
                position: absolute;
                top: 32px;
                right: 0;
                margin-top: 0;
            }
        }
        &:first-child {
            padding-top: 0;
            border-top: none;
        }
        &.flx-type2 {
            display: flex;
            flex-wrap: wrap;
            margin-top: -12px;
            .inner {
                width: 50%;
                margin-top: 24px;
            }
            @include respond-to(pc) {
                flex-wrap: nowrap;
            }
        }
    }
    h3.tit + .in-cnts {
        padding-top: 0;
        border-top: none;
    }
    .pjt-list {
        padding-bottom: 24px;
        li {
            margin-top: 28px;
            font-size: 16px;
            em {
                font-weight: bold;
            }
            p {
                margin-top: 10px;
                color: rgba(13, 18, 64, 0.6);
            }
        }
        &.li3 {
            border-bottom: 1px solid rgba(13, 18, 64, 0.05);
        }
        @include respond-to(pc) {
            padding-bottom: 32px;
            li {
                font-size: 14px;
                p {
                    margin-top: 16px;
                }
            }
            &.li2 {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    &.wtype2 {
                        width: 100%;
                    }
                }
            }
            &.li3 {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 33.3%;
                }
            }
        }
        .cate {
            display: flex;
            flex-direction: column;
            padding-top: 8px;
            span {
                margin: {
                    top: 8px;
                    right: auto;
                }
                padding: 8px 16px;
                border-radius: 2px;
                border: solid 1px rgba(13, 18, 64, 0.05);
                background-color: rgba(13, 18, 64, 0.02);
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                i {
                    padding-right: 5px;
                    font-weight: bold;
                }
            }
            @include respond-to(pc) {
                flex-direction: row;
                span {
                    margin-right: 8px;
                    padding: 8px 24px;
                    font-size: 14px;
                }
            }
        }
    }
    .pjt-box {
        overflow: hidden;
        margin-top: 24px;
        border-radius: 4px;
        border: 1px solid rgba(13, 18, 64, 0.05);
        .pjt-hgroup {
            padding: 24px 24px 16px;
            background-color: rgba(13, 18, 64, 0.02);
            h4 {
                font-size: 16px;
            }
            p {
                margin-top: 10px;
                a {
                    color: rgba(13, 18, 64, 0.6);
                }
            }
        }
        .incnt-w {
            padding: 0 24px 24px;
            .in-cnts {
                &:first-child {
                    padding-top: 0;
                }
            }
        }
    }
    .pjt-detail-box {
        margin-top: 16px;
        padding: 35px 24px 24px;
        border-radius: 4px;
        border: solid 1px rgba(13, 18, 64, 0.1);
        .in-cnts {
            &:first-child {
                margin-top: 0;
            }
            .career-w {
                margin-top: 0
                ;
            }
        }
    }
    .pjt-add {
        margin-top: 32px;
        padding-top: 32px;
        border-top: 1px solid rgba(13, 18, 64, 0.05);
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 56px;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.03);
            color: $color-point;
            &:before {
                content: '';
                width: 22px;
                height: 22px;
                margin-right: 10px;
                background: url('../images/icon_sns02.svg') no-repeat -79px 50%;
            }
        }
        p {
            margin-top: 8px;
            color: rgba(13, 18, 64, 0.4);
            text-align: center;
        }
    }
    .cho-rbox {
        margin-top: 16px;
        padding: 24px;
        border-radius: 2px;
        background-color: rgba(85, 57, 255, 0.03);
        ul {
            li {
                margin-top: 16px;
                label {
                    color: #000;
                    font-weight: normal;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
    .cho-input {
        position: relative;
        margin-top: 140px;
        &:before {
            content: '';
            display: block;
            width: 100px;
            height: 1px;
            margin: 0 auto;
            background-color: rgba(13, 18, 64, 0.1);
        }
        .smtit {
            margin-top: 30px;
        }
        h5 {
            margin-top: 30px;
            font: {
                weight: bold;
                size: 16px;
            }
        }
        .chk-list {
            margin-left: 10px;
            li {
                margin-top: 25px;
                label {
                    padding-left: 35px;
                    color: $color-def;
                    line-height: 22px;
                    &:before {
                        width: 22px;
                        height: 22px;
                        border: {
                            width: 2px;
                            color: $color-point;
                        }
                    }
                    &:after {
                        top: 6px;
                        left: 6px;
                    }
                    > span {
                        display: block;
                        color: rgba(13, 18, 64, 0.4);
                    }
                }
            }
            @include respond-to(pc) {
                margin-left: 4px;
                li {
                    margin-top: 11px;
                    label {
                        padding-left: 22px;
                        line-height: 16px;
                        &:before {
                            width: 16px;
                            height: 16px;
                            border-width: 1px;
                        }
                        &:after {
                            top: 3px;
                            left: 3px;
                        }
                        > span {
                            display: inline;
                        }
                    }
                }
            }
        }
        .in-cnts {
            margin-top: 0;
            border: none;
        }
        textarea {
            height: 142px;
            padding: 17px 16px;
        }
        .desc-r {
            margin-top: 8px;
            text-align: right;
            &:before {
                content: '*';
                margin-right: 3px;
            }
            @include respond-to(pc) {
                font-size: 12px;
            }
        }
    }
    .btn {
        &.sticky {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 8px 24px;
            background: #fff;
            box-shadow: 0 -1px 0 0 rgba(13, 18, 64, 0.1);
            box-sizing: border-box;
            .inner {
                max-width: 600px;
                margin: 0 auto;
                box-sizing: border-box;
                a, button {
                    margin-top: 0;
                }
            }
        }
    }
    &.wtype2 {
        max-width: 500px;
    }
}
.mypage-cnts {
    padding-bottom: 80px;
    &:before {
        content: '';
        display: block;
        height: 170px;
        background: $color-point;
    }
    &.intit {
        &:before {
            display: none;
        }
    }
    @include respond-to(pc) {
        &:before {
            height: 200px;
        }
    }
    .top-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 48px);
        max-width: 600px;
        margin: -60px auto 0;
        padding: 0 24px;
        text-align: center;
        .profile {
            .photo {
                position: relative;
                width: 120px;
                margin: 0 auto;
                > span {
                    display: block;
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    background: #eee;
                    background: {
                        size: cover;
                        repeat: no-repeat;
                        position: 50% 50%;
                    }
                    border: 1px solid rgba(13, 18, 64, 0.05);
                    box-sizing: border-box;
                }
                .photo-up {
                    label {
                        display: block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 32px;
                        height: 32px;
                        background: $color-point url('../images/icon_write.svg') no-repeat 50% 50%;
                        border: 2px solid #fff;
                        border-radius: 50%;
                        box-sizing: border-box;
                        cursor: pointer;
                        span {
                            @include hiddenText;
                        }
                    }
                }
            }
            h2 {
                margin-top: 16px;
                font: {
                    size: 22px;
                    weight: normal;
                }
            }
            .cate {
                display: flex;
                justify-content: center;
                margin-top: 16px;
                > span, > a {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    height: 46px;
                    margin: 0 4px;
                    padding: 0 24px;
                    background-color: rgba(13, 18, 64, 0.02);
                    color: rgba(13, 18, 64, 0.6);
                    font-size: 14px;
                    border-radius: 2px;
                    strong, em {
                        font-weight: bold;
                    }
                    strong {
                        color: $color-point;
                    }
                }
                > a.homp {
                    color: $color-point;
                    &:after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        margin-left: 14px;
                        background: url('../images/icon_dirgo.svg') no-repeat 100% 0;
                    }
                }
            }
            .desc {
                margin-top: 24px;
                color: rgba(13, 18, 64, 0.6);
                line-height: 26px;
                a {
                    color: $color-point;
                }
                @include respond-to(pc) {
                    br {
                        display: none;
                    }
                }
            }
        }
        .in-btn {
            width: 100%;
            margin-top: 24px;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 48px;
                background: $color-point;
                color: #fff;
                &:before {
                    content: '';
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                    background: url('../images/icon_write.svg') no-repeat 0 0;
                }
                &.sug {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .btn {
       // padding: 0 20px;
       padding-top: 0;
        a, button {
            margin-left: 8px;
            &:first-child {
                margin-left: 0;
            }
        }
        /* width: calc(100% - 48px);
        max-width: 600px;
        margin: 16px auto 0;
        padding: 0 24px; */
        @include respond-to(pc) {
            padding: 0;
        }
    }
    .myp-incnts + .btn {
        max-width: 648px;
        margin: 0 auto;
        padding: 0 24px;
    }
    .conts-w {
        width: calc(100% - 48px);
        max-width: 600px;
        margin: 0 auto;
        h3 {
            margin-top: 65px;
        }
        .agree {
            padding-top: 39px;
        }
        .btn {
            width: 100%;
            margin-top: 40px;
            padding: 0;
        }
    }
    .modify-prog {
        padding: 40px 0 20px;
        background: $color-point;
        color: #fff;
        text-align: center;
        p {
            padding: 0 24px;
            font-size: 22px;
            line-height: 34px;
        }
    }
    //마이페이지 수정
    &.modify {
        padding-top: 72px;
        &:before {
            display: none;
        }
        .top-info {
            margin-top: 24px;
            h2 {
                font: {
                    weight: bold;
                    size: 16px;
                }
            }
        }
    }
}
.step {
    overflow: hidden;
    overflow-x: auto;
    width: 100vw;
    margin: {
        top: 24px;
        //left: -24px;
    }
    padding: 0 24px;
    box-sizing: border-box;
    transition: all 0.5s;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    ul {
        display: flex;
        li {
            padding-right: 1px;
            a {
                display: block;
                position: relative;
                height: 42px;
                padding: 0 16px;
                background-color: rgba(255, 255, 255, 0.1);
                white-space: nowrap;
                color: rgba(255, 255, 255, 0.6);
                line-height: 42px;
                i {
                    display: block;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    width: 6px;
                    height: 6px;
                    background: #ff4e16;
                    border-radius: 50%;
                    span {
                        @include hiddenText;
                    }
                }
            }
            &:first-child {
                a {
                    border-radius: 5px 0 0 5px;
                }
            }
            &:last-child {
                a {
                    border-radius: 0 5px 5px 0;
                }
            }
            &.selected {
                a {
                    background-color: rgba(255, 255, 255, 0.2);
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }
    @include respond-to(pc) {
        ul {
            justify-content: center;
            li {
                a {
                    padding: 0 32px;
                }
            }
        }
    }
}
.email-inc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    min-height: 100%;
    margin: 0 auto;
    text-align: center;
    h1 {
        font: {
            size: 32px;
            weight: normal;
        }
        &:after {
            content: '';
            display: block;
            width: 196px;
            height: 196px;
            margin-top: 18px;
            background: url('../images/img_email_inc.png') no-repeat 0 0;
            background-size: auto 100%;
        }
    }
    strong {
        display: block;
        margin-top: 18px;
        font: {
            size: 22px;
            weight: normal;
        }
    }
    p {
        margin-top: 16px;
        color: rgba(13, 18, 64, 0.6);
        font-size: 14px;
        line-height: 22px;
        em {
            font-weight: bold;
        }
    }
    .btn {
        width: 100%;
    }
    &.end {
        h1 {
            &:after {
                background-position: 100% 0;
            }
        }
    }
}
.mem-price {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 24px;
    text-align: center;
    .info-box {
        margin-bottom: 8px;
        padding: 24px;
        border-radius: 2px;
        border: solid 1px rgba(13, 18, 64, 0.05);
        > strong {
            font-size: 24px;
            line-height: 34px;
            b {
                color: $color-point;
            }
        }
        > p {
            margin-top: 16px;
            color: rgba(13, 18, 64, 0.6);
            line-height: 26px;
            &.desc {
                margin-top: 8px;
                font-size: 14px;
                line-height: 22px;
            }
        }
        .in-btn {
            margin-top: 24px;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 48px;
                background: $color-point;
                color: #fff;
                font-size: 14px;
                &.disb {
                    background-color: rgba(13, 18, 64, 0.05);
                    color: rgba(13, 18, 64, 0.4);
                }
            }
        }
    }
    > .link {
        a {
            color: $color-point;
            font-size: 14px;
            text-decoration: underline;
        }
    }
    .update-cnts {
        margin-top: 40px;
        h3 {
            padding-bottom: 18px;
            font-size: 16px;
            text-align: left;
            border-bottom: 1px solid rgba(13, 18, 64, 0.05);
        }
        .viewport {
            overflow: hidden;
            overflow-x: auto;
            width: 100vw;
            margin: {
                top: 24px;
                left: -24px;
            }
            padding-left: 24px;
            box-sizing: border-box;
            table {
                width: 600px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
                th {
                    height: 48px;
                    background-color: rgba(13, 18, 64, 0.05);
                }
                td {
                    height: 68px;
                    background-color: rgba(13, 18, 64, 0.02);
                }
            }
        }
        .no-update {
            margin-top: 24px;
            padding: 20px 0;
            background-color: rgba(13, 18, 64, 0.02);
            color: rgba(13, 18, 64, 0.4);
            font-size: 16px;
            text-align: center;
        }
    }
}
//project recruit
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:before {
        content: '';
        width: 200px;
        height: 200px;
        background: url('../images/no_data.png') no-repeat 0 0;
        background-size: cover;
    }
    p {
        margin-top: 24px;
        color: rgba(13, 18, 64, 0.4);
        font-size: 16px;
    }
    @include respond-to(pc) {
        margin-top: 24px;
        p {
            font-size: 22px;
        }
    }
}
.project-recruit {
    max-width: 800px;
    padding: 40px 20px;
    margin: 0 auto;
    .hgroup {
        > h3 {
            font-size: 16px;
        }
        .prog {
            display: flex;
            flex-wrap: wrap;
            margin-top: 16px;
            font-size: 16px;
            > em {
                color: $color-point;
                span {
                    &:before {
                        content: '·';
                        margin: 0 5px;
                    }
                    &:first-child {
                        &:before {
                            display: none;
                        }
                    }
                    i {
                        display: none;
                    }
                }
            }
            > p {
                color: rgba(13, 18, 64, 0.4);
                span {
                    margin-left: 5px;
                }
                &:before {
                    content: '|';
                    margin: 0 10px;
                }
            }
        }
    }
    > ul {
        margin-top: 16px;
        //border-top: 1px solid rgba(13, 18, 64, 0.05);
        font-size: 14px;
        li {
            margin-top: 16px;
            a {
                display: block;
                border-radius: 4px;
                border: solid 1px rgba(85, 57, 255, 0.05);
                background-color: rgba(85, 57, 255, 0.03);
                .info-w {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 24px;
                    .info {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        width: 100%;
                        //width: calc(100% - 80px);
                        color: rgba(13, 18, 64, 0.6);
                        strong {
                            overflow: hidden;
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        span {
                            margin-top: 5px;
                        }
                    }
                    .not {
                        padding: 8px 16px;
                        border-radius: 2px;
                        background-color: rgba(255, 78, 22, 0.05);
                        color: #ff4e16;
                        i {
                            display: none;
                        }
                    }
                }
                .prog-condi {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    overflow: hidden;
                    position: relative;
                    padding: 16px 24px;
                    border-top: 1px solid rgba(85, 57, 255, 0.05);
                    .nick {
                        overflow: hidden;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        span {
                            min-width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background: {
                                repeat: no-repeat;
                                position: 50% 50%;
                                size: cover;
                            }
                        }
                        .info {
                            //flex: 1;
                            position: relative;
                            padding-left: 16px;
                            em {
                                display: block;
                                width: 100%;
                                font-weight: bold;
                            }
                        }
                    }
                    .condi {
                        margin-left: auto;
                        span {
                            display: block;
                            padding: 8px 16px;
                            border-radius: 2px;
                            background-color: rgba(13, 18, 64, 0.02);
                            color: rgba(13, 18, 64, 0.6);
                            white-space: nowrap;
                            box-sizing: border-box;
                            &.ing {
                                color: $color-point;
                            }
                            &.end {
                                color: rgba(13, 18, 64, 0.4);
                            }
                        }
                    }
                }
                &.end {
                    border: solid 1px rgba(13, 18, 64, 0.05);
                    background-color: rgba(13, 18, 64, 0.02);
                }
            }
        }
    }
    .paging {
        margin-top: 40px;
    }
    @include respond-to(pc) {
        .hgroup {
            display: flex;
            justify-content: space-between;
            .prog {
                margin-top: 0;
                > em {
                    span {
                        i {
                            display: inline;
                        }
                        &.end {
                            color: rgba(13, 18, 64, 0.4);
                        }
                    }
                }
            }
        }
        > ul {
            li {
                a {
                    display: flex;
                    position: relative;
                    .info-w {
                        min-width: 27%;
                        width: 27%;
                        .not {
                            position: absolute;
                            right: 152px;
                            font-size: 12px;
                            i {
                                display: inline;
                            }
                        }
                    }
                    .prog-condi {
                        width: 73%;
                        border-top: none;
                        box-sizing: border-box;
                        .nick {
                            //width: calc(100% - 120px);
                            .info {
                                width: calc(100% - 200px);
                                em {
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        .condi {
                            position: absolute;
                            right: 24px;
                            span {
                                width: 120px;
                                font-size: 12px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
.total-price {
    margin-top: 16px;
    background-color: rgba(13, 18, 64, 0.02);
    color: rgba(13, 18, 64, 0.6);
    .price-info {
        display: flex;
        padding: 17px 24px;
        > div {
            display: flex;
            flex-direction: column;
            width: 50%;
            i {
                font-size: 12px;
            }
            em {
                margin-top: 4px;
                font-weight: bold;
                line-height: 26px;
                span {
                    font-weight: normal;
                }
            }
        }
    }
    > p {
        padding: 12px 0;
        text-align: center;
        font-size: 14px;
        a {
            color: rgba(13, 18, 64, 0.6);
            &:after {
                content: '>';
                margin-left: 10px;
            }
        }
    }
    &.type2 {
        text-align: center;
    }
    @include respond-to(pc) {
        display: flex;
        .price-info {
            width: 60%;
            > div {
                em {
                    font-size: 16px;
                }
            }
        }
        > p {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: auto;
            padding-right: 24px;
        }
        &.type2 {
            display: block;
            padding-bottom: 16px;
            .price-info {
                display: block;
                width: auto;
                > div {
                    display: block;
                    width: auto;
                    font-size: 16px;
                }
            }
            > p {
                display: block;
                padding: 0;
            }
        }
    }
}
.admin-sp {
    margin-top: 24px;
    border-top: 1px solid  rgba(13, 18, 64, 0.05);
    .hgroup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        h3 {
            font-size: 16px;
        }
        > div {
            display: flex;
            align-items: center;
            > p {
                padding-right: 8px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 12px;
                &.end {
                    color: rgba(13, 18, 64, 0.4);
                }
            }
            > span {
                padding: 8px 12px;
                font-size: 14px;
                border-radius: 2px;
                background-color: rgba(85, 57, 255, 0.05);
                color: $color-point;
                &.cel {
                    background-color: rgba(255, 78, 22, 0.05);
                    color: #ff4e16;
                    font-size: 12px;
                }
            }
        }
    }
    .spt-wrap {
        .inbox {
            position: relative;
            margin-top: 16px;
            border-radius: 4px;
            border: solid 1px rgba(85, 57, 255, 0.05);
            background-color: rgba(85, 57, 255, 0.03);
            input {
                //width: auto;
                height: 22px;
                padding: 0;
                background: none;
                color: rgba(13, 18, 64, 0.6);
                font-size: 14px;
                text-decoration: underline;
            }
            .noti {
                display: none;
                position: absolute;
                top: 22px;
                left: 0;
                padding: 8px;
                border-radius: 4px;
                border: solid 1px rgba(13, 18, 64, 0.05);
                background: #fff;
                color: #ff4e16;
                font-size: 12px;
                white-space: nowrap;
            }
            input:focus ~ .noti {
                display: block;
            }
            .prog-type {
                display: none;
            }
            > strong {
                display: block;
                padding: 22px 24px 16px;
                font-size: 14px;
                white-space: nowrap;
            }
            .date {
                display: flex;
                padding: 16px 24px;
                > div {
                    position: relative;
                    width: 50%;
                    padding-right: 15px;
                    .tit, > label {
                        display: block;
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 12px;
                    }
                    > span {
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 14px;
                    }
                    > em {
                        display: block;
                        margin-top: 4px;
                        font: {
                            size: 14px;
                            weight: bold;
                        }
                        color: rgba(13, 18, 64, 0.6);
                    }
                    .noti {
                        top: 35px;
                    }
                }
            }
            .sp-price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 22px 24px;
                border-top: 1px solid rgba(85, 57, 255, 0.05);
                .int-price {
                    display: flex;
                    align-items: center;
                    position: relative;
                    height: 22px;
                    input {
                        width: 70px;
                        margin-right: 3px;
                        text-align: right;
                    }
                    span {
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 14px;
                    }
                    i {
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 14px;
                    }
                }
            }
            .condi {
                > span {
                    display: block;
                    min-width: 94px;
                    max-width: 94px;
                    margin: 0 auto;
                    padding: 8px 12px;
                    background: $color-point;
                    color: #fff;
                    font-size: 12px;
                    white-space: nowrap;
                    border-radius: 2px;
                    box-sizing: border-box;
                    text-align: center;
                    &.sett-ok {
                        border: solid 1px rgba(13, 18, 64, 0.05);
                        background-color: rgba(13, 18, 64, 0.02);
                        text-align: center;
                        color: rgba(13, 18, 64, 0.6);
                    }
                    &.cel {
                        background: $color-def;
                    }
                }
                > p {
                    margin-top: 6px;
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 10px;
                }
            }
            &.end {
                border: solid 1px rgba(13, 18, 64, 0.05);
                background-color: rgba(13, 18, 64, 0.02);
            }
        }
    }
    .weekly-adj {
        margin-top: 16px;
        color: rgba(13, 18, 64, 0.4);
        .pre-str {
            padding: 10px 0 10px 24px;
            color: $color-def;
            em {
                padding: 0 5px;
                color: $color-point;
            }
        }
        .w-inner {
            display: flex;
            flex-direction: column;
            min-height: 130px;
            border-top: solid 1px rgba(85, 57, 255, 0.05);
            background: rgba(85, 57, 255, 0.03);
            &:first-child {
                border: none;
            }
            .h-w {
                display: flex;
                justify-content: space-between;
                padding: 16px 24px;
                border-bottom: 1px solid rgba(85, 57, 255, 0.05);
                strong {
                    display: block;
                    color: $color-def;
                    font-weight: bold;
                }
                span {
                    display: block;
                }
            }
            .condi {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding: 0 39px 0 24px;
                .w-price {
                    color: rgba(13, 18, 64, 0.6);
                    i {
                        display: block;
                        font-size: 12px;
                    }
                    em {
                        display: block;
                        margin-top: 5px;
                        font-weight: bold;
                    }
                }
                p.tw {
                    font-size: 12px;
                    em {
                        padding: 0 3px;
                        color: $color-def;
                    }
                }
                > span {
                    display: block;
                    min-width: 95px;
                    margin: auto 0 auto auto;
                    padding: 8px 17px;
                    border: solid 1px rgba(13, 18, 64, 0.05);
                    background-color: rgba(13, 18, 64, 0.02);
                    font-size: 12px;
                    border-radius: 2px;
                    box-sizing: border-box;
                    text-align: center;
                    i {
                        display: block;
                        margin-bottom: 4px;
                    }
                    a {
                        display: block;
                        margin-top: 4px;
                        color: rgba(13, 18, 64, 0.4);
                        text-decoration: underline;
                    }
                    &.p-type {
                        background: $color-point;
                        color: #fff;
                    }
                    &.ing {
                        background-color: rgba(13, 18, 64, 0.05);
                    }
                }
                > button {
                    position: absolute;
                    right: 9px;
                    width: 30px;
                    height: 30px;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 9px;
                        left: 7px;
                        width: 0; 
                        height: 0; 
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent; 
                        
                        border-left: 5px solid blue; 
                    }
                    span {
                        @include hiddenText;
                    }
                }
            }
            &.end {
                background-color: rgba(13, 18, 64, 0.02);
            }
        }
    }
    @include respond-to(pc) {
        margin-top: 32px;
        .spt-wrap {
            .inbox {
                display: flex;
                align-items: center;
                min-height: 82px;
                padding: 19px 24px;
                box-sizing: border-box;
                .prog-type {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 80px;
                    height: 34px;
                    border-radius: 2px;
                    background-color: rgba(13, 18, 64, 0.02);
                    font-size: 12px;
                    &.ing {
                        color: $color-point;
                    }
                    &.end {
                        color: rgba(13, 18, 64, 0.4);
                    }
                }
                > strong {
                    padding: 0 24px;
                }
                .date {
                    padding: 0;
                    > div {
                        width: 96px;
                        margin: 0 24px;
                        > em {
                            display: block;
                            margin-top: 4px;
                        }
                    }
                }
                .sp-price {
                    flex: auto;
                    padding: 0;
                    border: none;
                    .int-price {
                        flex-wrap: wrap;
                        justify-content: center;
                        text-align: center;
                        width: 115px;
                        height: auto;
                        > i {
                            min-width: 100%;
                            color: rgba(13, 18, 64, 0.6);
                            font-size: 12px;
                        }
                        .noti {
                            //display: block;
                            top: calc(50% + 10px);
                        }
                        > i + .noti {
                            top: calc(50% + 3px);
                        }
                    }
                }
            }
        }
        .weekly-adj {
            .w-inner {
                flex-direction: row;
                align-items: center;
                min-height: 82px;
                .h-w {
                    display: block;
                    border: none;
                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
.settle-wrap {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 24px;
    .pjt-info {
        border-radius: 4px;
        background-color: rgba(85, 57, 255, 0.03);
        font-size: 14px;
        .in-btn {
            display: flex;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: auto;
                height: 48px;
                padding: 0 24px;
                border-radius: 2px;
                background-color: rgba(85, 57, 255, 0.05);
                color: $color-point;
                text-align: center;
                box-sizing: border-box;
                &.btn-pf {
                    max-width: 77px;
                    white-space: nowrap;
                }
                &.commu {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 8px;
                    background: $color-point;
                    color: #fff;
                    &:before {
                        content: '';
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;
                        background: url('../images/icon_commu.svg') no-repeat 0 0;
                    }
                }
            }
        }
        .profile {
            .nick {
                display: flex;
                align-items: center;
                padding: 24px;
                > span {
                    min-width: 64px;
                    height: 64px;
                    background: {
                        size: cover;
                        repeat: no-repeat;
                        position: 50% 50%;
                    }
                }
                .info {
                    overflow: hidden;
                    flex: 1;
                    margin-left: 16px;
                    > div {
                        display: flex;
                        //flex-direction: column;
                        /* strong {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        } */
                        i {
                            margin-right: 3px;
                            font-weight: bold;
                        }
                        span {
                            margin-top: 4px;
                            color: rgba(13, 18, 64, 0.6);
                        }
                        &.cname {
                            font-weight: bold;
                        }
                    }
                    p {
                        margin-top: 4px;
                        color: rgba(13, 18, 64, 0.4);
                    }
                }
            }
            .part {
                padding: 15px 24px 24px;
                border-top: 1px solid rgba(85, 57, 255, 0.05);
                color: rgba(13, 18, 64, 0.6);
                line-height: 22px;
                > p {
                    overflow: hidden;
                    color: rgba(13, 18, 64, 0.6);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                > span {
                    color: rgba(13, 18, 64, 0.4);
                }
                > em {
                    font-weight: bold;
                }
                .in-btn {
                    margin-top: 16px;
                }
            }
        }
        .price {
            padding: 24px 24px 0;
            border-top: 1px solid rgba(85, 57, 255, 0.05);
            > p {
                color: rgba(13, 18, 64, 0.4);
            }
            table {
                margin-top: 8px;
                th, td {
                    padding: 8px 0;
                }
                th {
                    text-align: left;
                    font-weight: bold;
                }
                td {
                    color: rgba(13, 18, 64, 0.6);
                    text-align: right;
                }
            }
            .in-btn {
                width: 100%;
                margin-top: 8px;
                margin-left: -24px;
                padding: 24px;
                border-top: 1px solid rgba(85, 57, 255, 0.05);
            }
        }
        @include respond-to(pc) {
            display: flex;
            padding: 0;
            > div {
                min-width: 50%;
                box-sizing: border-box;
                &.price {
                    display: flex;
                    flex-direction: column;
                    padding-right: 24px;
                    border-top: none;
                    border-left: 1px solid rgba(85, 57, 255, 0.05);
                    .in-btn {
                        margin-top: auto;
                    }
                }
            }
        }
    }
}
.adj-w {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(13, 18, 64, 0.05);
    @include respond-to(pc) {
        padding-bottom: 32px;
        margin-bottom: 32px;
    }
}
.cnts-hgroup {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(13, 18, 64, 0.05);
    h3 {
        display: none;
    }
    .tab {
        ul {
            display: flex;
            align-items: center;
            li {
                color: rgba(13, 18, 64, 0.4);
                a {
                    color: rgba(13, 18, 64, 0.4);
                }
                &:before {
                    content: '·';
                    margin: 0 5px;
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
                &.selected {
                    a {
                        color: $color-point;
                    }
                }
            }
        }
    }
    @include respond-to(pc) {
        display: flex;
        h3 {
            display: block;
            font-size: 14px;
        }
        .tab {
            margin-left: auto;
            font-size: 14px;
        }
    }
}
.cnts-hgroup2 {
    margin-top: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(13, 18, 64, 0.05);
    .tab {
        ul {
            display: flex;
            align-items: center;
            li {
                color: rgba(13, 18, 64, 0.4);
                a {
                    color: rgba(13, 18, 64, 0.4);
                }
                &:before {
                    content: '·';
                    margin: 0 5px;
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
                &.selected {
                    a {
                        color: $color-point;
                    }
                }
            }
        }
    }
    .tab {
        ul {
            display: flex;
            align-items: center;
            li {
                color: rgba(13, 18, 64, 0.4);
                a {
                    color: rgba(13, 18, 64, 0.4);
                }
                &:before {
                    content: '·';
                    margin: 0 5px;
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
                &.selected {
                    a {
                        color: $color-point;
                    }
                }
            }
        }
    }
}
.recu-status {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 24px;
    .status-detail {
        .inbox {
            display: flex;
            flex-direction: column;
            margin-top: 17px;
            padding-bottom: 24px;
            border-radius: 4px;
            border: solid 1px rgba(85, 57, 255, 0.05);
            background-color: rgba(85, 57, 255, 0.03);
            .tit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 24px;
                background: #fff;
                border-radius: 4px 4px 0 0;
                h4 {
                    overflow: hidden;
                    padding-right: 10px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 80px;
                    height: 34px;
                    border-radius: 2px;
                    background-color: rgba(85, 57, 255, 0.03);
                    color: $color-point;
                    font: {
                        size: 12px;
                        weight: bold;
                    }
                    &.ing {
                        background-color: rgba(85, 57, 255, 0.1);
                    }
                    &.rej {
                        background-color: rgba(13, 18, 64, 0.02);
                        color: rgba(13, 18, 64, 0.4);
                    }
                    &.ok {
                        background-color: #e0fe52;
                        color: $color-def;
                    }
                }
            }
            .profile {
                display: flex;
                align-items: center;
                padding: 16px 24px;
                .photo {
                    min-width: 64px;
                    height: 64px;
                    margin-right: 16px;
                    background: {
                        size: cover;
                    }
                }
                .info {
                    flex: 1;
                    width: calc(100% - 110px);
                    font-size: 14px;
                    .name {
                        display: flex;
                        flex-direction: column;
                        span {
                            margin-top: 4px;
                            color: rgba(13, 18, 64, 0.6);
                        }
                    }
                    p {
                        overflow: hidden;
                        width: 100%;
                        margin-top: 4px;
                        margin-right: auto;
                        white-space: nowrap;
                        color: rgba(13, 18, 64, 0.4);
                        text-overflow: ellipsis;
                    }
                }
                .btn-view {
                    margin-left: auto;
                    a {
                        display: block;
                        width: 32px;
                        height: 32px;
                        background: url('../images/icon_arrow02.svg') no-repeat 100% 0;
                        span {
                            @include hiddenText;
                        }
                        &.suj {
                            width: auto;
                            padding-right: 32px;
                            color: $color-point;
                            font: {
                                size: 12px;
                                weight: bold;
                            }
                            text-indent: -9999px;
                            line-height: 32px;
                        }
                    }
                }
            }
            .agr-info {
                position: relative;
                padding: 16px 24px;
                border-top: 1px solid rgba(85, 57, 255, 0.05);
                table {
                    font-size: 14px;
                    caption {
                        overflow: visible;
                        position: static;
                        font: {
                            size: 14px;
                            weight: bold;
                        }
                        color: rgba(13, 18, 64, 0.6);
                        text-indent: 0;
                        line-height: 22px;
                    }
                    th, td {
                        padding: 7px 0;
                        color: rgba(13, 18, 64, 0.6);
                    }
                    th  {
                        text-align: left;
                    }
                    td {
                        font-weight: bold;
                        text-align: right;
                    }
                }
                .btn-sug {
                    position: absolute;
                    top: 16px;
                    right: 24px;
                    a, button {
                        color: $color-point;
                        font-size: 12px;
                        text-decoration: underline;
                    }
                }
            }
            .desc-box {
                padding: 16px 24px;
                border-top: 1px solid rgba(85, 57, 255, 0.05);
                color: rgba(13, 18, 64, 0.4);
                font-size: 12px;
                line-height: 18px;
                .ans {
                    overflow: auto;
                    height: 88px;
                    margin: 8px 0 0;
                    padding: 10px 12px;
                    border-radius: 4px;
                    border: 1px solid rgba(13, 18, 64, 0.05);
                    box-sizing: border-box;
                    font-size: 14px;
                    line-height: 22px;
                }
                .desc {
                    margin-top: 8px;
                    padding: 10px 12px;
                    border-radius: 4px;
                    background-color: rgba(13, 18, 64, 0.02);
                    p {
                        color: rgba(13, 18, 64, 0.6);
                    }
                }
            }
            .in-btn {
                display: flex;
                margin-top: auto;
                padding: 0 24px;
                * {
                    margin: 0 4px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
                > a {
                    position: relative;
                    min-width: 48px;
                    width: 48px;
                    height: 48px;
                    border-radius: 2px;
                    background: rgba(85, 57, 255, 0.05) url('../images/icon_cmt.svg') no-repeat 50% 50%;
                    span {
                        @include hiddenText;
                    }
                    i {
                        display: block;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 6px;
                        height: 6px;
                        background: #ff4e16;
                        border-radius: 50%;
                    }
                }
                p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    height: 48px;
                    border-radius: 2px;
                    background-color: rgba(13, 18, 64, 0.05);
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 14px;
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        margin-right: 0;
                        background-color: #f2efff;
                        color: $color-point;
                        &:hover {
                            background-color: $color-point;
                            color: #fff;
                        }
                    }
                    &.drop {
                        max-width: 88px;
                        background: #f2efff;
                        color: $color-point;
                    }
                    &.actv {
                        position: relative;
                        background: $color-point;
                        color: #fff;
                        a {
                            color: #fff;
                            background: $color-point;
                        }
                        > span {
                            position: absolute;
                            top: 56px;
                            padding: 11px 24px;
                            background: #0d1240;
                            border-radius: 4px;
                            color: #fff;
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: -3px;
                                left: calc(50% - 5px);
                                width: 10px;
                                height: 10px;
                                background: #0d1240;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }
        }
        @include respond-to(pc) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .inbox {
                width: calc(50% - 12px);
                .profile {
                    .btn-view {
                        a.suj {
                            text-indent: 0;
                        }
                    }
                }
                .desc-box {
                    .ans {
                        height: 56px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .paging {
        margin-top: 65px;
    }
    @include respond-to(pc) {
        padding-top: 64px;
    }
}
.pjt-detail {
    max-width: 800px;
    margin: 40px auto 0;
    padding: 0 24px;
    .company-info {
        display: flex;
        align-items: center;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid rgba(85, 57, 255, 0.05);
        background-color: rgba(85, 57, 255, 0.03);
        .logo {
            min-width: 64px;
            height: 64px;
            background: {
                size: cover;
                repeat: no-repeat;
                position: 50% 50%;
            }
            border-radius: 50%;
        }
        strong {
            padding-left: 16px;
            font-size: 14px;
        }
        .btn-view {
            margin-left: auto;
            a {
                display: block;
                width: 32px;
                height: 32px;
                background: url('../images/icon_arrow02.svg') no-repeat 0 0;
                span {
                    @include hiddenText;
                }
            }
        }
    }
    .inner {
        .pjt-info {
            padding: 0;
            .agree-cnts {
                margin-top: 24px;
                line-height: 26px;
                > p {
                    color: rgba(13, 18, 64, 0.6);
                    em {
                        color: $color-point;
                    }
                }
                > div {
                    margin-top: 24px;
                    padding: 13px 20px;
                    border-radius: 2px;
                    background-color: rgba(85, 57, 255, 0.05);
                    color: $color-point;
                    text-align: center;
                }
            }
        }
        aside {
            margin-top: 40px;
            .send-message {
                padding: 16px;
                border-radius: 4px;
                background-color: rgba(13, 18, 64, 0.02);
                font-size: 14px;
                p {
                    color: rgba(13, 18, 64, 0.6);
                    line-height: 22px;
                }
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 48px;
                    margin-top: 10px;
                    border: 1px solid $color-point;
                    color: $color-point;
                    background: #fff;
                    &:before {
                        content: '';
                        width: 21px;
                        height: 20px;
                        margin-right: 10px;
                        background: url('../images/icon_cmt.svg') no-repeat 0 0;
                        background-size: cover;
                    }
                }
            }
            .condi-box {
                overflow: hidden;
                margin-top: 17px;
                border-radius: 4px;
                background-color: rgba(85, 57, 255, 0.03);
                h4 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 38px;
                    background-color: rgba(85, 57, 255, 0.03);
                    color: $color-point;
                    font-size: 14px;
                    &.ing {
                        background-color: rgba(85, 57, 255, 0.1);
                    }
                    &.reg {
                        background-color: rgba(13, 18, 64, 0.02);
                        color: rgba(13, 18, 64, 0.4);
                    }
                    &.ok {
                        background: #e0fe52;
                        color: $color-def;
                    }
                }
                .in-cnts {
                    padding: 16px 24px;
                    > strong {
                        display: block;
                        font-size: 16px;
                        text-align: center;
                    }
                    > p {
                        margin-top: 16px;
                        color: #000;
                        font-size: 14px;
                        line-height: 22px;
                        em {
                            font-weight: bold;
                        }
                    }
                    ul.chk-li {
                        li {
                            margin-top: 16px;
                            label {
                                color: rgba(13, 18, 64, 0.6);
                                font-size: 14px;
                                line-height: 22px;
                                em {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    ul.s-list {
                        font-size: 14px;
                        li {
                            margin-top: 16px;
                            i {
                                font-weight: bold;
                            }
                            p {
                                margin-top: 10px;
                                color: rgba(13, 18, 64, 0.6);
                            }
                        }
                    }
                    .desc {
                        margin-top: 16px;
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 14px;
                        line-height: 22px;
                        em {
                            font-weight: bold;
                        }
                    }
                    .in-btn {
                        display: flex;
                        margin-top: 16px;
                        a, button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: auto;
                            height: 48px;
                            margin: 0 4px;
                            background: $color-point;
                            border-radius: 2px;
                            color: #fff;
                            &:first-child {
                                margin-left: 0;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                            &:disabled {
                                background-color: rgba(13, 18, 64, 0.05);
                                color: rgba(13, 18, 64, 0.4);
                            }
                            &.def {
                                max-width: 100px;
                                background-color: rgba(85, 57, 255, 0.05);
                                color: $color-point;
                            }
                        }
                    }
                }
            }
        }
    }
    @include respond-to(pc) {
        .inner {
            display: flex;
            align-items: flex-start;
            position: relative;
            padding-bottom: 50px;
            .pjt-info {
                flex: 1;
            }
            aside {
                position: sticky;
                top: 100px;
                min-width: 292px;
                max-width: 292px;
                margin-left: auto;
                padding-left: 40px;
                .btn-sticky {
                    margin-top: 16px;
                }
            }
        }
    }
}
//message
.message-wrap {
    height: 100%;
    position: relative;
    top: 74px;
    //margin-top: 74px;
    background-color: rgba(85, 57, 255, 0.03);
    .prog {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 64px;
        height: 34px;
        background-color: rgba(85, 57, 255, 0.03);
        border-radius: 2px;
        color: #5539ff;
        font: {
            size: 12px;
            weight: bold;
        }
        &.ing {
            background-color: rgba(85, 57, 255, 0.1);
        }
        &.ok {
            background-color: #e0fe52;
            color: $color-def;
        }
        &.rej {
            background-color: rgba(13, 18, 64, 0.07);
            color: #000;
        }
    }
    .per-list {
        display: flex;
        flex-direction: column;
        .tit {
            padding: 16px 28px;
            background: #fff;
            border-bottom: 1px solid rgba(85, 57, 255, 0.05);
            h2 {
                font: {
                    size: 22px;
                    weight: normal;
                }
            }
            > p {
                margin-top: 8px;
                color: rgba(13, 18, 64, 0.4);
                font-size: 14px;
            }
        }
        > ul {
            overflow: auto;
            height: calc(100% - 182px);
            padding: 0 24px;
            background: #fff;
            li {
                position: relative;
                padding: 16px 0;
                border-bottom: 1px solid rgba(85, 57, 255, 0.05);
                a {
                    display: block;
                    .new {
                        display: block;
                        position: absolute;
                        bottom: 31px;
                        right: 0;
                        width: 8px;
                        height: 8px;
                        margin-top: 15px;
                        background: #ff4e16;
                        border-radius: 50%;
                        span {
                            @include hiddenText;
                        }
                    }
                    > em {
                        color: rgba(13, 18, 64, 0.4);
                        font: {
                            weight: bold;
                            size: 12px;
                        }
                    }
                    > div {
                        display: flex;
                        align-items: center;
                        margin-top: 12px;
                        .photo {
                            min-width: 40px;
                            height: 40px;
                            background-size: cover;
                            border-radius: 50%;
                        }
                        .info {
                            padding-left: 12px;
                            strong {
                                font-size: 14px;
                            }
                            p {
                                color: rgba(13, 18, 64, 0.4);
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                    .prog {
                        position: absolute;
                        right: 24px;
                        bottom: 19px;
                    }
                    .time {
                        position: absolute;
                        top: 16px;
                        right: 0;
                        color: rgba(13, 18, 64, 0.4);
                        font-size: 12px;
                    }
                }
                &.reject {
                    opacity: 0.3;
                    a {
                        color: rgba(13, 18, 64, 0.5);
                    }
                }
            }
        }
        .msg-guide {
            padding: 16px;
            background-color: rgba(13, 18, 64, 0.02);
            color: rgba(13, 18, 64, 0.6);
            h4 {
                font: {
                    size: 11px;
                    weight: normal;
                }
                text-align: center;
            }
            ul {
                padding-top: 8px;
                font-size: 9px;
                line-height: 16px;
                li {
                    margin-top: 4px;
                }
            }
        }
    }
    .mesg-box {
        width: 100vw;
        min-height: calc(100vh - 185px);
        .pjt-tit {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 48px;
            padding: 8px 24px 8px 8px;
            background: #fff;
            box-shadow: 0 1px 0 0 rgba(85, 57, 255, 0.05);
            box-sizing: border-box;
            .btn-prev {
                margin-right: 8px;
                a {
                    display: block;
                    width: 32px;
                    height: 32px;
                    background: url('../images/icon_arrow_back_def.svg') no-repeat 0 0;
                    span {
                        @include hiddenText;
                    }
                }
            }
            h3 {
                overflow: hidden;
                white-space: nowrap;
                margin-left: 16px;
                font-size: 14px;
                text-overflow: ellipsis;
            }
            a {
                margin-left: auto;
                padding-left: 8px;
                color: #5539ff;
                font-size: 14px;
                text-decoration: underline;
                white-space: nowrap;
            }
            @include respond-to(pc) {
                .btn-prev {
                    display: none;
                }
            }
        }
        .no-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: calc(100vh - 190px);
            &:before {
                content: '';
                width: 120px;
                height: 120px;
                background: url('../images/img_nolist.png') no-repeat 0 0;
                background-size: cover;
            }
            p {
                margin-top: 16px;
                color: rgba(13, 18, 64, 0.4);
                font-size: 16px;
            }
        }
    }
    &.sub-w {
        padding: 0;
        .per-list {
            display: none;
        }
        .mesg-box {
            .hgroup {
                position: relative;
                min-height: 48px;
                padding: 11px 0;
                background: $color-point;
                text-align: center;
                box-sizing: border-box;
                .btn-prev {
                    position: absolute;
                    top: 4px;
                    left: 24px;
                }
                h3 {
                    overflow: hidden;
                    padding: 0 40px 0 70px;
                    color: #fff;
                    font: {
                        size: 16px;
                        weight: normal;
                    }
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .mesg-cnts {
                position: relative;
                height: calc(100vh - 74px);
                padding: 66px 24px 105px;
                box-sizing: border-box;
                .inner {
                    overflow: auto;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    .in-mesg {
                        display: flex;
                        margin-top: 16px;
                        .photo {
                            min-width: 32px;
                            height: 32px;
                            margin: 8px 8px 0 0;
                            border-radius: 50%;
                            background-size: cover;
                        }
                        > div {
                            flex: 1;
                            position: relative;
                            padding: 16px;
                            background-color: rgba(85, 57, 255, 0.05);
                            font-size: 14px;
                            line-height: 22px;
                            border-radius: 4px;
                            em {
                                font-weight: bold;
                            }
                            p {
                                margin-top: 8px;
                            }
                            .time {
                                position: absolute;
                                top: 16px;
                                right: 16px;
                                color: rgba(13, 18, 64, 0.4);
                            }
                        }
                        &:first-child {
                            margin-top: 0;
                        }
                        &.comp {
                            > div {
                                background: $color-point;
                                color: #fff;
                                -webkit-font-smoothing: antialiased;
                                a {
                                    color: #fff;
                                }
                                .time {
                                    color: rgba(255, 255, 255, 0.6);
                                }
                            }
                        }
                    }
                }
                .no-data {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    &:before {
                        content: '';
                        width: 120px;
                        height: 120px;
                        background: url('../images/img_nomesg.png') no-repeat 0 0;
                        background-size: cover;
                    }
                    p {
                        margin-top: 16px;
                        color: rgba(13, 18, 64, 0.4);
                        font-size: 16px;
                    }
                    &.start {
                        &:before {
                            background-image: url('../images/img_start.png');
                        }
                    }
                }
                fieldset {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 24px;
                    width: calc(100% - 48px);
                    height: 70px;
                    padding: 8px 16px;
                    border-radius: 4px;
                    border: 1px solid rgba(13, 18, 64, 0.05);
                    background-color: #fff;
                    box-sizing: border-box;
                    textarea {
                        height: 100%;
                        padding: 0;
                        background: none;
                        color: $color-def;
                        font-size: 14px;
                        line-height: 22px;
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 58px;
                        height: 38px;
                        margin-left: 8px;
                        background: $color-point;
                        border-radius: 2px;
                        color: #fff;
                    }
                }
            }
            .hgroup + .mesg-cnts {
                height: calc(100vh - 128px);
            }
        }
    }
    @include respond-to(pc) {
        overflow: hidden;
        display: flex;
        top: 96px;
        max-width: 800px;
        height: calc(100vh - 120px);
        margin: 0 auto 24px;
        border-radius: 4px;
        border: 1px solid rgba(13, 18, 64, 0.1);
        box-sizing: border-box;
        .per-list {
            min-width: 299px;
            background: #fff;
        }
        .mesg-box {
            border-left: 1px solid rgba(85, 57, 255, 0.05);
        }
        &.sub-w {
            width: 100%;
            .per-list {
                display: flex;
                width: 299px;
                .tit {
                    padding: 24px;
                }
                > ul {
                    overflow: auto;
                    //height: calc(100% - 124px);
                    margin-top: 0;
                    padding: 0;
                    li {
                        a {
                            padding: 0 24px;
                            .new {
                                right: 8px;
                            }
                            .time {
                                right: 24px;
                            }
                        }
                        &.selected {
                            background-color: rgba(85, 57, 255, 0.03);
                        }
                    }
                }
            }
            .mesg-box {
                flex: 1;
                min-height: auto;
                .hgroup {
                    display: none;
                }
                .mesg-cnts {
                    position: relative;
                    height: 100%;
                }
            }
        }
    }
}
//pay
.pay-box {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    background-color: rgba(13, 18, 64, 0.02);
    > div {
        width: 50%;
        padding: 16px 24px;
        color: rgba(13, 18, 64, 0.6);
        box-sizing: border-box;
        i {
            display: block;
            margin-bottom: 4px;
            font-size: 12px;
        }
        em {
            font-size: 16px;
        }
    }
    > button {
        width: calc(100% - 48px);
        margin: 16px 24px;
        padding: 13px 24px;
        background: $color-point;
        border-radius: 2px;
        color: #fff;
        text-align: center;
    }
    > p {
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        text-align: center;
        a {
            color: $color-point;
            text-decoration: underline;
        }
    }
    @include respond-to(pc) {
        align-items: center;
        flex-wrap: nowrap;
        height: 82px;
        > div {
            width: 200px;
        }
        > button, > p {
            width: auto;
            margin-left: auto;
        }
        > p {
            padding-right: 24px;
        }
    }
}
.pay-wrap {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 24px;
    .cnts-hgroup {
        margin-top: 24px;
        @include respond-to(pc) {
            margin-top: 32px;
        }
    }
    .pay-list {
        position: relative;
        //margin-top: 9px;
        //padding-top: 15px;
        .viewport {
            overflow: hidden;
            overflow-x: auto;
            width: 100vw;
            margin-left: -24px;
            padding-left: 24px;
            box-sizing: border-box;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none; /* Chrome, Safari, Opera*/
            }
        }
        table {
            width: 800px;
            margin-right: 24px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 14px;
            thead {
                //@include hiddenText;
                //position: absolute;
                th {
                    padding: 11px 0;
                    color: $color-def;
                    font-size: 12px;
                }
            }
            tbody {
                td {
                    padding: 19px 16px 15px;
                    background: rgba(13, 18, 64, 0.02);
                    border-bottom: 1px solid rgba(13, 18, 64, 0.05);
                    color: rgba(13, 18, 64, 0.6);
                    .sbj {
                        strong {
                            overflow: hidden;
                            display: block;
                            width: 152px;
                            font-weight: normal;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 12px;
                        }
                        span {
                            display: block;
                            margin-top: 4px;
                            color: $color-def;
                        }
                    }
                    .profile {
                        display: flex;
                        align-items: center;
                        .thumb {
                            min-width: 24px;
                            height: 24px;
                            background: {
                                size: cover;
                            }
                            border-radius: 50%;
                        }
                        .info {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding-left: 10px;
                            em {
                                overflow: hidden;
                                width: 100px;
                                color: $color-def;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            span {
                                margin-top: 4px;
                                font-size: 12px;
                            }
                        }
                    }
                    > em {
                        color: $color-point;
                    }
                    &.cate {
                        text-align: center;
                        .condi {
                            display: inline-block;
                            min-width: 55px;
                            padding: 8px 10px;
                            border-radius: 2px;
                            background-color: rgba(13, 18, 64, 0.02);
                            box-sizing: border-box;
                        }
                    }
                    &.td-price {
                        color: $color-def;
                        text-align: right;
                    }
                    &:first-child {
                        padding: {
                            left: 24px;
                            right: 24px;
                        }
                    }
                    &:nth-child(2) {
                        padding: 19px 0 15px;
                    }
                }
                tr.ing {
                    td {
                        background: rgba(85, 57, 255, 0.03);
                        &.cate {
                            .condi {
                                color: $color-point;
                                background-color: rgba(85, 57, 255, 0.03);
                            }
                        }
                    }
                }
            }
        }
        /* .scroll {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 110px;
            height: 6px;
            border-radius: 3px;
            background-color: rgba(85, 57, 255, 0.4);
        } */
        @include respond-to(pc) {
            //margin-top: 32px;
            //padding-top: 0;
            /* .scroll {
                display: none;
            } */
            .viewport {
                width: auto;
                margin: 0;
                padding: 0;
            }
        }
    }
    .no-data {
        margin-top: 24px;
        @include respond-to(pc) {
            margin-top: 64px;
        }
    }
}
.pjt-adm-detail {
    margin: 24px auto 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(13, 18, 64, 0.05);
    .pjt-def-info {
        font-size: 16px;
        text-align: center;
        line-height: 26px;
        &:before {
            content: '';
            display: block;
            width: 160px;
            height: 160px;
            margin: 0 auto;
            background: url('../images/img_pjt_detail.png') no-repeat 0 0;
            background-size: cover;
        }
        strong {
            display: block;
            margin-top: 24px;
        }
        > a {
            display: block;
            height: 48px;
            margin-top: 24px;
            background: $color-point;
            color: #fff;
            border-radius: 2px;
            font-size: 14px;
            line-height: 48px;
        }
        &.type2 {
            &:before {
                background-image: url('../images/img_pjt_detail02.png');
            }
        }
    }
    .rec-info {
        margin-top: 24px;
        padding: 24px;
        background: rgba(13, 18, 64, 0.02);
        border-radius: 4px;
        font-size: 14px;
        line-height: 22px;
        color: rgba(13, 18, 64, 0.4);
        ul {
            li {
                position: relative;
                padding-left: 22px;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    width: 4px;
                    height: 4px;
                    background: rgba(13, 18, 64, 0.4);
                    border-radius: 50%;
                }
            }
        }
    }
    @include respond-to(pc) {
        padding: {
            left: 100px;
            right: 100px;
        }
    }
}
//poll
.poll-wrap {
    max-width: 500px;
    margin: 0 auto;
    padding: 110px 24px 120px;
    input[type='radio'] + label,
    input[type='checkbox'] + label {
        padding-left: 35px;
        color: $color-def;
        font-size: 16px;
        line-height: 24px;
        &:before {
            width: 22px;
            height: 22px;
            border: {
                width: 2px;
                color: $color-point;
            }
        }
    }
    input[type='radio'] {
        &:checked + label {
            &:after {
                top: 4px;
                left: 4px;
                width: 14px;
                height: 14px;
            }
        }
    }
    .p-step {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 240px;
        margin: 0 auto;
        font-size: 12px;
        strong, span {
            width: 36px;
            position: relative;
            text-align: center;
            &:after {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                margin: 4px auto 0;
                border-radius: 50%;
                background: #e7e7ec;
                box-sizing: border-box;
            }
            &.check {
                color: $color-point;
                &:after {
                    background: $color-point;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 9px;
                    left: 15px;
                    z-index: 2;
                    width: 6px;
                    height: 3px;
                    border: {
                        width: 0 0 1px 1px;
                        style: solid;
                        color: #fff;
                    }
                    transform: rotate(-45deg);
                }
            }
        }
        strong {
            color: $color-point;
            font-weight: normal;
            &:after {
                border: 2px solid $color-point;
                background: #fff;
            }
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            left: calc(50% - 111px);
            bottom: 7px;
            width: 223px;
            height: 6px;
            background: #e7e7ec;
        }
    }
    h2 {
        margin-top: 30px;
        font: {
            size: 28px;
            weight: normal;
        }
        text-align: center;
    }
    .cnts {
        padding-top: 15px;
        line-height: 26px;
        p {
            margin-top: 15px;
            em {
                color: $color-point;
            }
        }
    }
    .noti-box {
        margin-top: 30px;
        padding: 16px 14px;
        background-color: rgba(13, 18, 64, 0.02);
        font-size: 14px;
        line-height: 22px;
    }
    .ques-li {
        position: relative;
        margin-top: 30px;
        line-height: 26px;
        &:before {
            content: '';
            display: block;
            width: 100px;
            height: 1px;
            margin: 0 auto;
            background-color: rgba(13, 18, 64, 0.1);
        }
        ul {
            margin-top: 40px;
            li {
                margin-top: 25px;
                > p {
                    font-weight: bold;
                }
                .ans {
                    display: flex;
                    flex-direction: column;
                    padding-top: 4px;
                    > span {
                        width: auto;
                        margin-top: 4px;
                        label {
                            > span {
                                padding-left: 3px;
                                color: rgba(13, 18, 64, 0.4);
                            }
                        }
                    }
                    &.type2 {
                        flex-direction: column;
                        > span {
                            margin-top: 25px;
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-input {
        position: relative;
        margin-top: 30px;
        padding-top: 20px;
        line-height: 26px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: calc(50% - 50px);
            width: 100px;
            height: 1px;
            margin: 0 auto;
            background-color: rgba(13, 18, 64, 0.1);
        }
        h3 {
            display: flex;
            align-items: center;
            color: $color-point;
            font-size: 12px;
            font-weight: normal;
            &:before {
                content: '';
                display: block;
                width: 4px;
                height: 8px;
                margin-right: 5px;
                background: $color-point;
                border-radius: 8px;
                transform: rotate(45deg);
            }
        }
        .desc {
            font-size: 14px;
        }
        .fm-w {
            margin-top: 30px;
            .tit {
                display: block;
                padding-bottom: 8px;
                font-weight: bold;
            }
            .sel-w {
                display: flex;
                margin-top: 20px;
                .select-w, .input-w,
                > select {
                    width: 50%;
                    &:first-child {
                        margin-right: 10px;
                    }
                }
                select {
                    flex: 1;
                    color: rgba(13, 18, 64, 0.4);
                    font-size: 16px;
                }
                input {
                    &:disabled {
                        background-color: rgba(13, 18, 64, 0.02);
                    }
                    &.actv {
                        color: $color-point;
                    }
                }
                .select-w {
                    position: relative;
                    button {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 56px;
                        padding: 0 15px;
                        border-radius: 2px;
                        background: rgba(85, 57, 255, 0.03) url('../images/icon_arrow.svg') no-repeat 100% 50%;
                        color: rgba(13, 18, 64, 0.4);
                        box-sizing: border-box;
                        font-size: 16px;
                        &.actv {
                            color: $color-def;
                        }
                    }
                    .option-li {
                        display: none;
                        position: absolute;
                        z-index: 5;
                        top: 56px;
                        width: 100%;
                        padding: 8px 16px;
                        border: 1px solid $color-point;
                        background: #fff;
                        box-sizing: border-box;
                        i {
                           padding: 7px 0;
                           color: rgba(13, 18, 64, 0.4);
                           font-size: 12px;
                        }
                        ul {
                            li {
                                padding: 4px 0;
                                font-size: 14px;
                                &.selected {
                                    color: $color-point;
                                }
                                &.etc {
                                    margin-top: 7px;
                                    padding-top: 12px;
                                    border-top: 1px solid rgba(13, 18, 64, 0.1);
                                }
                            }
                        }
                    }
                    &.selected {
                        .option-li {
                            display: block;
                        }
                    }
                }
                .input-w {
                    position: relative;
                    .recmd-li {
                        display: none;
                        overflow: auto;
                        position: absolute;
                        top: 43px;
                        left: 16px;
                        width: 100%;
                        height: 120px;
                        max-width: 200px;
                        padding: 8px;
                        border-radius: 2px;
                        box-shadow: 4px 8px 16px 0 rgba(13, 18, 64, 0.15);
                        background-color: #fff;
                        color: rgba(13, 18, 64, 0.6);
                        font-size: 14px;
                        box-sizing: border-box;
                        ul {
                            li {
                                padding: 5px 8px;
                                line-height: 140%;
                                &.selected {
                                    border-radius: 2px;
                                    background-color: rgba(85, 57, 255, 0.03);
                                }
                            }
                        }
                    }
                    .btn-del {
                        &:before,
                        &:after {
                            background: $color-point;
                        }
                    }
                    &.selected {
                        .recmd-li {
                            display: block;
                        }
                    }
                }
            }
            label + .sel-w {
                margin-top: 0;
            }
            .chk-li {
                display: flex;
                flex-direction: column;
                line-height: 22px;
                span {
                    margin-top: 35px;
                    input[type='checkbox'] + label {
                        &:before {
                            top: 0;
                        }
                    }
                    input[type='checkbox'] {
                        &:checked + label {
                            &:after {
                                left: 7px;
                            }
                        }
                    }
                    &:first-child {
                        margin-top: 6px;
                    }
                }
            }
            .chk-w {
                .inner {
                    display: flex;
                    margin-top: 10px;
                    > label {
                        flex: 1;
                        span {
                            @include hiddenText;
                        }
                        &:before {
                            top: 18px;
                        }
                        &.type-h {
                            max-width: 0;
                        }
                    }
                    input[type='radio'] {
                        &:checked + label {
                            &:after {
                                top: 22px;
                            }
                        }
                    }
                    .file-w {
                        flex: 1;
                        position: relative;
                        label {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 6px;
                            right: 10px;
                            width: 107px;
                            height: 48px;
                            border-radius: 2px;
                            background-color: rgba(13, 18, 64, 0.05);
                            color: rgba(13, 18, 64, 0.6);
                        }
                    }
                }
                > p {
                    margin-top: 8px;
                    color: rgba(13, 18, 64, 0.6);
                    font-size: 12px;
                    text-align: right;
                }
            }
            .link {
                margin-top: 15px;
                text-align: right;
                a {
                    color: $color-point;
                    text-decoration: underline;
                }
            }
            textarea {
                height: 142px;
            }
            .sel-form {
                .opt-li {
                    height: 120px;
                }
            }
            .comp-intu + .sel-form {
                margin-top: 20px;
            }
            .int-form {
                .file-up {
                    background-color: rgba(13, 18, 64, 0.05);
                    color: rgba(13, 18, 64, 0.6);
                }
            }
            .f-desc {
                margin-top: 8px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 12px;
                text-align: right;
                &:before {
                    content: '*';
                }
            }
        }
        .ques-li {
            &:before {
                display: none;
            }
        }
    }
    .btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 24px 50px;
        box-sizing: border-box;
        background: #fff;
        .sub {
            width: 93px;
        }
    }
    &.end {
        padding-top: 135px;
        &:before {
            content: '';
            display: block;
            width: 253px;
            height: 160px;
            margin: 0 auto;
            background: url('../images/poll_end.png') no-repeat 50% 50%;
            background-size: contain;
        }
    }
    @include respond-to(pc) {
        input[type='radio'] + label,
        input[type='checkbox'] + label {
            padding-left: 25px;
            font-size: 14px;
            line-height: 18px;
            &:before {
                width: 16px;
                height: 16px;
                border-width: 1px;
            }
        }
        input[type='radio'] {
            &:checked + label {
                &:after {
                    top: 3px;
                    left: 3px;
                    width: 10px;
                    height: 10px;
                }
            }
        }
        .p-step {
            width: 210px;
            strong, span {
                &:after {
                    width: 16px;
                    height: 16px;
                }
                &.check {
                    &:before {
                        bottom: 7px;
                        left: 14px;
                    }
                }
            }
            &:after {
                left: calc(50% - 80px);
                bottom: 6px;
                width: 160px;
                height: 4px;
            }
        }
        h2 {
            margin-top: 40px;
            font-size: 22px;
            br {
                display: none;
            }
        }
        .cnts {
            line-height: 22px;
            &.type2 {
                p ~ p {
                    margin-top: 0;
                }
            }
        }
        .noti-box {
            font-size: 12px;
            line-height: 18px;
        }
        .ques-li {
            line-height: 22px;
        }
        .portfolio-input {
            .fm-w {
                .sel-w {
                    select, .select-w button {
                        font-size: 14px;
                    }
                }
                .chk-li {
                    line-height: 18px;
                    span {
                        margin-top: 13px;
                        input[type='checkbox'] {
                            &:checked + label {
                                &:after {
                                    top: 3px;
                                    left: 4px;
                                }
                            }
                        }
                    }
                }
                textarea {
                    padding: 17px 16px;
                    font-size: 14px;
                }
                .chk-w {
                    .inner {
                        input[type='radio'] {
                            &:checked + label {
                                &:after {
                                    top: 21px;
                                }
                            }
                        }
                        .file-w {
                            label {
                                top: 8px;
                                width: 92px;
                                height: 40px;
                            }
                        }
                    }
                }
            }
        }
        .btn {
            position: static;
            width: 300px;
            margin: 40px auto 0;
            padding: 0;
            a.sub {
                min-width: 190px;
            }
            &.type2 {
                width: 100%;
            }
        }
        &.end {
            padding-top: 155px;
            &:before {
                width: 299px;
                height: 188px;
            }
            .cnts {
                p ~ p {
                    margin-top: 0;
                }
            }
        }
    }
}
.recommend-w {
    padding-bottom: 28px;
    color: $landing-def;
    font-family: $font-landing;
    -webkit-font-smoothing: antialiased;
    h3, a {
        color: $landing-def;
    }
    strong {
        font-weight: 700;
    }
    @include respond-to(pc) {
        padding-bottom: 105px;
    }
    .top-v {
        padding: 90px 30px 40px;
        background: #F5EBFF;
        .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 1024px;
            margin: 0 auto;
            text-align: center;
            &:before {
                content: '';
                display: block;
                width: 148px;
                height: 148px;
                background: url('../images/recomd_img.png') no-repeat 50% 50%;
                background-size: contain;
            }
            p {
                font: {
                    size: 20px;
                    weight: 700;
                }
                line-height: 28px;
                br {
                    display: none;
                }
            }
            a {
                @include landingBtn;
                margin-top: 20px;
            }
        }
        @include respond-to(pc) {
            padding: {
                top: 130px;
                bottom: 70px;
            }
            .inner {
                position: relative;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;
                &:before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 216px;
                    height: 216px; 
                }
                p {
                    font-size: 28px;
                    line-height: 40px;
                    br {
                        display: inline;
                    }
                }
            }
        }
    }
    .progrs {
        padding: 30px 30px 0;
        h3 {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            line-height: 28px;
            &:before {
                content: '✍';
                display: block;
                margin-bottom: 3px;
                font-size: 36px;
            }
        }
        ul {
            padding-top: 10px;
            li {
                margin-top: 30px;
                strong {
                    font-weight: 700;
                }
                p {
                    margin-top: 12px;
                    color: #494949;
                    font-size: 14px;
                    line-height: 24px;
                    em {
                        font-weight: 700;
                    }
                }
            }
        }
        @include respond-to(pc) {
            padding-top: 60px;
            .inner {
                display: flex;
                max-width: 1024px;
                margin: 0 auto;
                h3 {
                    width: 360px;
                    font-size: 28px;
                    line-height: 40px;
                    &:before {
                        font-size: 48px;
                        margin-bottom: 8px;
                    }
                }
                ul {
                    flex: 1;
                    max-width: 560px;
                    li {
                        margin-top: 40px;
                        strong {
                            font-size: 18px;
                        }
                        p {
                            margin-top: 12px;
                            font-size: 16px;
                            line-height: 28px;
                        }
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    .faq-w {
        padding: 60px 30px 0;
        h3 {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            line-height: 28px;
            &:before {
                content: '\1F4C3';
                display: block;
                margin-bottom: 3px;
                font-size: 36px;
            }
        }
        ul {
            padding-top: 28px;
            font-size: 14px;
            li {
                margin-top: 12px;
                padding: 16px 24px;
                border: 1px solid #E2E3E6;
                border-radius: 20px;
                > a {
                    display: block;
                    position: relative;
                    padding: 10px 30px 10px 0;
                    font-weight: 700;
                    line-height: 20px;
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 12px;
                        right: 0;
                        width: 9px;
                        height: 9px;
                        border: {
                            width: 0 0 3px 3px;
                            style: solid;
                            color: #323232;
                            radius: 2px;
                        }
                        box-sizing: border-box;
                        transform: rotate(-45deg);
                    }
                }
                > div {
                    display: none;
                    margin-top: 2px;
                    color: #494949;
                    line-height: 24px;
                }
                &.selected {
                    > a {
                        &:after {
                            top: 17px;
                            transform: rotate(135deg);
                        }
                    }
                    > div {
                        display: block;
                    }
                }
            }
        }
        @include respond-to(pc) {
            padding-top: 130px;
            .inner {
                display: flex;
                max-width: 1024px;
                margin: 0 auto;
                h3 {
                    width: 360px;
                    font-size: 28px;
                    line-height: 40px;
                    &:before {
                        font-size: 48px;
                        margin-bottom: 8px;
                    }
                }
                ul {
                    flex: 1;
                    padding-top: 0;
                    font-size: 16px;
                    li {
                        margin-top: 16px;
                        padding: 19px 32px;
                        div {
                            line-height: 28px;
                        }
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}