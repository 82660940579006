.partner-input {
    padding: 144px 24px 60px;
    background: #f9fdff;
    .txt-box {
        text-align: center;
        &:before {
            content: '';
            display: block;
            width: calc(100% + 48px);
            height: 473px;
            margin-left: -24px;
            background: url('../images/main_n01.png') no-repeat 50% 50%;
            background-size: auto 100%;
        }
        p {
            margin-top: 32px;
            font-size: 28px;
            line-height: 42px;
            em {
                font-weight: bold;
            }
            span {
                position: relative;
                white-space: nowrap;
            }
        }
        .in-btn {
            margin-top: 32px;
            a {
                display: block;
                max-width: 400px;
                height: 52px;
                margin: 0 auto;
                background: $color-point;
                color: #fff;
                font-size: 16px;
                line-height: 52px;
                border-radius: 2px;
            }
        }
    }
    @include respond-to(pcMain) {
        .inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            width: 100%;
            max-width: 1040px;
            min-height: 540px;
            margin: 0 auto;
        }
        .txt-box {
            //position: relative;
            width: 50%;
            max-width: 360px;
            //padding-right: 120px;
            box-sizing: border-box;
            text-align: left;
            &:before {
                position: absolute;
                top: 0;
                right: 0;
                width: 540px;
                height: 540px;
            }
            p {
                font-size: 32px;
                line-height: 48px;
            }
            .in-btn {
                text-align: center;
            }
        }
    }
}
.for-company, 
.for-talent {
    padding: 65px 24px 75px;
    background: #5539ff;
    color: #fff;
    .inner {
        position: relative;
        max-width: 1040px;
        margin: 0 auto;
        .hgroup {
            text-align: center;
            h2 {
                color: #fff;
                font: {
                    weight: normal;
                    size: 22px;
                }
            }
            p {
                margin-top: 17px;
                font: {
                    size: 28px;
                    weight: bold;
                }
                line-height: 42px;
            }
            &:after {
                content: '';
                display: block;
                width: 327px;
                height: 327px;
                margin: 16px auto 0;
                background: url('../images/main_n0201.png') no-repeat 50% 50%;
                background-size: cover;
            }
        }
        ul {
            margin-top: 113px;
            li {
                margin-top: 16px;
                padding: 24px;
                border-radius: 4px;
                background-color: rgba(255, 255, 255, 0.1);
                line-height: 30px;
                i {
                    color: #e0fe52;
                    font-size: 16px;
                }
                p {
                    margin-top: 16px;
                    font-size: 20px;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
    @include respond-to(pcMain) {
        .inner {
            display: flex;
            .hgroup {
                text-align: left;
                p {
                    margin-top: 24px;
                    font-size: 32px;
                    line-height: 48px;
                }
                &:after {
                    width: 400px;
                    height: 400px;
                    margin-top: 40px;
                }
            }
            ul {
                width: 51%;
                max-width: 520px;
                margin-left: auto;
                li {
                    padding: {
                        left: 40px;
                        right: 40px;
                    }
                }
            }
        }
    }
}
.for-talent {
    background: #0d1240;
    .inner {
        .hgroup {
            &:after {
                background-image: url('../images/main_n0202.png');
            }
        }
    }
}
.recruit-w {
    padding: 93px 24px 64px;
    background: #f9fdff;
    text-align: center;
    p {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: 180px;
        font: {
            size: 22px;
            weight: bold;
        }
        line-height: 34px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 190px;
            height: 90px;
            background-image: linear-gradient(to left, #e0fe52, rgba(224, 254, 82, 0));
            border-radius: 80px;
            transform: rotate(-45deg);
        }
        span {
            z-index: 2;
        }
        br {
            display: none;
        }
    }
    table {
        margin-top: 16px;
        font-weight: bold;
        th {
            position: relative;
            width: 50%;
            height: 74px;
            border-bottom: 1px solid $color-point;
            color: $color-point;
            font-weight: bold;
            &:first-child {
                color: rgba(85, 57, 255, 0.4);
            }
        }
        td {
            padding: 24px 0;
            font-size: 14px;
            border-bottom: 1px solid rgba(85, 57, 255, 0.1);
            &:first-child {
                color: rgba(13, 18, 64, 0.4);
            }
        }
        th, td {
            &:first-child {
                font-weight: normal;
            }
        }
    }
    .in-btn {
        max-width: 512px;
        margin: 0 auto;
        padding-top: 30px;
        a {
            display: block;
            margin-top: 16px;
            padding: 19px 0;
            border-radius: 2px;
            background-color: rgba(85, 57, 255, 0.03);
            color: $color-point;
            em {
                font-weight: bold;
            }
            &.b-comp {
                background: $color-point;
                color: #fff;
            }
        }
    }
    @include respond-to(pcMain) {
        padding: {
            top: 120px;
            bottom: 120px;
        }
        p {
            height: 250px;
            font-size: 32px;
            line-height: 48px;
            &:before {
                width: 300px;
                height: 150px;
            }
            br {
                display: inline;
            }
        }
        table {
            max-width: 1040px;
            margin: 64px auto 0;
            font-size: 22px;
            th {
                height: 82px;
                &:last-child {
                    background-size: 90px auto;
                }
            }
            td {
                font-size: 22px;
            }
        }
        .in-btn {
            padding-top: 48px;
            a {
                font-size: 22px;
            }
        }
    }
}
.about-seeso {
    padding: 64px 24px;
    color: #fff;
    background: #5539ff;
    text-align: center;
    line-height: 26px;
    .inner {
        position: relative;
        max-width: 1040px;
        margin: 0 auto;
        font-size: 16px;
        h2 {
            margin-top: 16px;
            color: #fff;
            font-size: 28px;
            &:first-child {
                margin-top: 0;
            }
        }
        p {
            margin-top: 16px;
        }
        ul {
            margin-top: 16px;
            &:after {
                content: '';
                display: block;
                width: 327px;
                height: 327px;
                margin: 16px auto 0;
                background: url('../images/main_n0301.png') no-repeat 50% 50%;
                background-size: cover;
            }
        }
        .in-btn {
            margin-top: 16px;
            a {
                display: block;
                padding: 13px 0;
                background: #fff;
                color: $color-point;
                text-align: center;
            }
        }
    }
    @include respond-to(pcMain) {
        padding: {
            top: 120px;
            bottom: 120px;
        }
        text-align: left;
        .inner {
            display: flex;
            box-sizing: border-box;
            .txt-box {
                width: 400px;
            }
            h2 {
                margin-top: 40px;
                font-size: 32px;
                line-height: 48px;
            }
            ul {
                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 440px;
                    height: 440px;
                    margin: 0;
                }
            }
            .in-btn {
                margin-top: 40px;
            }
        }
    }
}
//how it work
.steam-def {
    padding: 104px 24px 32px;
    text-align: center;
    background: #f9fdff;
    strong {
        font-size: 28px;
        line-height: 42px;
        br {
            display: none;
        }
    }
    p {
        margin-top: 24px;
        color: rgba(13, 18, 64, 0.6);
        font-size: 16px;
    }
    @include respond-to(pcMain) {
        padding: {
            top: 136px;
            bottom: 80px;
        }
        strong {
            font-size: 32px;
            line-height: 48px;
            br {
                display: inline;
            }
        }
    } 
}
.how-tab {
    padding: 0 24px;
    background: #f9fdff;
    ul {
        display: flex;
        justify-content: center;
        li {
            width: calc(50% - 3px);
            max-width: 200px;
            margin: 0 3px;
            text-align: center;
            a {
                display: block;
                position: relative;
                padding: 19px 0;
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                &:after {
                    content: '';
                    display: none;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background: $color-def;
                }
            }
            &.selected {
                a {
                    color: $color-def;
                    font-weight: bold;
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
    @include respond-to(pcMain) {
        ul {
            li {
                margin: 0 40px;
                a {
                    font-size: 22px;
                }
            }
        }
    }
}
.invt-code {
    padding: 64px 24px;
    text-align: center;
    background: #5539ff;
    color: #fff;
    .inner {
        position: relative;
        width: 100%;
        max-width: 1040px;
        margin: 0 auto;
        box-sizing: border-box;
        strong {
            font-size: 28px;
            line-height: 42px;
            br {
                display: none;
            }
        }
        p {
            margin-top: 16px;
            font-size: 16px;
            line-height: 26px;
            &:after {
                content: '';
                display: block;
                width: 327px;
                height: 327px;
                margin: 0 auto;
                background: url('../images/how_rec01.png') no-repeat 50% 50%;
                background-size: cover;
            }
        }
        .in-btn {
            max-width: 440px;
            margin: 0 auto;
            a {
                display: block;
                padding: 13px 0;
                background: #fff;
                color: $color-point;
                border-radius: 2px;
                font-size: 16px;
                text-align: center;
                line-height: 26px;
            }
        }
    }
    @include respond-to(pcMain) {
        min-height: 496px;
        padding: {
            top: 120px;
            bottom: 120px;
        }
        box-sizing: border-box;
        .inner {
            text-align: left;
            .txt-box {
                width: 440px;
            }
            strong {
                font-size: 32px;
                line-height: 48px;
                br {
                    display: inline;
                }
            }
            p {
                &:after {
                    position: absolute;
                    top: -90px;
                    right: 0;
                    width: 440px;
                    height: 440px;
                }
            }
            .in-btn {
                margin-top: 40px;
            }
        }
    }
    &.comp {
        background: $color-def;
        .inner {
            p {
                &:after {
                    background-image: url('../images/how_comp01.png');
                }
            }
            .in-btn {
                a {
                    color: $color-def;
                }
            }
        }
    }
}
.join-profile {
    .inner {
        position: relative;
        padding: 64px 24px;
        .txt-box {
            position: relative;
            max-width: 1040px;
            margin: 0 auto;
        }
        span {
            display: block;
            font-size: 22px;
            text-align: center;
        }
        p {
            margin-top: 16px;
            font: {
                size: 28px;
                weight: bold;
            }
            text-align: center;
            line-height: 42px;
            &:after {
                content: '';
                display: block;
                width: 327px;
                height: 327px;
                margin: 16px auto 0;
                background: url('../images/how_rec0201.png') no-repeat 50% 50%;
                background-size: cover;
            }
            br {
                display: none;
            }
        }
        ul {
            li {
                margin-top: 16px;
                padding: 24px;
                border-radius: 4px;
                background-color: rgba(13, 18, 64, 0.02);
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                line-height: 26px;
                box-sizing: border-box;
            }
        }
        .in-btn {
            margin-top: 16px;
            a {
                display: block;
                padding: 18px 0;
                border-radius: 4px;
                background-color: rgba(85, 57, 255, 0.03);
                color: $color-point;
                font-size: 16px;
                text-align: center;
            }
        }
        &:nth-child(2n + 1) {
            background: #f9fdff;
        }
        &:nth-child(2) {
            p {
                &:after {
                    background-image: url('../images/how_rec0202.png');
                }
            }
        }
        &:nth-child(3) {
            p {
                &:after {
                    background-image: url('../images/how_rec0203.png');
                }
            }
        }
        &:nth-child(4) {
            p {
                &:after {
                    background-image: url('../images/how_rec0204.png');
                }
            }
        }
        &:nth-child(5) {
            p {
                &:after {
                    background-image: url('../images/how_rec0205.png');
                }
            }
        }
    }
    @include respond-to(pcMain) {
        .inner {
            .txt-box {
                display: flex;
                flex-direction: column;
                * {
                    width: 480px;
                }
                span, p {
                    text-align: left;
                }
                ul {
                    padding-top: 16px;
                }
                p {
                    font-size: 32px;
                    line-height: 48px;
                    &:after {
                        position: absolute;
                        top: 40px;
                        right: 0;
                        width: 400px;
                        height: 400px;
                    }
                    br {
                        display: inline;
                    }
                }
            }
            &:nth-child(2n) {
                .txt-box {
                    p {
                        &:after {
                            right: auto;
                            left: 0;
                        }
                    }
                    * {
                        margin-left: auto;
                        text-align: left;
                    }
                }
            }
        }
    }
    &.comp {
        .inner {
            &:nth-child(1) {
                p {
                    &:after {
                        background-image: url('../images/how_comp0201.png');
                    }
                }
            }
            &:nth-child(2) {
                p {
                    &:after {
                        background-image: url('../images/how_comp0202.png');
                    }
                }
            }
            &:nth-child(3) {
                p {
                    &:after {
                        background-image: url('../images/how_comp0203.png');
                    }
                }
            }
            &:nth-child(4) {
                p {
                    &:after {
                        background-image: url('../images/how_comp0204.png');
                    }
                }
            }
            &:nth-child(5) {
                p {
                    &:after {
                        background-image: url('../images/how_comp0205.png');
                    }
                }
            }
        }
    }
}
.pricing {
    width: calc(100% - 48px);
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 24px;
    text-align: center;
    h2 {
        font-size: 32px;
        line-height: 48px;
    }
    table {
        width: 100%;
        margin-top: 32px;
        border-spacing: 4px 2px;
        font-size: 14px;
        th, td {
            width: 33.3%;
            padding: 13px 0;
            font-weight: bold;
            background-color: rgba(85, 57, 255, 0.03);
            &.disb {
                color: rgba(13, 18, 64, 0.4);
                background-color: rgba(13, 18, 64, 0.02);
            }
        }
        thead {
            th {
                background-color: rgba(85, 57, 255, 0.05);
                color: $color-point;
                &:first-child {
                    background: none;
                }
            }
        }
        tbody {
            th {
                background-color: rgba(13, 18, 64, 0.02);
                color: rgba(13, 18, 64, 0.6);
            }
            td {
            }
        }
    }
    p.vat {
        margin-top: 4px;
        font-size: 12px;
        color: rgba(13, 18, 64, 0.4);
        text-align: right;
    }
    .in-btn {
        max-width: 400px;
        margin: 24px auto 0;
        a {
            display: block;
            padding: 11px 0;
            background: $color-point;
            color: #fff;
            border-radius: 2px;
            font-size: 16px;
            line-height: 26px;
        }
    }
    @include respond-to(pc) {
        padding: {
            top: 80px;
            bottom: 80px;
        }
        table {
            br {
                display: none;
            }
            tbody {
                th, td {
                    padding: 24px 0;
                }
            }
        }
    }
    .membership-guide {
        overflow: hidden;
        margin-top: 24px;
        border-radius: 4px;
        background-color: rgba(224, 254, 82, 0.1);
        font-size: 14px;
        .hgroup {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 24px;
            background-color: rgba(224, 254, 82, 0.2);
            h3 {
                font-size: 14px;
            }
            strong {
                margin: 8px auto 0;
                padding: 8px 12px;
                border-radius: 4px;
                background: $color-def;
                color: #e0fe52;
            }
        }
        .in-cnts {
            padding: 24px;
            .sale {
                display: inline-block;
                padding: 8px 12px;
                border-radius: 4px;
                background-color: #e0fe52;
                font-weight: bold;
            }
            > p {
                margin-top: 16px;
                font: {
                    size: 22px;
                    weight: bold;
                }
                line-height: 34px;
                s {
                    color: rgba(13, 18, 64, 0.4); 
                }
            }
            .benf-price {
                padding-top: 16px;
                text-align: left;
                > div {
                    margin-top: 8px;
                    padding: 16px;
                    border-radius: 4px;
                    background-color: rgba(13, 18, 64, 0.05);
                    line-height: 22px;
                    .vat {
                        margin-top: 0;
                        font-size: 14px;
                        text-align: left;
                    }
                }
            }
            .guide {
                color: rgba(13, 18, 64, 0.6);
                text-align: left;
                line-height: 22px;
                h4 {
                    margin-top: 24px;
                    color: rgba(13, 18, 64, 0.6);
                }
            }
            .in-btn {
                max-width: 100%;
                a {
                    background: $color-def;
                    text-align: center;
                }
            }
        }
        @include respond-to(pcMain) {
            .hgroup {
                position: relative;
                strong {
                    position: absolute;
                    top: 16px;
                    right: 24px;
                    margin-top: 0;
                }
            }
            .in-cnts {
                .benf-price {
                    display: flex;
                    justify-content: space-between;
                    > div {
                        width: calc(50% - 12px);
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
.pjt-comp {
    padding: 64px 20px;
    background: $color-point;
    text-align: center;
    .inner {
        max-width: 816px;
        margin: 0 auto;
        p {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            height: 180px;
            color: #fff;
            font: {
                size: 22px;
                weight: bold;
            }
            line-height: 34px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 190px;
                height: 100px;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
                border-radius: 80px;
                transform: rotate(-45deg);
            }
            span {
                z-index: 2;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            li {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: calc(50% - 8px);
                height: 154px;
                margin: 8px 4px 0;
                background: #fff;
                border-radius: 4px;
                &:before {
                    content: '';
                    width: 80px;
                    height: 80px;
                    background: url('../images/icon_folder.png') no-repeat 0 0;
                    background-size: cover;
                }
                span {
                    margin-top: 16px;
                    font-size: 16px;
                }
            }
        }
    }
    @include respond-to(pcMain) {
        padding: {
            top: 120px;
            bottom: 120px;
        }
        .inner {
            p {
                height: 250px;
                font-size: 32px;
                line-height: 48px;
                &:before {
                    width: 300px;
                    height: 150px;
                }
                br {
                    display: inline;
                }
            }
            ul {
                margin-top: 64px;
                li {
                    width: calc(33.3% - 16px);
                    height: 170px;
                    margin: 16px 8px 0;
                }
            }
        }
    }
}
.invt-app {
    padding: 64px 24px;
    background: #f9fdff;
    text-align: center;
    .inner {
        position: relative;
        max-width: 1040px;
        margin: 0 auto;
        strong {
            display: block;
            font-size: 28px;
            line-height: 42px;
        }
        p {
            margin-top: 24px;
            color: rgba(13, 18, 64, 0.6);
            font-size: 16px;
            line-height: 26px;
            &:after {
                content: '';
                display: block;
                width: 327px;
                height: 327px;
                margin: 24px auto 0;
                background: url('../images/how_rec03.png') no-repeat 50% 50%;
                background-size: cover;
            }
        }
        .in-btn {
            margin-top: 24px;
            a {
                display: block;
                padding: 13px 0;
                background: $color-point;
                color: #fff;
                text-align: center;
                line-height: 26px;
                border-radius: 2px;
            }
        }
    }
    @include respond-to(pcMain) {
        padding: {
            top: 120px;
            bottom: 120px;
        }
        text-align: left;
        .inner {
            * {
                width: 440px;
            }
            p {
                &:after {
                    position: absolute;
                    top: -90px;
                    right: 0;
                    width: 400px;
                    height: 400px;
                }
            }
            .in-btn {
                margin-top: 40px;
            }
        }
    }
}
.main-faq {
    padding: 64px 24px;
    background: $color-def;
    h2 {
        color: #fff;
        font-size: 28px;
        text-align: center;
        line-height: 42px;
    }
    .inner {
        max-width: 600px;
        margin: 0 auto;
        padding-top: 20px;
        ul {
            li {
                margin-top: 16px;
                padding: 24px;
                background: #fff;
                border-radius: 4px;
                color: rgba(13, 18, 64, 0.6);
                font-size: 16px;
                line-height: 26px;
                .ans {
                    display: none;
                    margin-top: 16px;
                }
                &.selected {
                    em {
                        color: $color-def;
                        font-weight: bold;
                    }
                    .ans {
                        display: block;
                    }
                }
            }
        }
        .in-btn {
            margin-top: 40px;
            a {
                display: block;
                padding: 16px 0;
                background: $color-point;
                color: #fff;
                font-size: 16px;
                text-align: center;
                line-height: 26px;
                border-radius: 4px;
            }
        }
    }
    @include respond-to(pcMain) {
        .inner {
            ul {
                li {
                    padding: {
                        left: 40px;
                        right: 40px;
                    }
                }
            }
        }
    }
}
.steam-join {
    padding: 64px 24px;
    background: $color-point;
    color: #fff;
    text-align: center;
    strong {
        display: block;
        font-size: 28px;
        line-height: 42px;
        br {
            display: none;
        }
    }
    p {
        margin-top: 24px;
        font-size: 16px;
    }
    .in-btn {
        &:before {
            content: '';
            display: block;
            width: 327px;
            height: 327px;
            margin: 24px auto;
            background: url('../images/how_rec04.png') no-repeat 50% 50%;
            background-size: 100% auto;
        }
        a {
            display: block;
            padding: 16px 0;
            background: #fff;
            color: $color-point;
            font-size: 16px;
            text-align: center;
            line-height: 26px;
            border-radius: 4px;
        }
    }
   @include respond-to(pcMain) {
       padding: {
           top: 120px;
           bottom: 120px;
       }
       text-align: left;
       .inner {
           position: relative;
           max-width: 1040px;
           margin: 0 auto;
           * {
               width: 440px;
           }
           strong {
               font-size: 32px;
               br {
                   display: inline;
               }
           }
           .in-btn {
               margin-top: 40px;
               &:before {
                    position: absolute;
                    top: -60px;
                    right: 0;
                    width: 432px;
                    height: 400px;
                    margin: 0;
               }
           }
       }
   }
}