@charset "utf-8";

@import "base.scss";
@import "fontface.scss";

//LAYOUT
#skipNav {
	a {
		position: absolute;
		left:-3000%;
	}	
	:focus {
		display: block;
		left:0; 
		top: 0;
		z-index: 100;
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: #000;
		color: #fff;
		font-size: 18px;
		text-align: center;
	}
}
.t-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
header {
	display: flex;
	position: fixed;
	top: 0;
	z-index: 30;
	width: 100%;
	padding: 20px 24px;
	box-sizing: border-box;
	background: #fff;
	.btn-menu {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 40px;
		height: 40px;
		box-sizing: border-box;
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: calc(50% - 10px);
			width: 20px;
			height: 2px;
			background: $color-point;
			border-radius: 3px;
		}
		&:before {
			top: 11px
		}
		&:after {
			top: 18px;
		}
		span {
			@include hiddenText;
			width: 20px;
			height: 16px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 25px;
				left: calc(50% - 10px);
				width: 20px;
				height: 2px;
				background: $color-point;
				border-radius: 3px;
			}
		}
	}
	h1 {
		a {
			display: block;
			width: 80px;
			height: 34px;
			background: url('../images/logo.svg') no-repeat 0 0;
			background-size: 100% auto;
			span {
				@include hiddenText;
			}
		}
		&.alpha {
			a {
				width: 122px;
				background-image: url('../images/logo_alpha.svg');
			}
		}
	}
	.mem-w {
		display: flex;
		align-items: center;
		margin-top: -5px;
		margin-left: auto;
		padding-right: 30px;
		a {
			height: 26px;
			line-height: 26px;
		}
		.mem {
			display: flex;
			padding-right: 8px;
			li {
				display: flex;
				align-items: center;
				font-size: 14px;
				a {
					color: $color-point;
				}
				&:before {
					content: '/';
					margin: -1px 3px 0;
					color: $color-point;
				}
				&:first-child {
					&:before {
						display: none;
					}
				}
			}
		}
		.profile {
			display: flex;
			align-items: center;
			//margin-top: -5px;
			//margin-left: auto;
			li {
				padding: 0 5px;
				font-size: 14px;
				a {
					display: block;
					color: $color-point;
					em {
						font-weight: bold;
					}
					&.alm {
						position: relative;
						width: 26px;
						height: 26px;
						background: url('../images/icon_alm.svg') no-repeat 50% 4px;
						span {
							@include hiddenText;
						}
						i {
							position: absolute;
							top: 2px;
							left: 7px;
							width: 16px;
							height: 11px;
							background: #ff4e16;
							color: #fff;
							font-size: 10px;
							text-align: center;
							line-height: 10px;
							border-radius: 7px;
						}
					}
				}
			}
		}
		.alm-list {
			overflow: auto;
			position: fixed;
			top: 74px;
			right: 0;
			width: 100%;
			max-width: 320px;
			height: calc(100vh - 74px);
			background: #fff;
			box-shadow: -1px 0 0 0 rgba(13, 18, 64, 0.1);
			font-size: 14px;
			line-height: 22px;
			ul {
				li {
					box-shadow: 0 1px 0 0 rgba(13, 18, 64, 0.05);
					a {
						display: block;
						height: auto;
						padding: 16px;
						color: rgba(13, 18, 64, 0.4);
						span {
							display: block;
							margin-top: 8px;
							color: rgba(13, 18, 64, 0.4) !important;
						}
					}
					&.new {
						background-color: rgba(85, 57, 255, 0.03);
						a {
							color: $color-point;
						}
					}
				}
			}
		}
	}
	.inner {
		display: none;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		padding: 60px 40px 40px;
		background: $color-point;
		box-sizing: border-box;
		a {
			color: #fff;
		}
		ul {
			li {
				padding: 20px 0;
				font-size: 22px;

			}
		}
		.utill {
			margin-top: 28px;
			padding-top: 20px;
			border-top: 1px solid rgba(255, 255, 255, 0.4);
		}
		.btn-close {
			position: fixed;
			top: 40px;
			right: 14px;
			width: 40px;
			height: 40px;
			> span {
				@include hiddenText;
			}
			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 7px;
				left: calc(50% - 2px);
				width: 2px;
				height: 22px;
				background: #fff;
				border-radius: 2px;
			}
			&:before {
				transform: rotate(-45deg);
			}
			&:after {
				transform: rotate(45deg);
			}
		}
		&.selected {
			display: block;
			z-index: 100;
			nav, .utill {
				a {
					color: #fff;
				}
			}
		}
	}
	&.bgtype {
		background: #fff;
	}
	&.bgtype2 {
		background-color: $color-point;
		h1 {
			a {
				background-image: url('../images/logo_w.svg');
			}
		}
		.inner {
			nav {
				a {
					color: #fff !important;
				}
			}
		}
		.mem-w {
			.mem {
				li {
					&:before {
						color: #fff;
					}
				}
			}
			li {
				a {
					color: #fff;
					&.alm {
						background-position: 50% -31px;
					}
				}
			}
		}
		.btn-menu {
			&:before,
			&:after {
				background: #fff;
			}
			> span {
				&:before {
					background: #fff;
				}
			}
		}
	}
	&.sticky {
		background: #f9fdff;
		/* h1 {
			a {
				background-image: url('../images/logo.svg');
			}
		} */
		.btn-menu {
			&:before,
			&:after {
				background-color: $color-point;
			}
			> span {
				&:before {
					background-color: $color-point;
				}
			}
		}
		.profile {
			li {
				a {
					color: $color-point !important;
					&.alm {
						background-position: 50% 4px;
					}
				}
			}
		}
		.inner {
			a {
				color: $color-def !important;
			}
		}
	}
	&.index {
		background: #f9fdff;
	}
	@include respond-to(pc) {
		display: block;
		//top: 0;
		height: 72px;
		margin-bottom: -72px;
		h1 {
			float: left;
			a {
				width: 75px;
				height: 32px;
			}
		}
		.mem-w {
			position: absolute;
			top: 22px;
			right: 30px;
			margin-top: 0;
			.mem {
				li {
					padding-left: 40px;
					&:before {
						display: none;
					}
				}
			}
		}
		.inner {
			display: block;
			position: static;
			width: auto;
			height: auto;
			padding: 0;
			background: none;
			a {
				color: $color-def;
			}
			nav {
				margin: 7px 0 0;
				padding: 0;
				border: none;
				ul {
					display: flex;
					align-items: center;
					font-size: 14px;
					li {
						padding: 0 0 0 40px;
						font-size: 14px;
					}
				}
			}
			.utill {
				display: none;
				position: absolute;
				z-index: 10;
				top: 35px;
				right: 25px;
				width: 148px;
				padding: 16px 0;
				background: #fff;
				border: 1px solid $color-point;
				text-align: center;
				li { 
					padding: 8px 0 !important;
					font-size: 14px !important;
					a:hover {
						color: $color-point;
					}
				}
				&.selected {
					display: block;
				}
			}
			button {
				display: none;
			}
			&.selected {
				nav, .utill {
					a {
						color: $color-def;
					}
				}
			}
		}
		.alm-list {
			//height: calc(100vh - 412px);
		}
		&.bgtype {
			background: none;
			h1 {
				a {
				 	background-image: url('../images/logo_w.svg');
				}
			}
			nav {
				ul {
					li {
						a {
							color: #fff;
						}
					}
				}
			}
		} 
	}
}
.top-bnr {
	background: #e0fe52;
	text-align: center;
	a {
		display: block;
		padding: 16px 24px;
		font-size: 14px;
		line-height: 22px;
		p {
			&.beta {
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 18px;
				text-align: left;
				font: {
					weight: bold;
					size: 12px;
				}
				span {
					margin-right: 8px;
					padding: 5px 8px;
					border-radius: 20px;
					border: 1px solid #000;
					font-size: 12px;
				}
			}
		}
	}
	@include respond-to(pc) {
		a {
			font-size: 16px;
			p, strong {
				display: inline;
			}
			p { 
				font-weight: bold;
				&.beta {
					font-size: 16px;
					> span {
						padding: 6px 10px;
						font-size: 14px;
					}
				}
			}
			strong {
				font-weight: normal;
				text-decoration: underline;
			}
		}
	}
}
.top-bnr + header {
	position: relative;
}
main {
	//display: flex;
	//flex-direction: column;
	flex: 1 0 auto;
	&.bgtype {
		background: #FDFDFD;
	}
	&.bgtype2 {
		background: #FCFBFF;
	}
}
footer {
	padding: 64px 40px;
	background: #f9fdff;
	.ftr-inner {
		em.logo {
			display: block;
			width: 75px;
			height: 32px;
			background: url('../images/logo.svg') no-repeat 0 0;
			background-size: 100% auto;
			span {
				@include hiddenText;
			}
		}
		> ul {
			display: flex;
			flex-wrap: wrap;
			li {
				min-width: 100%;
				margin-top: 24px;
				em, span {
					display: block;
					margin-top: 16px;
					font-size: 14px;
					white-space: nowrap;
					a {
						color: rgba(13, 18, 64, 0.6);
					}
				}
				em {
					font-weight: bold;
				}
				&:nth-child(1),
				&:nth-child(2) {
					min-width: 50%;
				}
			}
		}
		.c-info {
			padding-top: 24px;
			color: rgba(13, 18, 64, 0.6);
			font-size: 12px;
			line-height: 22px;
			address {
				margin-top: 16px;
			}
			p {
				margin-top: 16px;
			}
		}
	}
	@include respond-to(pc) {
		padding: {
			top: 80px;
			bottom: 80px;
		}
		.ftr-inner {
			display: flex;
			max-width: 1200px;
			margin: 0 auto;
			> ul {
				margin-top: -16px;
				padding-left: 65px;
				li {
					min-width: auto;
					margin-top: 0;
					padding-right: 64px;
					&:nth-child(1),
					&:nth-child(2) {
						min-width: auto;
					}
					&:last-child {
						padding-right: 0;
					}
				}
			}
			.c-info {
				width: 40%;
				margin: {
					top: -18px;
					left: auto;
				}
				padding: {
					top: 0;
					left: 64px;
				}
				font-size: 14px;
				box-sizing: border-box;
			}
		}
	}
}